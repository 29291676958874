import React from 'react';
import { Form, Message, Modal, Button } from 'semantic-ui-react';
import request from 'utils/request';

export default class SendResult extends React.Component {
  static defaultProps = {
    initialValues: {},
    loading: false,
    error: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      formValues: {
        ...this.props.initialValues,
      },
      previousPatientIds: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.initialValues !== prevProps.initialValues) {
      this.setState({
        touched: false,
        formValues: {
          ...this.props.initialValues,
        },
      });
    }
  }

  handleSubmit = async () => {
    const { result } = this.props;
    const { formValues } = this.state;
    this.setState({ loading: true, error: null, touched: true });
    try {
      const body = formValues;
      await request({
        method: 'POST',
        path: `/1/results/${result.id}/send`,
        body,
      });
      this.setState({
        open: false,
        formValues: {},
        touched: false,
      });
      if (this.props.onSave) {
        this.props.onSave();
      }
    } catch (error) {
      this.setState({ loading: false, error });
    }
  };

  setField(name, value) {
    this.setState({
      formValues: {
        ...this.state.formValues,
        [name]: value,
      },
    });
  }

  render() {
    const { initialValues, trigger } = this.props;
    const { open, loading, error, formValues } = this.state;

    return (
      <Modal
        closeOnDimmerClick={false}
        closeIcon
        onClose={() => {
          this.setState({
            open: false,
            formValues: initialValues,
            touched: false,
          });
          if (this.props.onSave) {
            this.props.onSave();
          }
        }}
        onOpen={() => this.setState({ open: true })}
        open={open}
        trigger={trigger}>
        <Modal.Header>Send Test Results</Modal.Header>
        <Modal.Content>
          {error && <Message error content={error.message} />}
          <Form onSubmit={() => this.handleSubmit()}>
            <Form.Dropdown
              name="ccRecipients"
              search
              selection
              multiple
              allowAdditions
              options={
                formValues.ccRecipients?.map((value) => {
                  return {
                    value,
                    text: value,
                  };
                }) || []
              }
              label="Send a copy to (Optional)"
              onAddItem={(evt, { name, value }) => {
                this.setField(name, [
                  ...(formValues.ccRecipients || []),
                  value,
                ]);
              }}
              onChange={(e, { name, value }) => this.setField(name, value)}
              value={formValues.ccRecipients || []}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            basic
            content="Cancel"
            onClick={() => {
              this.setState({
                open: false,
                formValues: initialValues,
                touched: false,
              });
              if (this.props.onSave) {
                this.props.onSave();
              }
            }}
          />
          <Button
            loading={loading}
            primary
            content={'Send'}
            onClick={() => this.handleSubmit()}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}
