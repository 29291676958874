import React from 'react';
import { observer, inject } from 'mobx-react';
import { Switch, Route, Link } from 'react-router-dom';
import AppWrapper from 'components/AppWrapper';

import { Container, Divider, Breadcrumb, Button } from 'semantic-ui-react';
import Menu from './Menu';
import PageLoader from 'components/PageLoader';
import Overview from './Overview';
import Measurements from './Measurements';
import Results from './Results';
import EditPatient from 'components/modals/EditPatient';

@inject('patients')
@observer
export default class Patient extends React.Component {
  state = {
    itemId: this.props.match.params.id,
  };

  componentDidMount() {
    this.props.patients.fetchItem(this.state.itemId);
  }

  render() {
    const { patients } = this.props;
    const item = patients.get(this.state.itemId);
    return (
      <AppWrapper>
        <Container>
          <Breadcrumb size="big">
            <Breadcrumb.Section link as={Link} to="/">
              Home
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon="right chevron" />
            <Breadcrumb.Section link as={Link} to="/patients">
              Patients
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon="right chevron" />
            <Breadcrumb.Section active>
              {item ? [item.firstName, item.lastName].join(' ') : 'Loading...'}
            </Breadcrumb.Section>
          </Breadcrumb>
          {item && (
            <EditPatient
              initialValues={item}
              trigger={
                <Button
                  primary
                  floated="right"
                  style={{ marginTop: '-5px' }}
                  content="Edit"
                  icon="edit"
                />
              }
            />
          )}
        </Container>
        <Divider hidden />
        <Menu itemId={this.state.itemId} />
        <Divider hidden />
        {!item && <PageLoader />}
        {item && (
          <Switch>
            <Route
              exact
              path="/patients/:id"
              item={item}
              component={(props) => <Overview {...props} patient={item} />}
            />
            <Route
              exact
              path="/patients/:id/measurements"
              item={item}
              component={(props) => <Measurements {...props} patient={item} />}
            />
            <Route
              exact
              path="/patients/:id/results"
              item={item}
              component={(props) => <Results {...props} patient={item} />}
            />
          </Switch>
        )}
      </AppWrapper>
    );
  }
}
