import React from 'react';
import { Form } from 'semantic-ui-react';
import SearchDropdown from '../SearchDropdown';
import request from 'utils/request';

export default class LocationField extends React.Component {
  async fetchLocations(filter) {
    return await request({
      method: 'POST',
      path: '/1/locations/search',
      body: {
        ...filter,
      },
      organizationId: this.props.organizationId,
    });
  }

  onChange = (e, { value }, location, firstTime, numResults) => {
    if (this.props.selectFirstIfOneResult && firstTime && numResults === 1) {
      this.props.onChange(e, {
        location,
        value,
      });
      return;
    }
    if (firstTime) {
      this.props.onChange(e, {
        location,
      });
      return;
    }
    this.props.onChange(e, {
      location,
      value,
    });
  };

  render() {
    const {
      value,
      required,
      setLocationName,
      clearable,
      selectFirst = true,
      searchParams = {},
    } = this.props;
    const locationId = value?.id || value;
    return (
      <Form.Field required={required}>
        <label>Location</label>
        <SearchDropdown
          fluid
          selectFirst={selectFirst}
          clearable={clearable}
          selectName={setLocationName}
          onChange={this.onChange}
          value={locationId}
          fetchData={(filter) =>
            this.fetchLocations({
              ...searchParams,
              ...filter,
            })
          }
          search={false}
        />
      </Form.Field>
    );
  }
}
