import React from 'react';
import { observer, inject } from 'mobx-react';
import DateTimeField from 'components/form-fields/DateTime';
import Weekdays from 'components/form-fields/Weekdays';
import request from 'utils/request';
import { Form, Label, Message, Button, Modal } from 'semantic-ui-react';
import SearchDropdown from '../../components/SearchDropdown';
import AutoFocus from 'components/AutoFocus';
import { formatDateStr } from 'utils/formatting';

const statusOptions = [
  { text: 'Open', value: 'open' },
  { text: 'Closed', value: 'closed' },
];

@inject('scheduleOverrides')
@observer
export default class EditScheduleOverride extends React.Component {
  static defaultProps = {
    initialValues: {
      status: 'closed',
      repeatAlways: false,
      repeatDateStart: formatDateStr(new Date()),
      repeatDateEnd: formatDateStr(new Date()),
      hourStart: 9,
      hourEnd: 18,
      priority: 1,
    },
  };

  state = {
    open: false,
    formValues: { ...this.props.initialValues },
  };

  componentDidUpdate(prevProps) {
    if (this.props.initialValues !== prevProps.initialValues) {
      this.setState({
        touched: false,
        formValues: { ...this.props.initialValues },
      });
    }
  }

  handleOnClose = () => {
    this.setState({
      touched: false,
      formValues: { ...this.props.initialValues },
      open: false,
    });
  };

  handleSubmit = () => {
    const { scheduleOverrides, initialValues } = this.props;
    this.setState({
      touched: true,
    });

    const action = initialValues.id
      ? scheduleOverrides.update.bind(scheduleOverrides)
      : scheduleOverrides.create.bind(scheduleOverrides);

    return action(this.state.formValues).then((err) => {
      if (err instanceof Error) return;
      this.setState({
        formValues: this.props.initialValues,
        open: false,
      });
    });
  };

  setField(name, value) {
    this.setState({
      touched: false,
      formValues: {
        ...this.state.formValues,
        [name]: value,
      },
    });
  }

  fetchLocations = (filter) => {
    return request({
      method: 'POST',
      path: '/1/locations/search',
      body: {
        ...filter,
      },
    });
  };

  handleOnLocationChange = (e, { value }, selectedLocation) => {
    this.setState({
      selectedLocation,
      formValues: {
        ...this.state.formValues,
        location: value,
      },
    });
  };

  handleOnDateStartChange = (value) => {
    const dateObj = new Date(Date.parse(value));
    this.setField('repeatDateStart', formatDateStr(dateObj));
  };

  handleOnDateEndChange = (value) => {
    const dateObj = new Date(Date.parse(value));
    this.setField('repeatDateEnd', formatDateStr(dateObj));
  };

  render() {
    const { scheduleOverrides, initialValues, trigger } = this.props;
    const { formValues = {}, touched, open } = this.state;
    const locationId =
      formValues.location && formValues.location.id
        ? formValues.location.id
        : formValues.location;

    const isUpdate = !!initialValues.id;
    const status = isUpdate
      ? scheduleOverrides.getStatus('update')
      : scheduleOverrides.getStatus('create');

    return (
      <Modal
        closeOnDimmerClick={false}
        closeIcon
        trigger={trigger}
        onClose={this.handleOnClose}
        onOpen={() => this.setState({ open: true })}
        open={open}>
        <Modal.Header>
          {isUpdate
            ? `Edit Schedule Override "${initialValues.name}"`
            : 'New Schedule Override'}
        </Modal.Header>
        <Modal.Content>
          <AutoFocus>
            <Form
              error={touched && Boolean(status.error)}
              onSubmit={() => this.handleSubmit()}>
              {status.error && <Message error content={status.error.message} />}

              <Form.Field>
                <label>Location</label>
                <SearchDropdown
                  selectFirst
                  onChange={this.handleOnLocationChange}
                  value={locationId}
                  fetchData={this.fetchLocations}
                  fluid
                  search={false}
                />
              </Form.Field>

              <Form.Input
                error={touched && !(formValues.name || '').length}
                required
                name="name"
                label="Name"
                type="text"
                value={formValues.name || ''}
                onChange={(e, { name, value }) => this.setField(name, value)}
              />

              <Form.Dropdown
                name="status"
                label="Status"
                required
                fluid
                selection
                value={formValues.status}
                options={statusOptions}
                onChange={(e, { name, value }) => this.setField(name, value)}
              />

              <Form.Checkbox
                label="Repeat Always?"
                name="repeatAlways"
                checked={formValues.repeatAlways}
                onChange={(e, { name, checked }) =>
                  this.setField(name, checked)
                }
              />

              {formValues.repeatAlways && (
                <Weekdays
                  name="repeatWeekdays"
                  label="Weekdays to Repeat"
                  required
                  value={formValues.repeatWeekdays}
                  onChange={(e, { name, value }) => this.setField(name, value)}
                />
              )}

              {!formValues.repeatAlways && (
                <>
                  <DateTimeField
                    name="repeatDateStart"
                    includeTime={false}
                    value={
                      new Date(
                        Date.parse(`${formValues.repeatDateStart}T13:00:00`)
                      )
                    }
                    label="Start Date"
                    onChange={(value) => this.handleOnDateStartChange(value)}
                  />

                  <DateTimeField
                    name="repeatDateEnd"
                    includeTime={false}
                    value={
                      new Date(
                        Date.parse(`${formValues.repeatDateEnd}T13:00:00`)
                      )
                    }
                    label="End Date"
                    onChange={(value) => this.handleOnDateEndChange(value)}
                  />
                </>
              )}

              <Form.Input
                required
                name="hourStart"
                label="Hour Start"
                type="text"
                value={formValues.hourStart || ''}
                onChange={(e, { name, value }) => this.setField(name, value)}
              />

              <Form.Input
                required
                name="hourEnd"
                label="Hour End"
                type="text"
                value={formValues.hourEnd || ''}
                onChange={(e, { name, value }) => this.setField(name, value)}
              />

              <Form.Input
                required
                name="priority"
                label="Priority"
                type="text"
                value={formValues.priority}
                onChange={(e, { name, value }) => this.setField(name, value)}
              />
            </Form>
          </AutoFocus>
        </Modal.Content>
        <Modal.Actions>
          <Button
            loading={status.request === true}
            primary
            content={isUpdate ? 'Update' : 'Create'}
            onClick={this.handleSubmit}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}
