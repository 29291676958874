import React from 'react';
import BookingAppWrapper from 'components/BookingAppWrapper';

import { Message, Divider } from 'semantic-ui-react';
import PageLoader from 'components/PageLoader';

export default class ResultTokenLogout extends React.Component {
  state = {
    error: null,
    loading: true,
  };

  componentDidMount() {
    localStorage.removeItem('resultToken');
    document.location = 'https://openclear.us';
  }

  render() {
    const { loading, error } = this.state;
    return (
      <BookingAppWrapper>
        <Divider hidden />
        {error && <Message error={error.message} />}
        {loading && <PageLoader />}
      </BookingAppWrapper>
    );
  }
}
