import React from 'react';
import request from 'utils/request';
import DataList from 'components/DataList';
import MeasurementsTable from './MeasurementsTable';

const itemLimit = 50;

export default class Measurements extends React.Component {

  state = {
    dateKey: Date.now()
  };

  handleDeleteItem = async (item) => {
    await request({
      method: 'DELETE',
      path: `/1/measurements/${item.id}`
    });
    this.refresh();
  };

  onRequest = async () => {
    return await request({
      method: 'POST',
      path: '/1/measurements/search',
      body: {
        patient: this.props.patient.id,
      }
    });
  };

  refresh() {
    this.setState({
      dataKey: Date.now()
    });
  }

  render() {
    return (
      <DataList
        key={this.state.dataKey}
        limit={itemLimit}
        onRequest={this.onRequest}>
        <MeasurementsTable onDeleteItem={this.handleDeleteItem} />
      </DataList>
    );
  }
}

