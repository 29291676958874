import React from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import SearchDropdown from '../../components/SearchDropdown';
import LocationField from 'components/form-fields/LocationField';

import request from 'utils/request';

import {
  Button,
  Modal,
  Form,
  Label,
  Icon,
  Grid,
  Checkbox,
  Divider,
} from 'semantic-ui-react';
import {
  appointmentStatusOptions,
  statuses,
  conciergeStatusOptions,
  conciergeStatuses,
} from 'utils/appointments';

function getDefaultState(/*props*/) {
  return {
    open: false,
  };
}

export default class Filters extends React.Component {
  state = {
    ...getDefaultState(this.props),
    appliedFilters: [],
    hidePast: true,
    hideCancellation: false,
    status: undefined,
    conciergeStatus: undefined,
    conciergeUser: undefined,
    clinicianUser: undefined,
    useAllTime: true,
    open: false,
    from: moment()
      .startOf('month')
      .toDate(),
    to: moment()
      .endOf('month')
      .toDate(),
  };

  open = () => this.setState({ open: true });
  close = () => {
    this.setState(getDefaultState(this.props));
  };

  componentDidMount() {
    const appliedFilters = this.computeFilters();
    this.props.onChange(appliedFilters);
    this.setState({
      appliedFilters: appliedFilters,
    });
  }

  computeFilters() {
    const {
      from,
      to,
      useAllTime,
      hidePast,
      hideCancellation,
      conciergeStatus,
      status,
      conciergeUser,
      clinicianUser,
      location,
      locationId,
    } = this.state;

    const filters = [];
    if (!useAllTime) {
      filters.push({
        field: 'dateRange',
        value: {
          from,
          to,
        },
        apiFilter: {
          from,
          to,
        },
        label: `Date Range ${from.toLocaleDateString()}-${to.toLocaleDateString()}`,
      });
    }
    if (hidePast) {
      filters.push({
        field: 'hidePast',
        value: true,
        apiFilter: {
          hidePast: true,
        },
        label: 'Hide Past',
      });
    }
    if (location) {
      filters.push({
        field: 'location',
        value: true,
        apiFilter: {
          location: locationId,
        },
        label: location.name,
      });
    }
    if (hideCancellation) {
      filters.push({
        field: 'hideCancellation',
        value: true,
        apiFilter: {
          hideCancellation: true,
        },
        label: 'Hide Cancellations',
      });
    }

    if (conciergeStatus) {
      filters.push({
        field: 'conciergeStatus',
        value: true,
        apiFilter: {
          conciergeStatus,
        },
        label: conciergeStatuses[conciergeStatus].name,
      });
    }

    if (status) {
      filters.push({
        field: 'status',
        value: true,
        apiFilter: {
          status,
        },
        label: statuses[status].name,
      });
    }

    if (conciergeUser) {
      filters.push({
        field: 'conciergeUser',
        value: true,
        apiFilter: {
          conciergeUser: conciergeUser.id,
        },
        label: conciergeUser.name,
      });
    }

    if (clinicianUser) {
      filters.push({
        field: 'clinicianUser',
        value: true,
        apiFilter: {
          clinicianUser: clinicianUser.id,
        },
        label: clinicianUser.name,
      });
    }

    return filters;
  }

  handleApply = () => {
    const appliedFilters = this.computeFilters();
    this.props.onChange(appliedFilters);
    this.setState({
      appliedFilters: appliedFilters,
    });
    this.close();
  };

  handleReset = () => {
    this.props.onChange([]);
    this.setState({
      appliedFilters: [],
    });
    this.close();
  };

  handleRemoveFilter = (filter) => {
    this.setState(
      {
        appliedFilters: this.state.appliedFilters.filter(
          (c) => c.field !== filter.field
        ),
      },
      () => {
        this.props.onChange(this.state.appliedFilters);
      }
    );
    this.close();
  };

  fetchConciergeUsers = (filter) => {
    return request({
      method: 'POST',
      path: '/1/users/organization/search',
      body: {
        role: 'concierge',
        ...filter,
      },
    });
  };

  fetchClinicianUsers = (filter) => {
    return request({
      method: 'POST',
      path: '/1/users/organization/search',
      body: {
        role: 'clinician',
        ...filter,
      },
    });
  };

  render() {
    const { from, to } = this.state;

    return (
      <>
        <Modal
          size="tiny"
          open={this.state.open}
          onOpen={this.open}
          onClose={this.close}
          trigger={
            <Button primary={true} floated="left" style={{ float: 'left' }}>
              <Icon name="filter" /> Filters
            </Button>
          }
          closeIcon>
          <Modal.Header>Filters</Modal.Header>
          <Modal.Content>
            <Form>
              <Grid divided>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <Form.Field>
                      <Checkbox
                        onChange={(e, { checked }) => {
                          this.setState({
                            useAllTime: checked,
                          });
                        }}
                        toggle
                        checked={this.state.useAllTime}
                        label="All Time"
                      />
                    </Form.Field>
                    <div
                      onClick={() =>
                        this.setState({
                          useAllTime: false,
                        })
                      }>
                      <Form.Field disabled={this.state.useAllTime}>
                        <label>From</label>
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          selected={from}
                          onChange={(date) => this.setState({ from: date })}
                          selectsStart
                          startDate={from}
                          endDate={to}
                        />
                      </Form.Field>
                      <Form.Field disabled={this.state.useAllTime}>
                        <label>To</label>
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          selected={to || new Date()}
                          onChange={(date) => this.setState({ to: date })}
                          selectsEnd
                          startDate={from}
                          endDate={to}
                          minDate={from}
                        />
                      </Form.Field>
                    </div>

                    <Divider hidden />

                    <Form.Checkbox
                      label="Hide Past"
                      checked={this.state.hidePast}
                      onChange={(e, { checked }) => {
                        this.setState({
                          hidePast: checked,
                        });
                      }}
                      style={{ marginRight: '10px' }}
                      toggle
                    />

                    <Form.Checkbox
                      label="Hide Cancellations"
                      checked={this.state.hideCancellation}
                      onChange={(e, { checked }) => {
                        this.setState({
                          hideCancellation: checked,
                        });
                      }}
                      style={{ marginRight: '10px' }}
                      toggle
                    />
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <LocationField
                      selectFirst={false}
                      clearable
                      value={this.state.locationId}
                      onChange={(e, { value, location }) =>
                        this.setState({ locationId: value, location })
                      }
                    />

                    <Form.Select
                      name="conciergeStatus"
                      clearable
                      options={conciergeStatusOptions}
                      label="Concierge Status"
                      onChange={(e, { value }) => {
                        this.setState({
                          conciergeStatus: value,
                        });
                      }}
                      value={this.state.conciergeStatus}
                    />

                    <Form.Select
                      name="status"
                      clearable
                      options={appointmentStatusOptions}
                      label="Appointment Status"
                      onChange={(e, { value }) => {
                        this.setState({
                          status: value,
                        });
                      }}
                      value={this.state.status}
                    />

                    <Form.Field>
                      <label>Concierge / Contact Person</label>
                      <SearchDropdown
                        clearable
                        onChange={(e, { value }, conciergeUser) => {
                          this.setState({
                            conciergeUser,
                          });
                        }}
                        value={this.state.conciergeUserId}
                        fetchData={this.fetchConciergeUsers}
                        fluid
                      />
                    </Form.Field>

                    <Form.Field>
                      <label>Assigned to Clinician/PA</label>
                      <SearchDropdown
                        onChange={(e, { value }, clinicianUser) => {
                          this.setState({ clinicianUser });
                        }}
                        value={this.state.clinicianUserId}
                        clearable
                        fetchData={this.fetchClinicianUsers}
                        fluid
                      />
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button content="Reset" onClick={this.handleReset} />
            <Button
              primary
              icon="check"
              content="Apply"
              onClick={this.handleApply}
            />
          </Modal.Actions>
        </Modal>

        <>
          {this.state.appliedFilters.map((filter) => (
            <Label
              key={filter.field}
              size="large"
              style={{ lineHeight: '20px' }}>
              {filter.label}
              <Icon
                name="delete"
                onClick={() => this.handleRemoveFilter(filter)}
              />
            </Label>
          ))}
        </>
      </>
    );
  }
}
