import React from 'react';
import request from 'utils/request';
import { observer, inject } from 'mobx-react';

import { NavLink } from 'react-router-dom';
import AddPatientToAppointment from 'components/modals/AddPatientToAppointment';
import EditPatient from 'components/modals/EditPatient';
import Protected from 'components/Protected';
import { DateTime } from 'luxon';
import { Header, Button, Table, Message, Modal } from 'semantic-ui-react';

@inject('me', 'appSession', 'patients')
@observer
export default class Patients extends React.Component {
  state = {
    showCreateDialog: false,
    editItem: null,
  };

  constructor(props) {
    super(props);
    this.createOrEditDialog = React.createRef();
  }

  onDeleteItem = (item) => {
    const { appointment } = this.props;
    return request({
      method: 'DELETE',
      path: `/1/appointments/${appointment.id}/patients/${item.id}`,
    }).then(() => this.props.refresh());
  };

  render() {
    const { organization } = this.props.me;
    const { appointment } = this.props;
    const { patients } = appointment;
    return (
      <>
        <Header as="h2">
          Patients
          <Protected endpoint="patients" permission="write">
            <AddPatientToAppointment
              onSave={() => this.props.refresh()}
              appointment={appointment}
              organization={organization}
              trigger={
                <Button
                  primary
                  floated="right"
                  style={{ marginTop: '-5px' }}
                  content="Add Patient"
                  icon="plus"
                />
              }
            />
          </Protected>
        </Header>
        <div className="list">
          {!patients.length && <Message>No patients added yet</Message>}

          {patients.length > 0 && (
            <Table celled sortable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.HeaderCell width={3}>Email</Table.HeaderCell>
                  <Table.HeaderCell width={3}>DOB</Table.HeaderCell>
                  <Table.HeaderCell width={3}>Created</Table.HeaderCell>
                  <Table.HeaderCell width={2} textAlign="center">
                    Actions
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {!patients.length && (
                  <Table.Row>
                    <Table.Cell>No patients added yet</Table.Cell>
                  </Table.Row>
                )}
                {patients.map((item) => {
                  return (
                    <Table.Row key={item.id}>
                      <Table.Cell>
                        <NavLink to={`/patients/${item.id}`}>
                          {item.firstName} {item.lastName}
                        </NavLink>
                      </Table.Cell>
                      <Table.Cell>{item.email}</Table.Cell>
                      <Table.Cell>
                        {!item.dob
                          ? 'None'
                          : DateTime.fromJSDate(item.dob).toLocaleString(
                              DateTime.DATE_FULL
                            )}
                      </Table.Cell>
                      <Table.Cell>
                        {DateTime.fromJSDate(
                          new Date(item.createdAt)
                        ).toLocaleString(DateTime.DATETIME_MED)}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        <Protected endpoint="patients" permission="write">
                          <EditPatient
                            organization={item.organization}
                            initialValues={item}
                            trigger={<Button basic icon="edit" />}
                          />
                          <Modal
                            header={`Are you sure you want to remove "${item.firstName} ${item.lastName}" from this appointment?`}
                            content="Patient record will not be deleted. You can always re-add patient to appointment."
                            trigger={<Button basic icon="remove" />}
                            closeIcon
                            actions={[
                              {
                                key: 'delete',
                                primary: true,
                                content: 'Delete',
                                onClick: () => this.onDeleteItem(item),
                              },
                            ]}
                          />
                        </Protected>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          )}
        </div>
      </>
    );
  }
}
