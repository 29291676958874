import React from 'react';
import { Form, Dimmer, Loader, Dropdown } from 'semantic-ui-react';
import { formatDateStr, formatTime } from 'utils/formatting';
import request from 'utils/request';
import DateTimeField from './DateTime';

function getStartDate(props) {
  const { dateStr } = props;
  let date;
  if (dateStr) {
    date = new Date(`${dateStr}T12:00:00`);
  } else {
    date = new Date();
  }
  return date;
}

export default class Availability extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: this.getKeyFromProps(props),
      startDate: null,
      availability: null,
    };
  }

  componentDidMount() {
    const locationId = this.getLocationId(this.props.location);
    if (locationId) {
      this.fetchAvailability(locationId);
    }
  }

  componentDidUpdate(lastProps) {
    const locationId = this.getLocationId(this.props.location);
    const lastLocationId = this.getLocationId(lastProps.location);
    if (locationId && locationId !== lastLocationId) {
      this.fetchAvailability(locationId);
    }
  }

  getKeyFromProps(props) {
    const { hour, minute } = props;
    if (typeof hour === 'number' && typeof minute === 'number') {
      return formatTime(hour, minute);
    } else {
      return null;
    }
  }

  getLocationId(location) {
    return location?.id || location;
  }

  checkDay(day) {
    const { availability } = this.state;
    const date = formatDateStr(day);
    if (availability && availability[date]?.numAvailable > 0) {
      return false;
    }
    return true;
  }

  determineAvailabilityStartDate(startDate) {
    const { availability } = this.state;
    const days = availability ? Object.keys(availability) : [];
    for (const day of days) {
      if (availability[day].numAvailable > 0) {
        return new Date(`${day}T12:00:00`);
      }
    }
    return startDate;
  }

  determineMaxDaysInAdvance() {
    const { location } = this.props;
    let maxDays = 100;
    if (
      location &&
      location.maximumDaysInAdvance &&
      location.maximumDaysInAdvance <= maxDays
    ) {
      maxDays = location.maximumDaysInAdvance;
    }
    return maxDays;
  }

  async fetchAvailability(locationId) {
    try {
      const dates = [];
      const startDate = getStartDate(this.props);
      const maxDays = this.determineMaxDaysInAdvance();
      for (let i = 0; maxDays > i; i++) {
        dates.push(new Date(startDate.getTime() + i * 24 * 3600 * 1000));
      }
      const availability = {};
      await Promise.all(
        dates.map(async (date) => {
          const { data } = await request({
            method: 'POST',
            path: '/1/availability/check',
            body: {
              date: formatDateStr(date),
              location: locationId,
              fullDay: !this.props.restrict,
            },
          });
          availability[formatDateStr(date)] = data;
        })
      );
      this.setState({
        startDate,
        availability,
      });
      this.checkSlotMatch(availability);
      if (this.props.onAvailabilityReceived) {
        this.props.onAvailabilityReceived({
          startDate,
          availability,
        });
      }
    } catch (error) {
      this.props.onError(error);
    }
  }

  checkSlotMatch(availability) {
    const { dateStr, hour, minute } = this.props;
    if (dateStr && hour != null && minute != null) {
      const { slots } = availability[dateStr];
      let key = null;
      for (let slot of slots) {
        if (slot.hour === hour && slot.minute === minute) {
          key = slot.key;
          break;
        }
      }
      if (key) {
        this.setState({
          key,
        });
      }
    }
  }

  onSlotChange = (evt, { value }) => {
    const { dateStr } = this.props;
    const { availability } = this.state;
    let selectedSlot = null;
    if (availability && dateStr) {
      const { slots } = availability[dateStr];
      for (let slot of slots) {
        if (slot.key === value) {
          selectedSlot = slot;
          break;
        }
      }
    }
    this.setState({
      key: selectedSlot?.key || null,
    });
    this.props.onSlotChange(selectedSlot);
  };

  render() {
    const { dateStr, location } = this.props;
    const { startDate, key, availability } = this.state;

    let options = [];

    const maxDays = this.determineMaxDaysInAdvance();

    if (availability && dateStr && availability[dateStr]) {
      const { dateDayName, slots } = availability[dateStr];
      options = slots.map(({ key, formatted, numAvailable }) => {
        let numAvailableFinal = numAvailable;
        if (numAvailableFinal < 0) {
          numAvailableFinal = 0;
        }
        return {
          key,
          text: `${dateDayName}, ${formatted} - (${numAvailableFinal} available)`,
          value: key,
        };
      });
    }

    const availabilityStartDate = this.determineAvailabilityStartDate(
      startDate
    );

    if (availability) {
      return (
        <React.Fragment>
          <DateTimeField
            required
            name="requestedDate"
            includeTime={false}
            value={new Date(`${dateStr}T12:00:00`)}
            label="Appointment Date"
            onChange={(value, name) => this.props.onDateChange(name, value)}
            dayPickerProps={{
              month: availabilityStartDate,
              disabledDays: this.props.restrict
                ? [
                    {
                      after: new Date(Date.now() + maxDays * 24 * 3600 * 1000),
                      before: startDate,
                    },
                    (day) => this.checkDay(day),
                  ]
                : [],
            }}
          />
          <Form.Field required>
            {location.timeZone && (
              <small style={{ float: 'right' }}>
                Timezone:{' '}
                {location.timeZone.replace(/\_/g, ' ').replace(/\//, ' / ')}
              </small>
            )}
            <label>Preferred Time</label>
            {dateStr ? (
              <Dropdown
                selection
                value={key}
                options={options}
                onChange={this.onSlotChange}
              />
            ) : (
              <Dropdown disabled fluid selection options={[]} />
            )}
          </Form.Field>
        </React.Fragment>
      );
    } else {
      return (
        <Dimmer active inverted>
          <Loader inverted>Calculating availability</Loader>
        </Dimmer>
      );
    }
  }
}
