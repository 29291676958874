import React from 'react';
import { observer, inject } from 'mobx-react';
import { Form, Message, Modal, Button, Dropdown } from 'semantic-ui-react';
import AutoFocus from 'components/AutoFocus';
import UploadsField from 'components/form-fields/Uploads';
import DateYearField from 'components/form-fields/DateYear';
import PatientTypeField from 'components/form-fields/PatientType';
import GradeField from 'components/form-fields/Grade';
import GeoAddressField from 'components/form-fields/GeoAddress';
import { organizationHasFields } from 'utils/organizations';

@inject('patients')
@observer
export default class EditPatient extends React.Component {
  static defaultProps = {
    initialValues: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      dobMonth: props.initialValues.dob
        ? new Date(props.initialValues.dob)
        : new Date(),
      formValues: { ...this.props.initialValues },
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.initialValues !== prevProps.initialValues) {
      this.setState({
        touched: false,
        formValues: { ...this.props.initialValues },
      });
    }
  }

  handleSubmit = () => {
    const { patients, initialValues } = this.props;
    this.setState({
      touched: true,
    });

    const action = initialValues.id
      ? patients.update.bind(patients)
      : patients.create.bind(patients);

    const body = this.state.formValues;

    return action(body).then((err) => {
      if (err instanceof Error) return;
      this.setState({
        formValues: this.props.initialValues,
        open: false,
        touched: false,
      });
      if (this.props.onSave) {
        this.props.onSave();
      }
    });
  };

  setField(name, value) {
    this.setState({
      formValues: {
        ...this.state.formValues,
        [name]: value,
      },
    });
  }

  render() {
    const { organization, patients, initialValues, trigger } = this.props;
    const { formValues = {}, touched, open } = this.state;

    const isUpdate = !!initialValues.id;
    const status = isUpdate
      ? patients.getStatus('update')
      : patients.getStatus('create');

    return (
      <Modal
        closeOnDimmerClick={false}
        closeIcon
        onClose={() =>
          this.setState({
            open: false,
            formValues: this.props.initialValues,
            touched: false,
          })
        }
        onOpen={() => this.setState({ open: true })}
        open={open}
        trigger={trigger}>
        <Modal.Header>
          {isUpdate
            ? `Edit "${initialValues.firstName} ${initialValues.lastName}"`
            : 'New Patient'}
        </Modal.Header>
        <Modal.Content>
          <AutoFocus>
            <Form
              error={touched && Boolean(status.error)}
              onSubmit={() => this.handleSubmit()}>
              {status.error && <Message error content={status.error.message} />}

              {organizationHasFields(organization, 'patientType') && (
                <PatientTypeField
                  organization={organization}
                  value={formValues.type}
                  onChange={(e, { name, value }) => this.setField(name, value)}
                />
              )}

              <Form.Input
                value={formValues.firstName || ''}
                name="firstName"
                label="First Name"
                required
                type="text"
                onChange={(e, { name, value }) => this.setField(name, value)}
              />

              <Form.Input
                value={formValues.lastName || ''}
                name="lastName"
                label="Last Name"
                required
                type="text"
                onChange={(e, { name, value }) => this.setField(name, value)}
              />

              <Form.Input
                value={formValues.email || ''}
                name="email"
                label="Email"
                required
                type="text"
                onChange={(e, { name, value }) => this.setField(name, value)}
              />

              <Form.Input
                value={formValues.phoneNo || ''}
                name="phoneNo"
                label="Phone Number"
                required
                type="text"
                onChange={(e, { name, value }) => this.setField(name, value)}
              />

              <GeoAddressField
                value={formValues.address || ''}
                geoLocation={formValues.geoLocation}
                autoComplete="off"
                label="Address"
                onChange={({ address, geoLocation }) => {
                  this.setState({
                    formValues: {
                      ...this.state.formValues,
                      address,
                      geoLocation,
                    },
                  });
                }}
              />

              {formValues.type === 'student' && (
                <GradeField
                  value={formValues.grade}
                  onChange={(e, { name, value }) => this.setField(name, value)}
                />
              )}

              <DateYearField
                label="Date of Birth"
                required={organizationHasFields(organization, 'dob')}
                month={this.state.dobMonth}
                selectedDays={formValues.dob ? new Date(formValues.dob) : null}
                onDateChange={(date) => this.setField('dob', date)}
                onMonthChange={(date) => this.setState({ dobMonth: date })}
              />

              <Form.Field>
                <label>Gender</label>
                <Dropdown
                  fluid
                  selection
                  name="sex"
                  value={formValues.sex}
                  options={[
                    {
                      key: 'female',
                      value: 'female',
                      text: 'Female',
                    },
                    {
                      key: 'male',
                      value: 'male',
                      text: 'Male',
                    },
                  ]}
                  onChange={(e, { name, value }) => this.setField(name, value)}
                />
              </Form.Field>

              {organizationHasFields(organization, 'cleared') && (
                <Form.Checkbox
                  toggle
                  required
                  label="Cleared"
                  name="cleared"
                  checked={
                    formValues.cleared != null ? formValues.cleared : true
                  }
                  onChange={(e, { name, checked }) =>
                    this.setField(name, checked)
                  }
                />
              )}

              <Form.Input
                value={formValues.insurancePlanName || ''}
                name="insurancePlanName"
                label="Insurance Plan Name"
                required
                type="text"
                onChange={(e, { name, value }) => this.setField(name, value)}
              />

              <Form.Input
                value={formValues.insuranceMemberId || ''}
                name="insuranceMemberId"
                label="Insurance Member ID"
                required
                type="text"
                onChange={(e, { name, value }) => this.setField(name, value)}
              />

              <UploadsField
                type="pdf"
                label="Consent Form"
                value={formValues.consentForm || null}
                onChange={({ value }) => this.setField('consentForm', value)}
              />
            </Form>
          </AutoFocus>
        </Modal.Content>
        <Modal.Actions>
          <Button
            loading={status.request === true}
            primary
            content={isUpdate ? 'Update' : 'Create'}
            onClick={this.handleSubmit}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}
