import { createOptions } from 'utils/form';

export const labOrderStatuses = {
  needsRequisition: {
    name: 'Needs Requisition',
    icon: 'wait',
  },
  requisitioned: {
    name: 'Requisitioned',
    icon: 'checkmark',
    color: 'olive',
  },
  cancelled: {
    name: 'Cancelled',
    icon: 'dont',
    color: 'red',
  },
};

export const labOrderStatusOptions = createOptions(labOrderStatuses);
