import React from 'react';
import request from 'utils/request';
import { Link } from 'react-router-dom';
import {
  Table,
  Divider,
  Button,
  Header,
  Loader,
  Message,
} from 'semantic-ui-react';
import { DateTime } from 'luxon';
import { formatUsd, formatTime } from 'utils/formatting';
import { sum } from 'lodash';
import ExportButton from 'components/ExportButton';

export default class Overview extends React.Component {
  state = {
    loading: true,
  };
  componentDidMount() {
    this.fetch();
  }
  async fetch() {
    try {
      const { data } = await request({
        method: 'POST',
        path: '/1/appointments/report',
      });
      this.setState({ data, loading: false });
    } catch (error) {
      this.setState({ error, loading: false });
    }
  }
  render() {
    const { data, loading, error } = this.state;
    if (loading) return <Loader active />;
    if (error) return <Message error content={error.message} />;
    return (
      <>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Appointment</Table.HeaderCell>
              <Table.HeaderCell>Cost Breakdown</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">Costs</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">Revenue</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">Profit</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.map((item) => {
              const mainPatient = item.patients.length
                ? item.patients[0]
                : undefined;
              return (
                <Table.Row key={item.id}>
                  <Table.Cell>
                    <Link
                      to={`/appointments/${item.id}`}
                      style={{
                        textDecoration:
                          item.status === 'cancelled'
                            ? 'line-through'
                            : 'normal',
                      }}>
                      {mainPatient.firstName} {mainPatient.lastName}
                    </Link>
                    <p>
                      {DateTime.fromISO(item.requestedDate, {
                        zone: 'America/New_York',
                      }).toLocaleString(DateTime.DATE_MED)}
                      ,&nbsp;
                      {formatTime(item.requestedHour, item.requestedMinute)}
                    </p>
                  </Table.Cell>
                  <Table.Cell>
                    <Table size="small" basic="very" celled>
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell>Lab</Table.Cell>
                          <Table.Cell>
                            {formatUsd(item.orderStats.totalProductCosts)}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell
                            style={{
                              borderTop: '1px solid rgba(34, 36, 38, 0.1)',
                            }}>
                            Provider
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              borderTop: '1px solid rgba(34, 36, 38, 0.1)',
                            }}>
                            {formatUsd(item.orderStats.providerCosts)}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell
                            style={{
                              borderTop: '1px solid rgba(34, 36, 38, 0.1)',
                            }}>
                            Travel
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              borderTop: '1px solid rgba(34, 36, 38, 0.1)',
                            }}>
                            {formatUsd(item.orderStats.travelCosts)}
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    {formatUsd(item.orderStats.totalCosts)}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    {formatUsd(item.orderStats.totalRevenue)}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    {formatUsd(item.orderStats.totalProfit)}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell />
              <Table.HeaderCell />
              <Table.HeaderCell textAlign="center">
                <strong>
                  {formatUsd(
                    sum(data.map((item) => item.orderStats.totalCosts))
                  )}
                </strong>
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                <strong>
                  {formatUsd(
                    sum(data.map((item) => item.orderStats.totalRevenue))
                  )}
                </strong>
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                <strong>
                  {formatUsd(
                    sum(data.map((item) => item.orderStats.totalProfit))
                  )}
                </strong>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
        <Divider hidden />
        <ExportButton
          content="Export All"
          path={'/1/appointments/report'}
          filename="raw-financials.csv"
        />
      </>
    );
  }
}
