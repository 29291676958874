import React from 'react';
import request from 'utils/request';
import { Message, Icon } from 'semantic-ui-react';
import config from 'config';

export default class FetchObject extends React.Component {
  state = {
    data: null,
    loading: true,
    error: null,
  };

  componentDidMount() {
    this.fetch(this.props);
  }

  fetch() {
    const { id } = this.props;
    request({
      method: 'GET',
      path: `/1/locations/${id}/calendar-token`,
    })
      .then(({ data }) => {
        this.setState({ data, error: null, loading: false });
      })
      .catch((error) => {
        this.setState({ error, loading: false });
      });
  }

  render() {
    const { loading, error, data } = this.state;
    if (loading) return <p>loading</p>;
    if (error) return <Message error content={error.message} />;
    return (
      <a
        href={`${config.API_URL}/1/locations/calendar/${data.calendarToken}`}
        target="_blank"
        style={{ float: 'right' }}
        rel="noopener noreferrer">
        <Icon name="calendar" />
      </a>
    );
  }
}
