import React from 'react';
import {
  Segment,
  Loader,
  Message,
  Image,
  Header,
  Grid,
  Container,
  Divider,
} from 'semantic-ui-react';
import { observer, inject } from 'mobx-react';
import PageCenter from 'components/PageCenter';
import LogoTitle from 'components/LogoTitle';
import request from 'utils/request';
import { urlForUpload } from 'utils/uploads';
import ReactMarkdown from 'react-markdown';

import Form from './Form';

function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return undefined;
  if (!results[2]) return undefined;
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

@inject('appointments', 'routing')
@observer
export default class CreateAppointments extends React.Component {
  state = {
    done: false,
    loading: false,
    error: null,
    organization: null,
    organizationLoading: true,
    setLocationName: undefined,
    referralCode: getParameterByName('referralCode'),
    clickIdentifier: getParameterByName('gclid'),
    hideLocation: getParameterByName('hideLocation'),
  };

  componentDidMount() {
    const { params } = this.props.match;
    const organizationSlug = params.organizationSlug || 'openclear';
    if (params.locationName) {
      this.setState({ setLocationName: params.locationName });
    }
    this.fetchOrganization(organizationSlug);
  }

  async fetchOrganization(organizationSlug) {
    try {
      const { data } = await request({
        method: 'GET',
        path: `/1/organizations/${organizationSlug}`,
      });
      this.setState({
        organization: data,
        error: null,
        organizationLoading: false,
      });
    } catch (error) {
      this.setState({
        error,
        organizationLoading: false,
      });
    }
  }

  onSubmit = async (body) => {
    const { referralCode, clickIdentifier } = this.state;
    this.setState({
      loading: true,
      error: null,
    });
    try {
      if (referralCode) {
        if (!body.salesInfo) body.salesInfo = {};
        body.salesInfo.referralCode = referralCode;
      }
      if (clickIdentifier) {
        if (!body.salesInfo) body.salesInfo = {};
        body.salesInfo.clickIdentifier = clickIdentifier;
      }
      if (this.state.organization.bookingConsentText) {
        if (!body.consentGiven) {
          alert('Please make sure to check the consent checkbox');
          this.setState({
            loading: false,
          });
          return;
        }
      }
      if (this.state.organization.type === 'summerCamp') {
        if (!body.patient.dob) {
          this.setState({
            error: new Error('Please make sure to supply a date of birth'),
            loading: false,
          });
          return;
        }
        if (!body.patient.sex) {
          this.setState({
            error: new Error('Please make sure to supply a gender'),
            loading: false,
          });
          return;
        }
        if (!body.patient.insurancePlanName) {
          this.setState({
            error: new Error(
              'Please make sure to supply an insurance plan name'
            ),
            loading: false,
          });
          return;
        }
        if (!body.patient.insuranceMemberId) {
          this.setState({
            error: new Error(
              'Please make sure to supply an insurance member ID'
            ),
            loading: false,
          });
          return;
        }
      }
      await request({
        method: 'POST',
        path: '/1/appointments',
        body,
        organizationId: this.state.organization.id,
      });
      this.setState({
        body,
        done: true,
        loading: false,
        error: null,
      });
    } catch (error) {
      if (error.message === '"dob" is required') {
        error = new Error('Date of birth is required.');
      } else if (error.message === '"requestedHour" is required') {
        error = new Error('Booking time is required.');
      }
      this.setState({
        error,
        loading: false,
      });
    }
  };

  onError = (error) => {
    this.setState({
      error,
    });
  };

  render() {
    const {
      done,
      body,
      error,
      loading,
      organizationLoading,
      organization,
      setLocationName,
      referralCode,
      hideLocation,
    } = this.state;
    if (organizationLoading) {
      return <Loader active />;
    }
    const hasBookingNotice =
      organization &&
      organization.bookingNotice &&
      organization.bookingNotice.length;
    return (
      <div style={{ height: '100%' }}>
        <LogoTitle
          title={
            organization && organization.appointmentsRequireApproval
              ? 'Request Appointment'
              : 'Book Appointment'
          }
        />
        <Divider hidden />
        <Container>
          <Grid centered verticalAlign="top" stackable>
            {hasBookingNotice && (
              <Grid.Column width={5}>
                <ReactMarkdown>{organization.bookingNotice}</ReactMarkdown>
              </Grid.Column>
            )}
            <Grid.Column width={hasBookingNotice ? 11 : 10}>
              <Segment.Group basic>
                <Segment padded>
                  {done ? (
                    organization.appointmentsRequireApproval ? (
                      <Message
                        info
                        icon="phone outline"
                        header="Thank you for your inquiry!"
                        content="Someone will contact you to schedule your appointment"
                      />
                    ) : (
                      <Message
                        info
                        icon="checkmark"
                        header={'Booking Successful!'}
                        content={`We will send a confirmation email to ${body.patient.email}`}
                      />
                    )
                  ) : (
                    <Form
                      hideLocation={hideLocation}
                      setLocationName={setLocationName}
                      onSubmit={this.onSubmit}
                      onError={this.onError}
                      error={error}
                      loading={loading}
                      organization={organization}
                    />
                  )}
                </Segment>
              </Segment.Group>
            </Grid.Column>
          </Grid>
        </Container>
      </div>
    );
  }
}
