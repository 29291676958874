import React from 'react';
import DayPicker from 'react-day-picker/DayPicker';
import { Form } from 'semantic-ui-react';

export default class DateYear extends React.Component {
  render() {
    const { label, month, required } = this.props;
    return (
      <Form.Field required={required}>
        <label>{label}</label>
        <DayPicker
          month={month}
          selectedDays={this.props.selectedDays}
          onDayClick={this.props.onDateChange}
          onMonthChange={this.props.onMonthChange}
          captionElement={() => {
            return (
              <YearMonthForm date={month} onChange={this.props.onMonthChange} />
            );
          }}
        />
      </Form.Field>
    );
  }
}

class YearMonthForm extends React.Component {
  constructor(props) {
    super(props);
    const { date = new Date() } = props;
    this.state = {
      year: String(date.getFullYear()),
      month: String(date.getMonth()),
    };
    this.setup();
  }

  setup() {
    this.years = this.getYears();
    this.months = this.getMonths();
    this.formatter = new Intl.DateTimeFormat('en-US', { month: 'long' });
  }

  getYears() {
    const endDate = new Date();
    const startDate = new Date(endDate.getFullYear() - 100, 0);
    const years = [];
    for (
      let year = startDate.getFullYear();
      year <= endDate.getFullYear();
      year += 1
    ) {
      years.push(String(year));
    }
    return years;
  }

  getMonths() {
    const months = [];
    for (let i = 0; i <= 11; i++) {
      months.push(String(i));
    }
    return months;
  }

  componentDidUpdate(lastProps, lastState) {
    const { year, month } = this.state;
    if (year !== lastState.year || month !== lastState.month) {
      this.props.onChange(new Date(+year, +month));
    }
  }

  onChange = (evt) => {
    const { name, value } = evt.target;
    this.setState({
      [name]: value,
    });
  };

  render() {
    const { year, month } = this.state;
    return (
      <div className="DayPicker-Caption">
        <select
          name="month"
          value={month}
          style={{
            width: 'auto',
            padding: '8px',
            display: 'inline-block',
            marginRight: '8px',
          }}
          onChange={this.onChange}>
          {this.months.map((month) => {
            const text = this.formatter.format(
              new Date(this.state.year, month)
            );
            return (
              <option key={month} value={month}>
                {text}
              </option>
            );
          })}
        </select>
        <select
          name="year"
          value={year}
          style={{
            width: 'auto',
            display: 'inline-block',
            padding: '8px',
          }}
          onChange={this.onChange}>
          {this.years.map((year) => {
            return (
              <option key={year} value={year}>
                {year}
              </option>
            );
          })}
        </select>
      </div>
    );
  }
}
