import React from 'react';
import { Table, Icon } from 'semantic-ui-react';
import { formatUsd } from 'utils/formatting';
import { customTypes } from 'utils/orders';

export default function({ order }) {
  return (
    <Table celled definition>
      <Table.Body>
        {order.items.map((item, i) => {
          const name = item.externalName || item.name;
          return (
            <Table.Row key={i}>
              <Table.Cell>
                {item.type === 'product' ? (
                  `${name} x ${item.quantity}`
                ) : (
                  <>
                    {name} <Icon name={customTypes[item.type].icon} />
                  </>
                )}
              </Table.Cell>
              <Table.Cell>{formatUsd(item.quantity * item.price)}</Table.Cell>
            </Table.Row>
          );
        })}

        <Table.Row>
          <Table.Cell textAlign="right">Total</Table.Cell>
          <Table.Cell>
            <strong>{formatUsd(order.totalPrice)}</strong>
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
}
