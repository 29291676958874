import React from 'react';
import { DateTime } from 'luxon';
import request from 'utils/request';
import { Button, Modal, Message, Progress } from 'semantic-ui-react';

export default class ViewStripeConnectLink extends React.Component {
  static defaultProps = {};

  state = {
    started: false,
    open: false,
    loading: true,
    error: false,
  };

  link() {
    this.setState({ started: true });
    const { initialValues } = this.props;
    request({
      method: 'POST',
      path: `/1/payment-accounts/${initialValues.id}/link`,
    })
      .then(({ data }) => {
        this.setState({ loading: false, error: null, data });
      })
      .catch((error) => {
        this.setState({ loading: false, error });
      });
  }

  renderContent() {
    const { data, error, loading } = this.state;
    if (error) {
      return <Message error content={error.message} />;
    }
    if (loading) {
      return <Progress label="Generating Stripe Link" active percent={100} />;
    }

    const { expires_at } = data;
    const expiresAt = new Date(expires_at * 1000);
    return (
      <>
        <p>
          The following link can be used to onboard the payee's banking
          information.
        </p>
        <p>
          This link will expires{' '}
          {DateTime.fromJSDate(new Date(expiresAt)).toRelative()}.
        </p>
        <Message style={{ wordWrap: 'break-word' }}>
          <a href={data.url} target="_blank" rel="noopener noreferrer">
            {data.url}
          </a>
        </Message>
      </>
    );
  }

  render() {
    const { trigger } = this.props;
    const { open, started } = this.state;
    return (
      <Modal
        closeOnDimmerClick={false}
        closeIcon
        trigger={trigger}
        onClose={() => this.setState({ open: false })}
        onOpen={() => this.setState({ open: true })}
        open={open}>
        <Modal.Header>Stripe Onboarding Link</Modal.Header>
        <Modal.Content>
          {started ? (
            this.renderContent()
          ) : (
            <>
              <Button
                primary
                icon="magic"
                content="Generate Stripe Onboarding Link"
                onClick={() => this.link()}
              />
            </>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            content="Close"
            onClick={() => this.setState({ open: false })}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}
