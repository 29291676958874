import React from 'react';
import { observer, inject } from 'mobx-react';
import { DateTime } from 'luxon';
import AppWrapper from 'components/AppWrapper';
import styled from 'styled-components';
import { formatTime } from 'utils/formatting';

import EditScheduleOverride from 'components/modals/EditScheduleOverride';
import Pagination from 'components/Pagination';
import Protected from 'components/Protected';

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

import {
  Container,
  Header,
  Table,
  Loader,
  Segment,
  Dimmer,
  Message,
  Modal,
  Button,
  Label,
} from 'semantic-ui-react';

function formatAlways(weekdays) {
  if (weekdays && weekdays.length) {
    return weekdays.join(', ');
  }
  return 'Every Day';
}

function formatRange(startDate, endDate) {
  if (startDate === endDate) {
    return startDate;
  }
  return `${startDate} - ${endDate}`;
}

@inject('appSession', 'scheduleOverrides')
@observer
export default class ScheduleOverrides extends React.Component {
  state = {
    showCreateDialog: false,
    editItem: null,
    currentPage: 1,
  };
  constructor(props) {
    super(props);
    this.createOrEditDialog = React.createRef();
  }

  componentDidMount() {
    this.props.scheduleOverrides.fetchItems();
  }

  handleRemove = (item) => {
    const { scheduleOverrides } = this.props;
    scheduleOverrides.delete(item);
  };

  render() {
    const { scheduleOverrides } = this.props;
    const listStatus = scheduleOverrides.getStatus('list');
    const deleteStatus = scheduleOverrides.getStatus('delete');

    return (
      <AppWrapper>
        <Container>
          <Header as="h2">
            Schedule Overrides
            <Protected endpoint="scheduleOverrides" permission="write">
              <EditScheduleOverride
                trigger={
                  <Button
                    primary
                    floated="right"
                    style={{ marginTop: '-5px' }}
                    content="New Schedule Override"
                    icon="plus"
                  />
                }
              />
            </Protected>
          </Header>
          <div className="list">
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={3}>Name</Table.HeaderCell>
                  <Table.HeaderCell width={1}>Priority</Table.HeaderCell>
                  <Table.HeaderCell width={3}>Status</Table.HeaderCell>
                  <Table.HeaderCell>When</Table.HeaderCell>
                  <Table.HeaderCell width={3} textAlign="center">
                    Actions
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {!scheduleOverrides.items.length && (
                  <Table.Row>
                    <Table.Cell>No schedule overrides added yet</Table.Cell>
                  </Table.Row>
                )}
                {scheduleOverrides.items.map((item) => {
                  return (
                    <Table.Row key={item.id}>
                      <Table.Cell>{item.name}</Table.Cell>
                      <Table.Cell>{item.priority}</Table.Cell>
                      <Table.Cell>
                        {formatTime(item.hourStart, 0)} -
                        {formatTime(item.hourEnd, 0)}
                        <Label
                          content={item.status}
                          style={{ float: 'right' }}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        {item.repeatAlways
                          ? formatAlways(item.repeatWeekdays)
                          : formatRange(
                              item.repeatDateStart,
                              item.repeatDateEnd
                            )}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        <EditScheduleOverride
                          initialValues={item}
                          trigger={
                            <Button
                              style={{ marginLeft: '20px' }}
                              basic
                              icon="edit"
                            />
                          }
                        />
                        <Modal
                          header={`Are you sure you want to delete "${item.name}"?`}
                          content="All data will be permanently deleted"
                          status={deleteStatus}
                          trigger={<Button basic icon="trash" />}
                          closeIcon
                          actions={[
                            {
                              key: 'delete',
                              primary: true,
                              content: 'Delete',
                              onClick: () => this.handleRemove(item),
                            },
                          ]}
                        />
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
            {listStatus.success &&
              scheduleOverrides.totalItems > scheduleOverrides.limit && (
                <Center>
                  <Pagination
                    limit={scheduleOverrides.limit}
                    page={scheduleOverrides.page}
                    total={scheduleOverrides.totalItems}
                    onPageChange={(e, { activePage }) => {
                      scheduleOverrides.setPage(activePage);
                      scheduleOverrides.fetchItems().then(() => {
                        window.scrollTo(0, 0);
                      });
                    }}
                  />
                </Center>
              )}
            {listStatus.request && (
              <Segment style={{ height: '100px' }}>
                <Dimmer active inverted>
                  <Loader>Loading</Loader>
                </Dimmer>
              </Segment>
            )}
            {listStatus.error && (
              <Message error content={listStatus.error.message} />
            )}
          </div>
        </Container>
      </AppWrapper>
    );
  }
}
