import React from 'react';
import { observer, inject } from 'mobx-react';
import { NavLink, Link } from 'react-router-dom';
import {
  Container,
  Dropdown,
  Icon,
  Menu,
  Button,
  Divider,
} from 'semantic-ui-react';
import logoInverted from 'assets/favicon.svg';
import Protected from 'components/Protected';
import OrganizationSwitcher from 'components/OrganizationSwitcher';

@inject('me')
@observer
export default class AppWrapper extends React.Component {
  render() {
    const { me } = this.props;
    const { organization } = me;
    if (
      window.innerWidth < 600 &&
      (organization.enableResultsQuickEntry ||
        organization.enableMeasurementsQuickEntry)
    ) {
      return (
        <div
          style={{
            width: '100%',
            padding: '4em',
          }}>
          {organization.enableResultsQuickEntry && (
            <Button
              as="a"
              href="/quick-entry/results"
              icon="check"
              content="Results Entry"
              primary
              fluid
              size="huge"
            />
          )}
          <Divider hidden />
          {organization.enableResultsQuickEntry && (
            <Button
              as="a"
              href="/quick-entry/measurements"
              icon="thermometer half"
              content="Measurements Entry"
              primary
              fluid
              size="huge"
            />
          )}
        </div>
      );
    }
    return (
      <div>
        <Menu inverted fixed="top">
          <Container>
            <Menu.Item as={Link} to="/">
              <img
                style={{ width: '30px' }}
                className="logo"
                src={`${logoInverted}`}
              />
            </Menu.Item>
            <Protected endpoint="appointments">
              <Menu.Item as={NavLink} to="/appointments">
                Appointments
              </Menu.Item>
            </Protected>
            <Protected endpoint="myAppointments">
              <Menu.Item as={NavLink} to="/my-appointments">
                My Appointments
              </Menu.Item>
            </Protected>
            <Protected endpoint="patients">
              <Menu.Item as={NavLink} to="/patients">
                Patients
              </Menu.Item>
            </Protected>
            {!organization.appointmentsWithoutScheduling && (
              <Protected endpoint="scheduleOverrides">
                <Menu.Item as={NavLink} to="/availability">
                  Availability
                </Menu.Item>
              </Protected>
            )}
            <Menu.Menu position="right">
              {organization.enableResultsQuickEntry && (
                <Protected endpoint="results">
                  <Menu.Item
                    icon="check"
                    content="Quick Test Entry"
                    title="Enter Test Results"
                    as={Link}
                    to="/quick-entry/results"
                  />
                </Protected>
              )}
              {organization.enableMeasurementsQuickEntry && (
                <Protected endpoint="measurements">
                  <Menu.Item
                    icon="thermometer half"
                    content="Measure"
                    title="Enter Temperatures"
                    as={Link}
                    to="/quick-entry/measure"
                  />
                </Protected>
              )}
              <OrganizationSwitcher />
              <Dropdown
                className="account"
                item
                trigger={
                  <span>
                    <Icon name="user" /> {me.user && me.user.name}
                  </span>
                }>
                <Dropdown.Menu>
                  <Dropdown.Item as={Link} to="/settings">
                    Settings
                  </Dropdown.Item>
                  <Protected endpoint="users">
                    <Dropdown.Item as={Link} to="/docs/getting-started">
                      API Docs
                    </Dropdown.Item>
                  </Protected>
                  {!organization.appointmentsWithoutScheduling && (
                    <Protected endpoint="scheduleOverrides">
                      <Dropdown.Item as={Link} to="/schedule-overrides">
                        Scheduling
                      </Dropdown.Item>
                    </Protected>
                  )}
                  {organization.enablePayments && (
                    <Protected endpoint="orders">
                      <Dropdown.Item as={Link} to="/orders">
                        Payments
                      </Dropdown.Item>
                    </Protected>
                  )}
                  <Protected endpoint="reports">
                    {me.organization.type === 'conciergeService' && (
                      <Dropdown.Item as={Link} to="/reports/concierge-service">
                        Reports
                      </Dropdown.Item>
                    )}
                  </Protected>
                  <Protected endpoint="labOrders">
                    <Dropdown.Item as={Link} to="/lab-orders">
                      Lab Orders
                    </Dropdown.Item>
                  </Protected>
                  <Protected endpoint="results">
                    <Dropdown.Item as={Link} to="/results">
                      Results
                    </Dropdown.Item>
                  </Protected>
                  <Protected endpoint="products">
                    <Dropdown.Item as={Link} to="/products">
                      Products
                    </Dropdown.Item>
                  </Protected>
                  <Protected endpoint="locations">
                    <Dropdown.Item as={Link} to="/locations">
                      Locations
                    </Dropdown.Item>
                  </Protected>
                  <Protected endpoint="organizations" scope="global">
                    <Dropdown.Item as={Link} to="/organizations">
                      Organizations
                    </Dropdown.Item>
                  </Protected>
                  <Protected endpoint="users" scope="global">
                    <Dropdown.Item as={Link} to="/users">
                      Users
                    </Dropdown.Item>
                  </Protected>
                  <Protected endpoint="users" scope="global">
                    <Dropdown.Item as={Link} to="/invites">
                      Invites
                    </Dropdown.Item>
                  </Protected>
                  {organization.enablePayments && (
                    <Protected endpoint="paymentAccounts">
                      <Dropdown.Item as={Link} to="/payment-accounts">
                        Payment Accounts
                      </Dropdown.Item>
                    </Protected>
                  )}
                  {organization.enablePayments && (
                    <Protected endpoint="billingAccounts">
                      <Dropdown.Item as={Link} to="/billing-accounts">
                        Billing Accounts
                      </Dropdown.Item>
                    </Protected>
                  )}
                  <Dropdown.Item as={Link} to="/logout">
                    Log Out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Menu>
          </Container>
        </Menu>
        <Container style={{ marginTop: '100px', paddingBottom: '100px' }}>
          {this.props.children}
        </Container>
      </div>
    );
  }
}
