import React from 'react';
import Pagination from 'components/Pagination';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import { urlForUpload } from 'utils/uploads';
import EditResult from 'components/modals/EditResult';
import { formatResult } from 'utils/formatting';

import {
  Table,
  Loader,
  Segment,
  Dimmer,
  Message,
  Modal,
  Button,
} from 'semantic-ui-react';

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

export default class DataTable extends React.Component {
  render() {
    const { status, data: items, meta = {}, page } = this.props;
    return (
      <div className="list">
        {status.success && !items.length && <Message>No results</Message>}

        {status.success && items.length > 0 && (
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Tested Date</Table.HeaderCell>
                <Table.HeaderCell>Received Date</Table.HeaderCell>
                <Table.HeaderCell>Result</Table.HeaderCell>
                <Table.HeaderCell>File</Table.HeaderCell>
                <Table.HeaderCell width={3} textAlign="center">
                  Actions
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {items.map((item) => {
                return (
                  <Table.Row key={item.id}>
                    <Table.Cell>
                      {DateTime.fromJSDate(
                        new Date(item.testedAt)
                      ).toLocaleString(DateTime.DATE_FULL)}
                    </Table.Cell>
                    <Table.Cell>
                      {item.resultsReceivedAt
                        ? DateTime.fromJSDate(
                            new Date(item.resultsReceivedAt)
                          ).toLocaleString(DateTime.DATE_FULL)
                        : ''}
                    </Table.Cell>
                    <Table.Cell>{formatResult(item.result)}</Table.Cell>
                    <Table.Cell>
                      {item.upload && (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={urlForUpload(item.upload)}>
                          <Button basic icon="file pdf" />
                        </a>
                      )}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <EditResult
                        result={item}
                        onSave={this.props.onUpdateItem}
                        trigger={
                          <Button
                            style={{ marginLeft: '20px' }}
                            basic
                            icon="edit"
                          />
                        }
                      />
                      <Modal
                        header={`Are you sure you want to delete?`}
                        content="All data will be permanently deleted"
                        trigger={<Button basic icon="trash" />}
                        closeIcon
                        actions={[
                          {
                            key: 'delete',
                            primary: true,
                            content: 'Delete',
                            onClick: () => this.props.onDeleteItem(item),
                          },
                        ]}
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        )}
        {status.success && meta.total > this.props.limit && (
          <Center>
            <Pagination
              limit={this.props.limit}
              page={page}
              total={meta.total}
              onPageChange={(e, { activePage }) => {
                this.props.setPage(activePage).then(() => {
                  window.scrollTo(0, 0);
                });
              }}
            />
          </Center>
        )}
        {status.request && (
          <Segment style={{ height: '100px' }}>
            <Dimmer active inverted>
              <Loader>Loading</Loader>
            </Dimmer>
          </Segment>
        )}
        {status.error && <Message error content={status.error.message} />}
      </div>
    );
  }
}
