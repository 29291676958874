import React from 'react';
import Pagination from 'components/Pagination';

import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import ViewOrder from 'components/modals/ViewOrder';
import request from 'utils/request';

import {
  Table,
  Loader,
  Segment,
  Dimmer,
  Message,
  Modal,
  Button,
  Icon,
  Checkbox,
} from 'semantic-ui-react';
import { formatUsd } from 'utils/formatting';

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

export default class DataTable extends React.Component {
  render() {
    const {
      status,
      data: items,
      meta = {},
      getSort,
      setSort,
      page,
    } = this.props;
    return (
      <div className="list">
        {status.success && !items.length && (
          <Message>No orders assigned to this billing account yet</Message>
        )}

        {status.success && items.length > 0 && (
          <Table celled sortable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Patient</Table.HeaderCell>
                <Table.HeaderCell>Total</Table.HeaderCell>
                <Table.HeaderCell>Created At</Table.HeaderCell>
                <Table.HeaderCell textAlign="center" width={3}>
                  Is Paid by Billing Account?
                </Table.HeaderCell>
                <Table.HeaderCell width={3} textAlign="center">
                  Actions
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {!items.length && (
                <Table.Row>
                  <Table.Cell>No orders created yet</Table.Cell>
                </Table.Row>
              )}
              {items.map((item) => {
                return (
                  <Table.Row key={item.id}>
                    <Table.Cell>
                      <NavLink to={`/patients/${item.patient.id}`}>
                        {item.patient.firstName} {item.patient.lastName}
                      </NavLink>
                    </Table.Cell>
                    <Table.Cell>{formatUsd(item.totalPrice)}</Table.Cell>
                    <Table.Cell>
                      {DateTime.fromJSDate(
                        new Date(item.createdAt)
                      ).toLocaleString(DateTime.DATETIME_MED)}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <Checkbox
                        toggle
                        checked={item.billingAccountPaid}
                        onChange={(e, { checked }) =>
                          this.props.onUpdateItem(item, checked)
                        }
                      />
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <ViewOrder
                        order={item}
                        readOnly
                        trigger={<Button basic icon="list" />}
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        )}
        {status.success && meta.total > this.props.limit && (
          <Center>
            <Pagination
              limit={this.props.limit}
              page={page}
              total={meta.total}
              onPageChange={(e, { activePage }) => {
                this.props.setPage(activePage).then(() => {
                  window.scrollTo(0, 0);
                });
              }}
            />
          </Center>
        )}
        {status.request && (
          <Segment style={{ height: '100px' }}>
            <Dimmer active inverted>
              <Loader>Loading</Loader>
            </Dimmer>
          </Segment>
        )}
        {status.error && <Message error content={status.error.message} />}
      </div>
    );
  }
}
