import React from 'react';
import { Message } from 'semantic-ui-react';
import { observer, inject } from 'mobx-react';
import PageCenter from 'components/PageCenter';
import PageLoader from 'components/PageLoader';
import { Route, Redirect } from 'react-router-dom';
import { userHasEndpointAccess } from 'utils/permissions';

import Boot from 'components/Boot';

@inject('appSession', 'me')
@observer
export default class Protected extends React.Component {
  constructor(props) {
    super(props);
    this.handleLoading();
  }

  handleLoading() {
    const { appSession, me } = this.props;
    if (appSession.token) {
      me.fetch('boot').then((err) => {
        if (err instanceof Error) return;
        me.fetchOrganization(me.determineOrganizationId()).then((err) => {
          if (err instanceof Error) return;
          appSession.setLoaded();
        });
      });
      return;
    } else {
      appSession.setLoaded();
    }
  }

  meetsPermissions(user) {
    const { endpoint, permission, scope = undefined } = this.props;
    if (permission) {
      return userHasEndpointAccess(user, {
        endpoint,
        permission,
        scope,
      });
    }
    return true;
  }

  render() {
    const { me, appSession, component: Component, ...rest } = this.props;
    const status = me.getStatus('boot');

    if (!appSession.loaded) {
      return (
        <PageCenter>
          {status.error && (
            <React.Fragment>
              <Message
                error
                header="Something went wrong"
                content={status.error.message}
              />
              <a href="/logout">Logout</a>
            </React.Fragment>
          )}
          {!status.error && <PageLoader />}
        </PageCenter>
      );
    } else if (!me.user) {
      return <Redirect to="/login" />;
    }

    if (!this.meetsPermissions(me.user)) {
      return (
        <PageCenter>
          <React.Fragment>
            <Message
              error
              header="Access Denied"
              content={`This view requires ${this.props.endpoint} ${this.props.permission} permissions`}
            />
            <a href="/">Take me Home</a>
            <a href="/logout" style={{ float: 'right' }}>
              Logout
            </a>
          </React.Fragment>
        </PageCenter>
      );
    }

    return (
      <Route
        {...rest}
        render={(props) => {
          return (
            <Boot>
              <Component {...props} />
            </Boot>
          );
        }}
      />
    );
  }
}
