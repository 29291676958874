export const paymentAccountTypes = {
  provider: {
    name: 'Provider',
    icon: 'doctor',
  },
  lab: {
    name: 'Lab',
    icon: 'flask',
  },
};
