import React from 'react';
import { merge } from 'lodash';
import {
  Header,
  Form,
  Message,
  Button,
  Dropdown,
  Divider,
} from 'semantic-ui-react';
import { formatDateStr } from 'utils/formatting';
import Availability from 'components/form-fields/Availability';
import DateYearField from 'components/form-fields/DateYear';
import LocationField from 'components/form-fields/LocationField';
import PatientTypeField from 'components/form-fields/PatientType';
import GeoAddressField from 'components/form-fields/GeoAddress';
import GradeField from 'components/form-fields/Grade';
import { organizationHasFields } from 'utils/organizations';
import ReactMarkdown from 'react-markdown';

export default class EditAppointment extends React.Component {
  state = {
    open: false,
    location: null,
    dobMonth: new Date(),
    formValues: merge(this.getDefaultFormValues(), this.props.initialValues),
  };

  componentDidUpdate(prevProps) {
    if (this.props.initialValues !== prevProps.initialValues) {
      this.setState({
        touched: false,
        formValues: { ...this.props.initialValues },
      });
    }
  }

  onLocationChange = (evt, { value, location }) => {
    this.setField('location', value);
    this.setState({
      location,
    });
  };

  onAvailabilityReceived = ({ startDate }) => {
    this.setField('requestedDate', formatDateStr(startDate));
  };

  onSubmit = () => {
    const { organization } = this.props;
    const body = this.state.formValues;
    if (organization && organization.id) {
      body.organization = organization.id;
    }
    this.props.onSubmit(body);
  };

  setField(name, value) {
    this.setState({
      formValues: {
        ...this.state.formValues,
        [name]: value,
      },
    });
  }

  setPatientField(name, value) {
    this.setState({
      formValues: {
        ...this.state.formValues,
        patient: {
          ...this.state.formValues.patient,
          [name]: value,
        },
      },
    });
  }

  onDateChange = (name, value) => {
    const dateObj = new Date(Date.parse(value));
    this.setField(name, formatDateStr(dateObj));
  };

  onSlotChange = (slot) => {
    this.setState({
      formValues: {
        ...this.state.formValues,
        requestedHour: slot.hour,
        requestedMinute: slot.minute,
      },
    });
  };

  getDefaultFormValues() {
    const { organization } = this.props;
    if (organization.type === 'educationalInstitution') {
      return {
        patient: {
          type: 'student',
        },
      };
    }
    if (organization.type === 'summerCamp') {
      return {
        patient: {
          type: 'camper',
        },
      };
    }
    return { patient: {} };
  }

  isRequiredField() {
    const { organization } = this.props;
    return organization.type === 'summerCamp';
  }

  render() {
    const { error, loading, organization, hideLocation } = this.props;
    const { location, formValues = {} } = this.state;

    if (location && location.bookingEnabled === false) {
      return <Message content="Bookings Currently Closed" />;
    }

    return (
      <div>
        {error && <Message error content={error.message} />}
        <Form onSubmit={this.onSubmit}>
          <div
            style={{
              display: hideLocation ? 'none' : 'block',
              marginBottom: '0.5em',
            }}>
            <LocationField
              required
              selectFirstIfOneResult
              setLocationName={this.props.setLocationName}
              organizationId={this.props.organization.id}
              value={formValues.location}
              onChange={this.onLocationChange}
              searchParams={{ hideHidden: true }}
            />
          </div>

          {organizationHasFields(organization, 'patientType') && (
            <>
              <PatientTypeField
                organization={organization}
                value={formValues.patient.type}
                onChange={(e, { name, value }) =>
                  this.setPatientField(name, value)
                }
              />

              <Header>Patient</Header>
            </>
          )}

          <Form.Input
            value={formValues.patient.firstName || ''}
            name="firstName"
            label="First Name"
            required
            type="text"
            onChange={(e, { name, value }) => this.setPatientField(name, value)}
          />

          <Form.Input
            value={formValues.patient.lastName || ''}
            name="lastName"
            label="Last Name"
            required
            type="text"
            onChange={(e, { name, value }) => this.setPatientField(name, value)}
          />

          {organizationHasFields(organization, 'dob') && (
            <DateYearField
              label="Date of Birth"
              month={this.state.dobMonth}
              required={this.isRequiredField('patient.dob')}
              selectedDays={formValues.patient.dob}
              onDateChange={(date) => this.setPatientField('dob', date)}
              onMonthChange={(date) => this.setState({ dobMonth: date })}
            />
          )}

          {organizationHasFields(organization, 'sex') && (
            <Form.Field required={this.isRequiredField('patient.sex')}>
              <label>Gender</label>
              <Dropdown
                fluid
                selection
                name="sex"
                value={formValues.patient.sex}
                options={[
                  {
                    key: 'female',
                    value: 'female',
                    text: 'Female',
                  },
                  {
                    key: 'male',
                    value: 'male',
                    text: 'Male',
                  },
                ]}
                onChange={(e, { name, value }) =>
                  this.setPatientField(name, value)
                }
              />
            </Form.Field>
          )}

          {formValues.patient.type === 'student' ||
            (formValues.patient.type === 'camper' && (
              <React.Fragment>
                {organizationHasFields(organization, 'grade') && (
                  <GradeField
                    value={formValues.patient.grade}
                    onChange={(e, { name, value }) =>
                      this.setPatientField(name, value)
                    }
                  />
                )}
                <Header>Parent / Guardian</Header>
                <Form.Input
                  value={formValues.patient.parentFirstName || ''}
                  required={this.isRequiredField('patient.parentFirstName')}
                  name="parentFirstName"
                  label="First Name"
                  type="text"
                  onChange={(e, { name, value }) =>
                    this.setPatientField(name, value)
                  }
                />
                <Form.Input
                  value={formValues.patient.parentLastName || ''}
                  name="parentLastName"
                  required={this.isRequiredField('patient.parentLastName')}
                  label="Last Name"
                  type="text"
                  onChange={(e, { name, value }) =>
                    this.setPatientField(name, value)
                  }
                />
              </React.Fragment>
            ))}

          <Form.Input
            required
            value={formValues.patient.email || ''}
            name="email"
            autoCapitalize="off"
            autoComplete="email"
            label="Email"
            type="email"
            onChange={(e, { name, value }) => this.setPatientField(name, value)}
          />

          <Form.Input
            required
            value={formValues.patient.phoneNo || ''}
            name="phoneNo"
            autoComplete="tel"
            label="Phone Number (+XX for international)"
            type="text"
            onChange={(e, { name, value }) => this.setPatientField(name, value)}
          />

          {organizationHasFields(organization, 'requestedDate') && (
            <Availability
              location={location}
              onError={this.props.onError}
              dateStr={formValues.requestedDate}
              onDateChange={this.onDateChange}
              onSlotChange={this.onSlotChange}
              onAvailabilityReceived={this.onAvailabilityReceived}
              restrict
            />
          )}

          {organizationHasFields(organization, 'address') && (
            <GeoAddressField
              required={organizationHasFields(organization, 'patientType')}
              value={formValues.patient.address || ''}
              geoLocation={formValues.patient.geoLocation}
              autoComplete="address-level1"
              label={
                organizationHasFields(organization, 'patientType')
                  ? 'Address'
                  : 'Address (Optional)'
              }
              onChange={({ address, geoLocation }) => {
                this.setState({
                  formValues: {
                    ...this.state.formValues,
                    patient: {
                      ...this.state.formValues.patient,
                      address,
                      geoLocation,
                    },
                  },
                });
              }}
            />
          )}

          {organization && organization.requireEmployeeId && (
            <Form.Input
              value={formValues.employeeId || ''}
              name="employeeId"
              label="Employee ID (Optional)"
              type="text"
              onChange={(e, { name, value }) => this.setField(name, value)}
            />
          )}

          {organization && organization.enablePromoCode && (
            <Form.Input
              value={formValues.promoCode || ''}
              name="promoCode"
              label="Promo Code (Optional)"
              type="text"
              onChange={(e, { name, value }) => this.setField(name, value)}
            />
          )}

          {organizationHasFields(organization, 'insuranceInformation') && (
            <>
              <Form.Input
                value={formValues.patient.insurancePlanName || ''}
                name="insurancePlanName"
                label="Insurance Plan Name"
                required={this.isRequiredField('patient.insurancePlanName')}
                type="text"
                onChange={(e, { name, value }) =>
                  this.setPatientField(name, value)
                }
              />
              <Form.Input
                value={formValues.patient.insuranceMemberId || ''}
                name="insuranceMemberId"
                label="Insurance Member ID"
                required={this.isRequiredField('patient.insuranceMemberId')}
                type="text"
                onChange={(e, { name, value }) =>
                  this.setPatientField(name, value)
                }
              />
            </>
          )}

          <Form.TextArea
            value={formValues.comment || ''}
            name="comment"
            label="Comment (Optional)"
            type="text"
            onChange={(e, { name, value }) => this.setField(name, value)}
          />

          {organization.bookingConsentText && (
            <>
              <Divider hidden />
              <ReactMarkdown>{organization.bookingConsentText}</ReactMarkdown>
              <Form.Checkbox
                style={{ float: 'left' }}
                name="consentGiven"
                label=""
                toggle
                checked={formValues.consentGiven}
                onChange={(e, { name, checked }) =>
                  this.setField(name, checked)
                }
              />
              <p>
                I have read and agree to the{' '}
                <a href="https://openclear.us/privacy-policy">Privacy Policy</a>{' '}
                &amp; the above Informed Consent
              </p>
              <Divider hidden />
            </>
          )}

          <Button
            loading={loading}
            primary
            fluid
            content={
              organization.appointmentsRequireApproval
                ? 'Send Inquiry'
                : 'Create Booking'
            }
          />
        </Form>
      </div>
    );
  }
}
