import React from 'react';
import { Container, Menu, Label } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';

export default ({ results }) => (
  <Menu tabular>
    <Menu.Item
      exact
      name="Submit Result"
      to={`/my-result-submission`}
      as={NavLink}
    />
    <Menu.Item exact to={`/my-result-submission/past`} as={NavLink}>
      Past Submissions<Label>{results?.length || 0}</Label>
    </Menu.Item>
  </Menu>
);
