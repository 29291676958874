import React from 'react';
import { Form, Dropdown } from 'semantic-ui-react';
import { geocodeByAddress } from 'react-google-places-autocomplete';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

export default class GeoAddress extends React.Component {
  constructor(props) {
    super(props);
    this.autocompleteService = new window.google.maps.places.AutocompleteService();
    this.geocoderService = new window.google.maps.Geocoder();
    this.state = {
      loading: false,
      error: false,
      placeOptions: [],
      geoLocation: props.geoLocation,
    };
  }
  onSearchChange(searchQuery) {
    if (searchQuery.length) {
      this.autocompleteService.getPlacePredictions(
        { input: searchQuery },
        (placeOptions) => {
          this.setState({ placeOptions });
        }
      );
    }
  }
  onChange(value) {
    let { geoLocation } = this.state;
    this.geocoderService.geocode({ address: value }, (results, status) => {
      if (status === window.google.maps.GeocoderStatus.OK) {
        if (results[0] && results[0].geometry) {
          const { location } = results[0].geometry;
          geoLocation = {
            type: 'point',
            coordinates: [location.lng(), location.lat()],
          };
        }
      }
      this.props.onChange({
        address: value,
        geoLocation,
      });
    });
  }
  render() {
    const { placeOptions, error } = this.state;
    const { label = 'Address', required = false, value } = this.props;

    const options = placeOptions
      ? placeOptions.map((place) => {
          return {
            value: place.description,
            text: place.description,
            key: place.description,
          };
        })
      : [];

    if (!options.map((o) => o.value).includes(value)) {
      options.push({
        value: value,
        text: value,
        key: value,
      });
    }

    return (
      <Form.Field required={required} error={error.message}>
        <label>{label}</label>
        <Dropdown
          fluid
          clearable
          selection
          search
          name="type"
          value={value}
          allowAdditions
          options={options}
          onChange={(e, { value }) => this.onChange(value)}
          onSearchChange={(e, { searchQuery }) =>
            this.onSearchChange(searchQuery)
          }
        />
      </Form.Field>
    );
  }
}
