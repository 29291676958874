import React from 'react';
import { Container, Menu } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';

export default ({ itemId }) => (
  <Container>
    <Menu tabular>
      <Menu.Item
        exact
        name="Overview"
        to={`/billing-accounts/${itemId}`}
        as={NavLink}
      />
      <Menu.Item
        exact
        name="Orders"
        to={`/billing-accounts/${itemId}/orders`}
        as={NavLink}
      />
    </Menu>
  </Container>
);
