import React from 'react';
import { DateTime } from 'luxon';
import { Header, Table, Divider, Label, Button } from 'semantic-ui-react';
import { urlForUpload } from 'utils/uploads';
import config from 'config';

function formatTestType(result) {
  const items = [];
  if (result.testType) {
    items.push(result.testType);
  }
  if (result.sampleType) {
    items.push(result.sampleType);
  }
  return items.join(', ');
}

function formatResult(result) {
  const props = {
    content: result.toUpperCase(),
  };
  if (result === 'negative') {
    props.color = 'olive';
  }
  if (result === 'positive') {
    props.color = 'red';
  }
  return <Label {...props} />;
}

function urlForGenerateResult(result) {
  return `${config.API_URL}/1/results/download-pdf?resultId=${result.id}&token=${result.token}`;
}

export default class Overview extends React.Component {
  render() {
    const { results } = this.props;
    return (
      <div>
        <Header as="h2">Your Results</Header>
        {results.map((result) => {
          return (
            <>
              <Table definition>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Patient Name</Table.Cell>
                    <Table.Cell>
                      {result.patient.lastName}, {result.patient.firstName}
                    </Table.Cell>
                  </Table.Row>
                  {result.testedAt && (
                    <Table.Row>
                      <Table.Cell>Testing Date/Time</Table.Cell>
                      <Table.Cell>
                        {DateTime.fromISO(result.testedAt, {
                          zone: 'America/New_York',
                        }).toLocaleString(DateTime.DATETIME_FULL)}
                      </Table.Cell>
                    </Table.Row>
                  )}
                  {result.resultsReceivedAt && (
                    <Table.Row>
                      <Table.Cell>Results Received Date/Time</Table.Cell>
                      <Table.Cell>
                        {DateTime.fromISO(result.resultsReceivedAt, {
                          zone: 'America/New_York',
                        }).toLocaleString(DateTime.DATETIME_FULL)}
                      </Table.Cell>
                    </Table.Row>
                  )}
                  <Table.Row>
                    <Table.Cell>Test Type</Table.Cell>
                    <Table.Cell>{formatTestType(result)}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Result</Table.Cell>
                    <Table.Cell>{formatResult(result.result)}</Table.Cell>
                  </Table.Row>
                </Table.Body>
                {result.upload ? (
                  <Table.Footer fullWidth>
                    <Table.Row>
                      <Table.HeaderCell colSpan="2">
                        <Button
                          primary
                          fluid
                          as="a"
                          target="_blank"
                          href={urlForUpload(result.upload)}>
                          Download PDF
                        </Button>
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Footer>
                ) : (
                  <Table.Footer fullWidth>
                    <Table.Row>
                      <Table.HeaderCell colSpan="2">
                        <Button
                          primary
                          fluid
                          as="a"
                          target="_blank"
                          href={urlForGenerateResult(result)}>
                          Download PDF
                        </Button>
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Footer>
                )}
              </Table>
              <Divider hidden />
            </>
          );
        })}
      </div>
    );
  }
}
