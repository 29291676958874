import React from 'react';
import { Form, Message, Modal, Button } from 'semantic-ui-react';
import request from 'utils/request';

function resetFormValues(appointment) {
  return {
    email: appointment.patients.length ? appointment.patients[0].email : '',
  };
}

export default class SendResult extends React.Component {
  static defaultProps = {
    loading: false,
    error: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      formValues: resetFormValues(props.appointment),
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.appointment !== prevProps.appointment) {
      this.setState({
        touched: false,
        formValues: resetFormValues(this.props.appointment),
      });
    }
  }

  handleSubmit = async () => {
    const { appointment } = this.props;
    const { formValues } = this.state;
    this.setState({ loading: true, error: null, touched: true });
    try {
      const body = formValues;
      await request({
        method: 'POST',
        path: `/1/appointments/${appointment.id}/emails/payment-link`,
        body,
      });
      this.setState({
        open: false,
        formValues: {},
        touched: false,
      });
      if (this.props.onSave) {
        this.props.onSave();
      }
    } catch (error) {
      this.setState({ loading: false, error });
    }
  };

  setField(name, value) {
    this.setState({
      formValues: {
        ...this.state.formValues,
        [name]: value,
      },
    });
  }

  render() {
    const { trigger, appointment } = this.props;
    const { open, loading, error, formValues } = this.state;
    return (
      <Modal
        closeOnDimmerClick={false}
        closeIcon
        onClose={() => {
          this.setState({
            open: false,
            formValues: {
              ...appointment,
            },
            touched: false,
          });
          if (this.props.onSave) {
            this.props.onSave();
          }
        }}
        onOpen={() => this.setState({ open: true })}
        open={open}
        trigger={trigger}>
        <Modal.Header>Send Payment Link</Modal.Header>
        <Modal.Content>
          {error && <Message error content={error.message} />}
          <Form onSubmit={() => this.handleSubmit()}>
            <p>
              This person will receive a link for payment and can manage this
              booking:
            </p>
            <Form.Input
              value={formValues.email}
              name="email"
              label="Email"
              type="text"
              onChange={(e, { name, value }) => this.setField(name, value)}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            basic
            content="Cancel"
            onClick={() => {
              this.setState({
                open: false,
                formValues: {
                  ...appointment,
                },
                touched: false,
              });
              if (this.props.onSave) {
                this.props.onSave();
              }
            }}
          />
          <Button
            loading={loading}
            primary
            content={'Send'}
            onClick={() => this.handleSubmit()}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}
