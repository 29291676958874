import React from 'react';
import { DateTime } from 'luxon';
import {
  Header,
  Table,
  Divider,
  Label,
  Button,
  Form,
  Message,
  Loader,
} from 'semantic-ui-react';
import { urlForUpload } from 'utils/uploads';
import config from 'config';
import AutoFocus from 'components/AutoFocus';
import request from 'utils/request';
import UploadsField from 'components/form-fields/Uploads';
import DateTimeField from 'components/form-fields/DateTime';
import {
  resultStatusOptions,
  resultSendStatusOptions,
  testResultTypeOptions,
} from 'utils/results';

const defaultResult = () => {
  return {
    resultsReceivedAt: new Date(),
  };
};

export default class Overview extends React.Component {
  state = {
    error: null,
    loading: false,
    result: defaultResult(),
  };

  onSubmit = async () => {
    try {
      const { result } = this.state;
      this.setState({
        error: null,
        loading: true,
      });
      await request({
        method: 'POST',
        path: '/1/results/submissions',
        body: {
          token: localStorage.getItem('resultSubmissionToken'),
          ...result,
        },
      });
      this.setState({
        loading: false,
        result: defaultResult(),
      });
      this.props.onRefresh();
      this.props.history.push('/my-result-submission/past?submitted=true');
      window.scrollTo(0, 0);
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  setField(name, value) {
    this.setState({
      result: {
        ...this.state.result,
        [name]: value,
      },
    });
  }

  render() {
    const { results } = this.props;
    const { result, error, loading } = this.state;
    return (
      <div>
        <Header as="h2">Submit Test Result</Header>
        <Form
          id="submit-result"
          error={Boolean(error)}
          onSubmit={this.onSubmit}>
          {loading && <Loader active />}
          {error && <Message error content={error.message} />}

          <UploadsField
            uploadsPath="/1/uploads/anonymous"
            type="image"
            label="Image Upload"
            value={result.upload || null}
            onChange={({ value }) => this.setField('upload', value)}
          />

          <Form.Dropdown
            selection
            label="Result"
            name="result"
            placeholder="Select"
            value={result.result}
            options={resultStatusOptions}
            onChange={(e, { name, value }) => this.setField(name, value)}
          />

          <DateTimeField
            name="resultsReceivedAt"
            label="Result Date"
            includeTime={false}
            value={
              result.resultsReceivedAt
                ? new Date(result.resultsReceivedAt)
                : null
            }
            onChange={(value, name) => this.setField(name, value)}
          />

          <Form.TextArea
            name="comment"
            label="Comments"
            value={result.comment || ''}
            onChange={(evt, { name, value }) => this.setField(name, value)}
          />

          {error && <Message error content={error.message} />}

          <Button
            primary
            fluid
            form="submit-result"
            loading={loading}
            content="Submit"
          />
        </Form>
      </div>
    );
  }
}
