import React from 'react';
import { Container, Menu, Label } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';

export default ({ itemId, numPatients }) => (
  <Container>
    <Menu tabular>
      <Menu.Item
        exact
        name="Overview"
        to={`/appointments/${itemId}`}
        as={NavLink}
      />
      <Menu.Item
        exact
        name="Patients"
        to={`/appointments/${itemId}/patients`}
        as={NavLink}>
        Patients
        <Label color="blue">{numPatients}</Label>
      </Menu.Item>
      <Menu.Item
        exact
        name="Lab Orders"
        to={`/appointments/${itemId}/lab-orders`}
        as={NavLink}
      />
    </Menu>
  </Container>
);
