import { createOptions } from 'utils/form';

export const patientSexes = {
  male: {
    name: 'Male',
    icon: 'gender male',
  },
  female: {
    name: 'Female',
    icon: 'gender female',
  },
};

export const patientSexOptions = createOptions(patientSexes);
