import React from 'react';
import { observer, inject } from 'mobx-react';
import Weekdays from 'components/form-fields/Weekdays';
import { Form, Label, Message, Button, Modal } from 'semantic-ui-react';

import AutoFocus from 'components/AutoFocus';
import { typeOptions } from 'utils/organizations';
import { testResultTypeOptions } from 'utils/results';

@inject('organizations')
@observer
export default class EditOrganization extends React.Component {
  static defaultProps = {
    initialValues: {},
  };

  state = {
    open: false,
    formValues: { ...this.props.initialValues },
  };

  componentDidUpdate(prevProps) {
    if (this.props.initialValues !== prevProps.initialValues) {
      this.setState({
        touched: false,
        formValues: { ...this.props.initialValues },
      });
    }
  }

  handleOnClose = () => {
    this.setState({
      touched: false,
      formValues: { ...this.props.initialValues },
      open: false,
    });
  };

  handleSubmit = () => {
    const { organizations, initialValues } = this.props;
    this.setState({
      touched: true,
    });

    const action = initialValues.id
      ? organizations.update.bind(organizations)
      : organizations.create.bind(organizations);

    return action(this.state.formValues).then((err) => {
      if (err instanceof Error) return;
      this.setState({
        formValues: this.props.initialValues,
        open: false,
      });
    });
  };

  setField(name, value) {
    this.setState({
      touched: false,
      formValues: {
        ...this.state.formValues,
        [name]: value,
      },
    });
  }

  render() {
    const { organizations, initialValues, trigger } = this.props;
    const { formValues = {}, touched, open } = this.state;

    const isUpdate = !!initialValues.id;
    const status = isUpdate
      ? organizations.getStatus('update')
      : organizations.getStatus('create');

    return (
      <Modal
        closeOnDimmerClick={false}
        closeIcon
        trigger={trigger}
        onClose={this.handleOnClose}
        onOpen={() => this.setState({ open: true })}
        open={open}>
        <Modal.Header>
          {isUpdate
            ? `Edit Organization "${initialValues.name}"`
            : 'New Organization'}
        </Modal.Header>
        <Modal.Content>
          <AutoFocus>
            <Form
              error={touched && Boolean(status.error)}
              onSubmit={() => this.handleSubmit()}>
              {status.error && <Message error content={status.error.message} />}
              <Form.Select
                name="type"
                required
                options={typeOptions}
                label="Type"
                onChange={(e, { value }) => this.setField('type', value)}
                value={formValues.type}
              />
              <Form.Input
                error={touched && !(formValues.name || '').length}
                required
                name="name"
                label="Name"
                type="text"
                value={formValues.name || ''}
                onChange={(e, { name, value }) => this.setField(name, value)}
              />
              <Form.Input
                error={touched && !(formValues.slug || '').length}
                required
                name="slug"
                label="Slug (lowercase, no spaces)"
                type="text"
                value={formValues.slug || ''}
                onChange={(e, { name, value }) => this.setField(name, value)}
              />
              <Form.Input
                error={touched && !(formValues.contactEmail || '').length}
                name="contactEmail"
                label="Contact Email"
                type="text"
                value={formValues.contactEmail || ''}
                onChange={(e, { name, value }) => this.setField(name, value)}
              />
              <Form.Input
                error={touched && !(formValues.contactPhoneNo || '').length}
                name="contactPhoneNo"
                label="Contact Phone No"
                type="text"
                value={formValues.contactPhoneNo || ''}
                onChange={(e, { name, value }) => this.setField(name, value)}
              />
              <Form.Input
                error={touched && !(formValues.contactName || '').length}
                name="contactName"
                label="Contact Name"
                type="text"
                value={formValues.contactName || ''}
                onChange={(e, { name, value }) => this.setField(name, value)}
              />
              <Form.Checkbox
                toggle
                label="Appointments are booked without scheduling (ad-hoc)"
                name="appointmentsWithoutScheduling"
                checked={formValues.appointmentsWithoutScheduling}
                onChange={(e, { name, checked }) =>
                  this.setField(name, checked)
                }
              />
              <Form.Checkbox
                toggle
                label="Appointments require approval by admin"
                name="appointmentsRequireApproval"
                checked={formValues.appointmentsRequireApproval}
                onChange={(e, { name, checked }) =>
                  this.setField(name, checked)
                }
              />

              <Form.Checkbox
                toggle
                label="Appointments require Patient address"
                name="appointmentsNeedAddress"
                checked={formValues.appointmentsNeedAddress}
                onChange={(e, { name, checked }) =>
                  this.setField(name, checked)
                }
              />
              <Form.Checkbox
                toggle
                label="Appointments require Patient date of birth"
                name="appointmentsNeedDob"
                checked={formValues.appointmentsNeedDob}
                onChange={(e, { name, checked }) =>
                  this.setField(name, checked)
                }
              />
              <Form.Checkbox
                toggle
                label="Appointments require Patient sex"
                name="appointmentsNeedSex"
                checked={formValues.appointmentsNeedSex}
                onChange={(e, { name, checked }) =>
                  this.setField(name, checked)
                }
              />
              <Form.Checkbox
                toggle
                label="Appointments require Patient insurance information"
                name="appointmentsNeedInsuranceInformation"
                checked={formValues.appointmentsNeedInsuranceInformation}
                onChange={(e, { name, checked }) =>
                  this.setField(name, checked)
                }
              />
              <Form.Checkbox
                toggle
                label="Enable Temp Measurements Quick Entry"
                name="enableMeasurementsQuickEntry"
                checked={formValues.enableMeasurementsQuickEntry}
                onChange={(e, { name, checked }) =>
                  this.setField(name, checked)
                }
              />
              <Form.Checkbox
                toggle
                label="Enable Test Results Quick Entry&trade;"
                name="enableResultsQuickEntry"
                checked={formValues.enableResultsQuickEntry}
                onChange={(e, { name, checked }) =>
                  this.setField(name, checked)
                }
              />

              {formValues.enableResultsQuickEntry && (
                <Form.Checkbox
                  toggle
                  label="Automatically send e-mail on Test Result Quick Entry"
                  name="sendResultOnQuickEntry"
                  checked={formValues.sendResultOnQuickEntry}
                  onChange={(e, { name, checked }) =>
                    this.setField(name, checked)
                  }
                />
              )}

              {formValues.enableResultsQuickEntry && (
                <Form.Select
                  name="defaultTestResultType"
                  required
                  options={testResultTypeOptions}
                  value={formValues.defaultTestResultType}
                  label="Default Test Result Type"
                  onChange={(e, { name, value }) => this.setField(name, value)}
                />
              )}

              <Form.Checkbox
                toggle
                label="Enable Promo Code"
                name="enablePromoCode"
                checked={formValues.enablePromoCode}
                onChange={(e, { name, checked }) =>
                  this.setField(name, checked)
                }
              />

              <Form.Checkbox
                toggle
                label="Enable payments (experimental)"
                name="enablePayments"
                checked={formValues.enablePayments}
                onChange={(e, { name, checked }) =>
                  this.setField(name, checked)
                }
              />

              <Form.Checkbox
                toggle
                label="Enable SMS for patients (will make phone no required)"
                name="enableSms"
                checked={formValues.enableSms}
                onChange={(e, { name, checked }) =>
                  this.setField(name, checked)
                }
              />

              <Form.Field>
                <a
                  style={{ float: 'right' }}
                  href="https://www.markdownguide.org/basic-syntax/"
                  target="_blank"
                  rel="noopener noreferrer">
                  Formatting
                </a>
                <label>Booking Notice</label>
                <Form.TextArea
                  name="bookingNotice"
                  rows={8}
                  value={formValues.bookingNotice || ''}
                  onChange={(e, { name, value }) => this.setField(name, value)}
                />
              </Form.Field>
              <Form.Field>
                <a
                  style={{ float: 'right' }}
                  href="https://www.markdownguide.org/basic-syntax/"
                  target="_blank"
                  rel="noopener noreferrer">
                  Formatting
                </a>
                <label>Booking Notice in Booking Confirmation E-Mail</label>
                <Form.TextArea
                  name="bookingEmailNotice"
                  rows={8}
                  value={formValues.bookingEmailNotice || ''}
                  onChange={(e, { name, value }) => this.setField(name, value)}
                />
              </Form.Field>
              <Form.Field>
                <a
                  style={{ float: 'right' }}
                  href="https://www.markdownguide.org/basic-syntax/"
                  target="_blank"
                  rel="noopener noreferrer">
                  Formatting
                </a>
                <label>Booking Consent Text</label>
                <Form.TextArea
                  name="bookingConsentText"
                  rows={8}
                  value={formValues.bookingConsentText || ''}
                  onChange={(e, { name, value }) => this.setField(name, value)}
                />
              </Form.Field>
              {formValues.appointmentsRequireApproval && (
                <Form.Field>
                  <a
                    style={{ float: 'right' }}
                    href="https://www.markdownguide.org/basic-syntax/"
                    target="_blank"
                    rel="noopener noreferrer">
                    Formatting
                  </a>
                  <label>Booking Request Confirmation Email</label>
                  <Form.TextArea
                    name="bookingRequestConfirmationEmail"
                    rows={8}
                    value={formValues.bookingRequestConfirmationEmail || ''}
                    onChange={(e, { name, value }) =>
                      this.setField(name, value)
                    }
                  />
                </Form.Field>
              )}
            </Form>
          </AutoFocus>
        </Modal.Content>
        <Modal.Actions>
          <Button
            loading={status.request === true}
            primary
            content={isUpdate ? 'Update' : 'Create'}
            onClick={this.handleSubmit}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}
