import React from 'react';
import { Header, Icon, Grid, Message } from 'semantic-ui-react';
import request from '../../utils/request';
import StripeForm from 'components/StripeForm';
import OrderSummary from 'components/OrderSummary';

function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return undefined;
  if (!results[2]) return undefined;
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function orderIsPaid(order) {
  return order.status === 'paid';
}

export default class Overview extends React.Component {
  state = {
    processing: getParameterByName('processing'),
    cancel: getParameterByName('cancel'),
    order: this.props.appointment.order,
  };
  componentDidMount() {
    const { processing } = this.state;
    if (processing) {
      this.startChecking();
    }
  }
  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
  startChecking() {
    this.interval = setInterval(() => this.fetch(), 4000);
  }
  fetch() {
    request({
      method: 'POST',
      path: '/1/appointments/fetch',
      body: {
        token: localStorage.getItem('appointmentToken'),
      },
    }).then(({ data }) => {
      this.setState({
        order: data.order,
      });
      if (orderIsPaid(data.order)) {
        clearInterval(this.interval);
      }
    });
  }
  renderPayment() {
    const { processing, order } = this.state;
    const isPaid = orderIsPaid(order);
    return isPaid ? (
      <Message success header="Thank you for your payment!" icon="checkmark" />
    ) : processing ? (
      <Message icon>
        <Icon name="circle notched" loading />
        <Message.Content>
          <Message.Header>Processing your payment</Message.Header>
          This can take a few seconds
        </Message.Content>
      </Message>
    ) : (
      <StripeForm
        getPaymentIntent={() =>
          request({
            method: 'POST',
            path: '/1/appointments/payment-intent',
            body: {
              token: localStorage.getItem('appointmentToken'),
            },
          })
        }
        onSuccess={() => {
          this.setState({ processing: true });
          this.startChecking();
        }}
      />
    );
  }
  render() {
    const { order } = this.state;
    return (
      <div>
        <Header as="h2">Payment</Header>
        <p>Here's a summary of your OpenClear order:</p>
        <Grid>
          <Grid.Row>
            <Grid.Column width={8}>
              <OrderSummary order={order} />
              {order.providerPaymentAccount ? (
                this.renderPayment()
              ) : (
                <Message content="Online payments are not available right now. Please contact your concierge" />
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
