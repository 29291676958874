import React from 'react';
import { Label } from 'semantic-ui-react';
import { statuses, conciergeStatuses } from './appointments';
import { orderStatuses } from './orders';
import { labOrderStatuses } from './lab-orders';
import { resultStatuses } from './results';

function round(value, decimals) {
  return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
}

export const truncate = (text, limit = 100) => {
  if (text.length > limit - 3) {
    return text.slice(0, limit - 3) + '...';
  }
  return text;
};

export function formatTime(hour, minute) {
  const meridian = hour > 11 ? 'pm' : 'am';
  const hour12Str = hour % 12 || 12;
  const minuteStr = `${minute}`.padStart(2, '0');
  return `${hour12Str}:${minuteStr}${meridian}`;
}

export function formatDateStr(dateObj) {
  if (typeof dateObj === 'string') {
    dateObj = new Date(dateObj);
  }
  const monthStr = `${dateObj.getMonth() + 1}`.padStart(2, '0');
  const dayStr = dateObj
    .getDate()
    .toString()
    .padStart(2, '0');
  return `${dateObj.getFullYear()}-${monthStr}-${dayStr}`;
}

export const numberWithCommas = (x) => {
  return (x || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatUsd = (value) => {
  if (isNaN(value)) return '';
  const usd = Math.round(value / 100);
  if (usd < 0) {
    return `($${numberWithCommas(0 - usd)})`;
  }
  return `$${numberWithCommas(usd)}`;
};

export function formatAppointmentStatus(item) {
  const status = statuses[item.status];
  const props = {
    content: status.name,
    icon: status.icon,
    color: status.color,
  };
  if (item.status === 'scheduled' && !item.confirmationSentAt) {
    props.content = 'Send Mail';
    props.color = 'yellow';
    props.icon = 'mail';
  }
  return <Label {...props} />;
}

export function formatConciergeStatus(item, includeConciergeUser = false) {
  const status = conciergeStatuses[item.conciergeStatus];
  const props = {
    content: status.name,
    icon: status.icon,
  };
  if (status.color) {
    props.color = status.color;
  }
  return (
    <>
      <Label {...props} />
      {includeConciergeUser &&
        (item.conciergeUser ? (
          <p style={{ fontSize: '12px' }}>by {item.conciergeUser.name}</p>
        ) : (
          <p></p>
        ))}
    </>
  );
}

export function formatDispatchingStatus(item, includeClinicianUser = false) {
  if (!item.clinicianUser) {
    return <Label content="Unassigned" icon="wait" />;
  }
  const props = {
    content: 'Assigned',
    icon: 'checkmark',
    color: 'olive',
  };
  return (
    <>
      <Label {...props} />
      {includeClinicianUser &&
        (item.clinicianUser ? (
          <p style={{ fontSize: '12px' }}>to {item.clinicianUser.name}</p>
        ) : (
          <p></p>
        ))}
    </>
  );
}

export function formatOption(types, key) {
  const status = types[key];
  if (!status) {
    return '-';
  }
  const props = {
    content: status.name,
    icon: status.icon,
  };
  if (status.color) {
    props.color = status.color;
  }
  return <Label {...props} />;
}

export function formatOrderStatus(order) {
  if (!order || !order.status) {
    return '-';
  }
  const status = orderStatuses[order.status];
  const props = {
    content: status.name,
    icon: status.icon,
  };
  if (status.color) {
    props.color = status.color;
  }
  return <Label {...props} />;
}

export function formatLabOrderStatus(order) {
  const status = labOrderStatuses[order.status];
  const props = {
    content: status.name,
    icon: status.icon,
  };
  if (status.color) {
    props.color = status.color;
  }
  return <Label {...props} />;
}

export function formatResult(result) {
  const status = resultStatuses[result];
  const props = {
    content: status.name,
    icon: status.icon,
  };
  if (status.color) {
    props.color = status.color;
  }
  return <Label {...props} />;
}
