import React from 'react';
import { Form, Dropdown } from 'semantic-ui-react';
import { patientTypesByOrganization } from 'utils/organizations';

export default class PatientTypeField extends React.Component {
  render() {
    const { organization } = this.props;
    return (
      <Form.Field>
        <label>Booking Type</label>
        <Dropdown
          fluid
          selection
          name="type"
          value={this.props.value}
          options={patientTypesByOrganization[organization.type] || []}
          onChange={this.props.onChange}
        />
      </Form.Field>
    );
  }
}
