import React from 'react';
import { Form, Input, Button, Message } from 'semantic-ui-react';
import AutoFocus from 'components/AutoFocus';

export default (props) => {
  const { loading, error } = props;
  const [email, setEmail] = React.useState('');
  const [touched, setTouched] = React.useState(false);

  return (
    <AutoFocus>
      {error && <Message error content={error.message} />}
      <Form
        error={touched}
        size="large"
        onSubmit={() => {
          setTouched(true);

          props.onSubmit({
            email,
          });
        }}>
        <p>
          We can send a new login link to your email address which will allow
          you to manage your result.
        </p>

        <Form.Field error={touched && !email.length}>
          <Input
            value={email}
            onChange={(e, { value }) => setEmail(value)}
            name="email"
            icon="mail"
            iconPosition="left"
            placeholder="E-mail Address"
            type="email"
            autoComplete="email"
          />
        </Form.Field>

        <Button
          fluid
          primary
          size="large"
          content="Send Magic Link"
          loading={loading}
        />
      </Form>
    </AutoFocus>
  );
};
