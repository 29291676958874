import React from 'react';
import { Table, Button, Header } from 'semantic-ui-react';
import Map from 'components/Map';
import { Link } from 'react-router-dom';
import { directionsUrl } from 'utils/maps';
import {
  formatAppointmentStatus,
  formatConciergeStatus,
  formatOrderStatus,
} from 'utils/formatting';
import { formatDispatchingStatus } from '../../utils/formatting';

export default class Overview extends React.Component {
  render() {
    const { appointment } = this.props;
    const mainPatient = appointment.patients.length
      ? appointment.patients[0]
      : undefined;
    return (
      <>
        <Header as="h3" content="Status" />
        <Table definition collapsing>
          <Table.Body>
            {appointment.conciergeStatus && (
              <Table.Row>
                <Table.Cell>Concierge Status</Table.Cell>
                <Table.Cell>{formatConciergeStatus(appointment)}</Table.Cell>
              </Table.Row>
            )}
            <Table.Row>
              <Table.Cell>Appointment Status</Table.Cell>
              <Table.Cell>{formatAppointmentStatus(appointment)}</Table.Cell>
            </Table.Row>
            {appointment.order && (
              <Table.Row>
                <Table.Cell>Order Status</Table.Cell>
                <Table.Cell>{formatOrderStatus(appointment.order)}</Table.Cell>
              </Table.Row>
            )}
            <Table.Row>
              <Table.Cell>Dispatching Status</Table.Cell>
              <Table.Cell>{formatDispatchingStatus(appointment)}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        {mainPatient && (
          <>
            <Header as="h3" content="Contact" />
            <Table definition collapsing>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>Email</Table.Cell>
                  <Table.Cell>{mainPatient.email}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Phone No.</Table.Cell>
                  <Table.Cell>{mainPatient.phoneNo}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </>
        )}

        {mainPatient && (
          <>
            <Header as="h3" content="Main Patient" />
            <Table definition>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>Patient Name</Table.Cell>
                  <Table.Cell>
                    <Link
                      to={`/patients/${mainPatient.id}`}
                      style={{
                        textDecoration:
                          appointment.status === 'cancelled'
                            ? 'line-through'
                            : 'normal',
                      }}>
                      {mainPatient.firstName} {mainPatient.lastName}
                    </Link>
                  </Table.Cell>
                </Table.Row>
                {mainPatient.address && (
                  <Table.Row>
                    <Table.Cell verticalAlign="top">Patient Address</Table.Cell>
                    <Table.Cell>
                      {mainPatient.address}
                      {mainPatient.geoLocation &&
                        mainPatient.geoLocation.coordinates.length > 0 && (
                          <>
                            <Map geoLocations={[mainPatient.geoLocation]} />

                            <Button
                              basic
                              icon="car"
                              content="Directions"
                              as="a"
                              rel="noopener noreferrer"
                              target="_blank"
                              href={directionsUrl(mainPatient.address)}
                            />
                          </>
                        )}
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </>
        )}
      </>
    );
  }
}
