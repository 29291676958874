import React from 'react';
import Pagination from 'components/Pagination';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import Protected from 'components/Protected';

import {
  Table,
  Loader,
  Segment,
  Dimmer,
  Message,
  Modal,
  Button,
} from 'semantic-ui-react';

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

export default class DataTable extends React.Component {
  render() {
    const { status, data: items, meta = {}, page } = this.props;
    return (
      <div className="list">
        {status.success && !items.length && (
          <Message>No measurements taken yet</Message>
        )}

        {status.success && items.length > 0 && (
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Date</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell>Temperature</Table.HeaderCell>
                <Table.HeaderCell width={3} textAlign="center">
                  Actions
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {items.map((item) => {
                return (
                  <Table.Row key={item.id}>
                    <Table.Cell>
                      {DateTime.fromJSDate(
                        new Date(item.createdAt)
                      ).toLocaleString(DateTime.DATETIME_MED)}
                    </Table.Cell>
                    <Table.Cell>{item.status}</Table.Cell>
                    <Table.Cell>
                      {item.temperature ? `${item.temperature}℉` : '-'}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <Protected endpoint="measurements" permission="write">
                        <Modal
                          header={`Are you sure you want to delete?`}
                          content="All data will be permanently deleted"
                          trigger={<Button basic icon="trash" />}
                          closeIcon
                          actions={[
                            {
                              key: 'delete',
                              primary: true,
                              content: 'Delete',
                              onClick: () => this.props.onDeleteItem(item),
                            },
                          ]}
                        />
                      </Protected>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        )}
        {status.success && meta.total > this.props.limit && (
          <Center>
            <Pagination
              limit={this.props.limit}
              page={page}
              total={meta.total}
              onPageChange={(e, { activePage }) => {
                this.props.setPage(activePage).then(() => {
                  window.scrollTo(0, 0);
                });
              }}
            />
          </Center>
        )}
        {status.request && (
          <Segment style={{ height: '100px' }}>
            <Dimmer active inverted>
              <Loader>Loading</Loader>
            </Dimmer>
          </Segment>
        )}
        {status.error && <Message error content={status.error.message} />}
      </div>
    );
  }
}
