import React from 'react';
import { observer, inject } from 'mobx-react';

import { Button, Form, TextArea, Modal, Message } from 'semantic-ui-react';
import { emailRegexp } from 'utils/validate';
import AutoFocus from 'components/AutoFocus';
import request from 'utils/request';

const defaultState = () => {
  return {
    open: false,
    validEmails: [],
    invalidEmails: [],
    touched: false,
    error: null,
  };
};

@inject('invites')
@observer
export default class InviteForm extends React.Component {
  state = {
    validEmails: [],
    invalidEmails: [],
    roles: null,
  };

  componentDidMount() {
    this.fetch();
  }

  handleTextAreaChange = (e, { value }) => {
    const values = value
      .toLowerCase()
      .split(/[\s,;\t\n]+/)
      .map((str) => str.trim())
      .filter(Boolean);

    const validEmails = [];
    const invalidEmails = [];
    values.forEach((text) => {
      if (text.match(emailRegexp)) {
        validEmails.push(text);
      } else {
        invalidEmails.push(text);
      }
    });
    this.setState({
      validEmails,
      invalidEmails,
    });
  };

  fetch() {
    request({
      method: 'GET',
      path: `/1/users/roles`,
    })
      .then(({ data }) => {
        const roles = Object.keys(data)
          .map((key) => {
            return {
              id: key,
              ...data[key],
            };
          })
          .filter((role) => role.allowScopes.includes('organization'));
        this.setState({ roles, loading: false });
      })
      .catch((error) => {
        this.setState({ error, loading: false });
      });
  }

  handleSubmit = () => {
    this.setState({
      touched: true,
    });

    return this.props.invites
      .create({ emails: this.state.validEmails, role: this.state.role })
      .then((error) => {
        if (error instanceof Error) {
          this.setState({ error });
          return;
        }
        this.setState(defaultState());
      });
  };

  render() {
    const { roles, validEmails, open, error } = this.state;
    const { invites, trigger, ...rest } = this.props;
    const status = invites.getStatus('create');

    return (
      <Modal
        closeIcon
        onClose={() => this.setState(defaultState())}
        onOpen={() => this.setState({ open: true })}
        open={open}
        trigger={trigger}
        {...rest}>
        <Modal.Header>Invite User</Modal.Header>
        <Modal.Content>
          {error && <Message error content={error.message} />}
          <AutoFocus>
            <Form onSubmit={this.handleSubmit} className={this.props.className}>
              {roles && (
                <Form.Dropdown
                  required
                  selection
                  name="role"
                  label="Role"
                  options={roles.map((role) => {
                    return {
                      key: role.id,
                      text: role.name,
                      value: role.id,
                    };
                  })}
                  onChange={(e, { value }) => {
                    this.setState({ role: value });
                  }}
                />
              )}

              <Form.Field>
                <label>Enter email address of the participant to invite</label>
                <TextArea
                  style={{ height: '150px' }}
                  name="emails"
                  onChange={this.handleTextAreaChange}
                  placeholder="Email address seperate by comma or newline .e.g first@gmail.com, second@gmail.com"
                />
              </Form.Field>
            </Form>
          </AutoFocus>
        </Modal.Content>
        <Modal.Actions>
          <Button basic onClick={this.props.onClose}>
            Close
          </Button>
          <Button
            onClick={this.handleSubmit}
            primary
            disabled={validEmails.length === 0}
            loading={status.request}
            type="submit">
            Invite Members {validEmails.length ? `(${validEmails.length})` : ''}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
