import { createOptions } from 'utils/form';

export const resultStatuses = {
  pending: {
    name: 'Pending',
    icon: 'wait',
  },
  positive: {
    name: 'Positive',
    icon: 'exclamation triangle',
    color: 'orange',
  },
  negative: {
    name: 'Negative',
    icon: 'checkmark',
    color: 'olive',
  },
  qns: {
    name: 'QNS',
    icon: 'tint',
  },
  inconclusive: {
    name: 'Inconclusive',
    icon: 'question circle',
  },
};

export const resultStatusOptions = createOptions(resultStatuses);

export const resultSendStatuses = {
  pending: {
    name: 'Pending',
    icon: 'wait',
  },
  sent: {
    name: 'Sent',
    icon: 'mail',
  },
};

export const resultSendStatusOptions = createOptions(resultSendStatuses);

export const testResultTypeOptions = [
  {
    text: 'RT-PCR',
    value: 'RT-PCR',
  },
  {
    text: 'Antibody',
    value: 'Antibody',
  },
  {
    text: 'Antigen',
    value: 'Antigen',
  },
  {
    text: 'Rapid Test',
    value: 'Rapid Test',
  },
];
