import React from 'react';
import { Button, Modal } from 'semantic-ui-react';
import Map from 'components/Map';
import { directionsUrl } from 'utils/maps';

export default class ViewLabInstructions extends React.Component {
  static defaultProps = {};

  state = {
    open: false,
  };

  render() {
    const { location, appointment, trigger } = this.props;
    const { open } = this.state;

    return (
      <Modal
        closeOnDimmerClick={false}
        closeIcon
        trigger={trigger}
        onClose={() => this.setState({ open: false })}
        onOpen={() => this.setState({ open: true })}
        open={open}>
        <Modal.Header>Lab Instructions</Modal.Header>
        <Modal.Content>
          <p>
            Step 1: Log in <a href="">here</a>
          </p>
          <p>Step 2: Create patient</p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            content="Close"
            onClick={() => this.setState({ open: false })}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}
