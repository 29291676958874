import React from 'react';
import request from 'utils/request';

import { Label, Message } from 'semantic-ui-react';

function colorForAvailability(numAvailable, limit, overrideName) {
  if (!!overrideName && numAvailable === 0) {
    return 'gray';
  }
  if (numAvailable <= 0) {
    return 'red';
  }
  if (numAvailable <= limit - 2) {
    return 'orange';
  }
  if (numAvailable <= limit - 1) {
    return 'yellow';
  }
  return 'olive';
}

export default class VisualizeDay extends React.Component {
  state = {
    data: null,
  };
  componentDidMount() {
    this.fetch();
  }
  fetch() {
    const { date, location } = this.props;
    request({
      method: 'POST',
      path: '/1/availability/check',
      body: {
        date,
        location,
        fullDay: true,
      },
    }).then(({ data }) => {
      this.setState({ data });
    });
  }
  render() {
    const { limit } = this.props;
    const { data } = this.state;
    if (!data) return <p />;
    const { slots } = data;
    if (!slots.length) {
      return <Message content="No slots available for this day" />;
    }
    return (
      <div>
        {slots.map(({ key, numAvailable, overrideName }) => {
          let title = `${numAvailable} slots available`;
          if (overrideName) {
            title = `${overrideName}, ${title}`;
          }
          return (
            <Label
              color={colorForAvailability(numAvailable, limit, overrideName)}
              title={title}
              content={key}
              style={{
                marginBottom: '0.3em',
                marginLeft: '0',
                marginRight: '0.3em',
              }}
            />
          );
        })}
      </div>
    );
  }
}
