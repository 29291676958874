import { createOptions } from 'utils/form';

export const orderStatuses = {
  pending: {
    name: 'Pending',
    icon: 'wait',
  },
  sent: {
    name: 'Sent',
    icon: 'paper plane',
  },
  outstanding: {
    name: 'Outstanding',
    icon: 'hourglass outline',
  },
  paid: {
    name: 'Paid',
    icon: 'beer',
    color: 'olive',
  },
  needsConsent: {
    name: 'Need Consent',
    icon: 'pencil',
  },
  delinquent: {
    name: 'Delinquent',
    icon: 'warning circle',
  },
  refunded: {
    name: 'Refunded',
    icon: 'money',
  },
};

export const orderStatusOptions = createOptions(orderStatuses);

export const customTypes = {
  travelFee: {
    text: 'Travel Fee',
    icon: 'suitcase',
  },
  discount: {
    text: 'Discount',
    icon: 'gift',
  },
};
