import React from 'react';
import { observer, inject } from 'mobx-react';
import request from 'utils/request';

import DataList from 'components/DataList';
import Table from './Table';
const itemLimit = 50;
import { Header } from 'semantic-ui-react';

@inject('me', 'appSession')
@observer
export default class Orders extends React.Component {
  state = {
    showCreateDialog: false,
    editItem: null,
    dateKey: Date.now(),
  };

  constructor(props) {
    super(props);
  }

  onRequest = (filters = {}) => {
    const { searchId } = this.state;
    return request({
      method: 'POST',
      path: '/1/orders/search',
      body: {
        billingAccount: this.props.billingAccount.id,
        ...filters,
        ...(searchId ? { searchId } : {}),
        ...this.state.filters,
        limit: itemLimit,
      },
    });
  };

  refresh = () => {
    this.setState({
      dataKey: Date.now(),
    });
  };

  updateItem(item, billingAccountPaid) {
    request({
      method: 'PATCH',
      path: `/1/orders/${item.id}`,
      body: {
        ...item,
        billingAccountPaid,
      },
    }).then(({ data }) => {
      this.refresh();
    });
  }

  render() {
    const { organization } = this.props.me;
    return (
      <>
        <Header as="h2">Orders</Header>
        <DataList
          key={this.state.dataKey}
          limit={itemLimit}
          sort={{
            field: 'createdAt',
            order: 'desc',
          }}
          onRequest={this.onRequest}>
          <Table
            onUpdateItem={(item, billingAccountPaid) =>
              this.updateItem(item, billingAccountPaid)
            }
          />
        </DataList>
      </>
    );
  }
}
