export const types = {
  educationalInstitution: {
    name: 'Educational Institution',
    icon: 'university',
  },
  conciergeService: {
    name: 'Concierge Service',
    icon: 'map marker alternate',
  },
  vaccinationCenter: {
    name: 'Vaccination Center',
    icon: 'syringe',
  },
  genericProvider: {
    name: 'Generic Provider',
    icon: 'user md',
  },
  summerCamp: {
    name: 'Summer Camp',
    icon: 'tree',
  },
  corporation: {
    name: 'Corporation',
    icon: 'industry',
  },
};

export const typeOptions = Object.keys(types).map((key) => {
  return {
    key,
    text: types[key].name,
    icon: types[key].icon,
    value: key,
  };
});

export function organizationHasFields(organization, fields) {
  if (!organization) return false;
  switch (fields) {
    case 'address':
      return (
        ['conciergeService', 'educationalInstitution', 'summerCamp'].includes(
          organization.type
        ) || organization.appointmentsNeedAddress
      );
    case 'patientType':
      return ['educationalInstitution', 'summerCamp'].includes(
        organization.type
      );
    case 'dob':
      return (
        ['educationalInstitution', 'summerCamp'].includes(organization.type) ||
        organization.appointmentsNeedDob
      );
    case 'grade':
      return ['educationalInstitution'].includes(organization.type);
    case 'sex':
      return (
        ['summerCamp'].includes(organization.type) ||
        organization.appointmentsNeedSex
      );
    case 'insuranceInformation':
      return (
        ['summerCamp'].includes(organization.type) ||
        organization.appointmentsNeedInsuranceInformation
      );
    case 'requestedDate':
      return (
        !organization.appointmentsWithoutScheduling &&
        !['summerCamp'].includes(organization.type)
      );
    default:
      return false;
  }
}

export const patientTypesByOrganization = {
  educationalInstitution: [
    {
      text: 'Book for Student',
      value: 'student',
      key: 'student',
    },
    {
      text: 'Book for Parent/Guardian',
      value: 'parent',
      key: 'parent',
    },
    {
      text: 'Book for Teacher/Staff',
      value: 'staff',
      key: 'staff',
    },
  ],
  summerCamp: [
    {
      text: 'Book for Camper',
      value: 'camper',
      key: 'camper',
    },
    {
      text: 'Book for Staff',
      value: 'staff',
      key: 'staff',
    },
  ],
};
