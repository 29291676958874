import React from 'react';
import { observer, inject } from 'mobx-react';
import AppWrapper from 'components/AppWrapper';
import { userHasRole } from 'utils/permissions';

@inject('me')
@observer
export default class Home extends React.Component {
  componentDidMount() {
    const { me } = this.props;
    if (
      userHasRole(me.user, 'attendant') &&
      !userHasRole(me.user, 'clinician') &&
      !userHasRole(me.user, 'superAdmin')
    ) {
      this.props.history.replace('/measure');
    } else {
      if (userHasRole(me.user, 'clinician')) {
        this.props.history.replace('/patients');
      } else {
        this.props.history.replace('/appointments');
      }
    }
  }
  render() {
    return (
      <AppWrapper>
        Hello {this.props.me.user.name} ({this.props.me.user.email}) from
        dashboard
      </AppWrapper>
    );
  }
}
