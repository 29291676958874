import { observable, action } from 'mobx';
import BaseStore from './BaseStore';
import request from 'utils/request';

export default class MeStore extends BaseStore {
  @observable user;

  determineOrganizationId() {
    let organizationId = localStorage.getItem('setOrganizationId');
    if (organizationId) return organizationId;
    const userOrganizationIds = this.user.roles
      .filter((role) => role.scope === 'organization')
      .map((role) => role.scopeRef);
    organizationId = userOrganizationIds[0];
    if (organizationId) {
      localStorage.setItem('setOrganizationId', organizationId);
      return organizationId;
    }
    localStorage.setItem('setOrganizationId', 'openclear');
    return 'openclear';
  }

  @action
  fetch(statusKey) {
    const status = this.createStatus(statusKey);
    return request({
      method: 'GET',
      path: '/1/users/me',
    })
      .then((resp) => {
        this.user = resp.data;
        status.success();
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }

  @action
  fetchOrganization(id, statusKey = 'fetchOrganization') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'GET',
      path: '/1/organizations/' + id,
    })
      .then((resp) => {
        this.organization = resp.data;
        status.success();
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }
}
