import React from 'react';
import { observer, inject } from 'mobx-react';
import { toJS } from 'mobx';

import { Form, Label, Message, Button, Modal } from 'semantic-ui-react';

import AutoFocus from 'components/AutoFocus';
import { paymentAccountTypes } from 'utils/payments';
import { createOptions } from 'utils/form';

function resetState(initialValues = {}) {
  return {
    open: false,
    formValues: {
      ...initialValues,
    },
  };
}

@inject('paymentAccounts')
@observer
export default class EditPaymentAccount extends React.Component {
  static defaultProps = {
    initialValues: {},
  };

  state = resetState(this.props.initialValues);

  componentDidUpdate(prevProps) {
    if (this.props.initialValues !== prevProps.initialValues) {
      const initialValues = toJS(this.props.initialValues);
      this.setState(resetState(initialValues));
    }
  }

  handleOnClose = () => {
    this.setState(resetState(this.props.initialValues));
  };

  handleSubmit = () => {
    const { paymentAccounts, initialValues } = this.props;
    this.setState({
      touched: true,
    });

    const action = initialValues.id
      ? paymentAccounts.update.bind(paymentAccounts)
      : paymentAccounts.create.bind(paymentAccounts);

    const body = {
      ...this.state.formValues,
    };

    return action({
      ...body,
    }).then((err) => {
      if (err instanceof Error) return;
      this.setState({
        ...resetState(this.props.initialValues),
        open: false,
      });
    });
  };

  setField(name, value) {
    this.setState({
      touched: false,
      formValues: {
        ...this.state.formValues,
        [name]: value,
      },
    });
  }

  render() {
    const { paymentAccounts, initialValues, trigger } = this.props;
    const { formValues = {}, touched, open } = this.state;

    const isUpdate = !!initialValues.id;
    const status = isUpdate
      ? paymentAccounts.getStatus('update')
      : paymentAccounts.getStatus('create');

    return (
      <Modal
        closeIcon
        trigger={trigger}
        onClose={this.handleOnClose}
        onOpen={() => this.setState({ open: true })}
        open={open}>
        <Modal.Header>
          {isUpdate
            ? `Edit Payment Account "${initialValues.name}"`
            : 'New Payment Account'}
        </Modal.Header>
        <Modal.Content>
          <AutoFocus>
            {status.error && <Message error content={status.error.message} />}
            <Form onSubmit={() => this.handleSubmit()}>
              <Form.Input
                required
                name="name"
                label="Name"
                type="text"
                value={formValues.name || ''}
                onChange={(e, { name, value }) => this.setField(name, value)}
              />

              <Form.Select
                name="type"
                clearable
                required
                options={createOptions(paymentAccountTypes)}
                label="Type"
                onChange={(e, { name, value }) => this.setField(name, value)}
                value={formValues.type}
              />
            </Form>
          </AutoFocus>
        </Modal.Content>
        <Modal.Actions>
          <Button
            loading={status.request === true}
            primary
            content={isUpdate ? 'Update' : 'Create'}
            onClick={this.handleSubmit}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}
