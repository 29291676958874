import React from 'react';
import AppWrapper from 'components/AppWrapper';
import { observer, inject } from 'mobx-react';

import config from 'config';
import DataList from 'components/DataList';
import Search from 'components/Search';

import request from 'utils/request';

import Filters from './Filters';
import Table from './Table';
import Protected from 'components/Protected';
import NewAppointment from 'components/modals/NewAppointment';
import ViewCalendarLink from 'components/modals/ViewCalendarLink';

import {
  Container,
  Header,
  Segment,
  Grid,
  Button,
  Divider,
} from 'semantic-ui-react';
import { userHasRole } from 'utils/permissions';

const itemLimit = 50;

@inject('me', 'appSession')
@observer
export default class Sessions extends React.Component {
  state = {
    dateKey: Date.now(),
  };

  onRequest = (filters = {}) => {
    const { searchId } = this.state;
    return request({
      method: 'POST',
      path: '/1/appointments/mine',
      body: {
        ...filters,
        ...(searchId ? { searchId } : {}),
        ...this.state.filters,
        limit: itemLimit,
      },
    });
  };

  onChange = (filters) => {
    this.setState(
      {
        filters: filters.reduce((result, item) => {
          return {
            ...result,
            ...(item.apiFilter || {}),
          };
        }, {}),
      },
      () => this.refresh()
    );
  };

  handleOnSearch = (search) => {
    this.setState(
      {
        searchId: search,
      },
      () => this.refresh()
    );
  };

  refresh = () => {
    this.setState({
      dataKey: Date.now(),
    });
  };

  render() {
    const { organization, user } = this.props.me;
    const defaultSort = userHasRole(user, 'concierge')
      ? {
          field: 'createdAt',
          order: 'desc',
        }
      : {
          field: 'requestedAt',
          order: 'asc',
        };
    return (
      <AppWrapper>
        <Container>
          <Header as="h2">
            My Appointments
            <ViewCalendarLink
              calendarUrl={`${config.API_URL}/1/appointments/mine/calendar/${user.calendarToken}`}
              trigger={
                <Button
                  basic
                  icon="calendar"
                  content="Subscribe to Calendar"
                  style={{ float: 'right' }}
                />
              }
            />
          </Header>
          <Segment>
            <Grid>
              <Grid.Row width={16}>
                <Grid.Column width={12}>
                  <Filters onChange={this.onChange}></Filters>
                </Grid.Column>
                <Grid.Column width={4}>
                  <Search
                    placeholder="Search by ID, Name, Email, Phone"
                    onChange={this.handleOnSearch}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>

          <DataList
            key={this.state.dataKey}
            limit={itemLimit}
            sort={defaultSort}
            live
            onRequest={this.onRequest}>
            <Table onUpdateItem={this.refresh} />
          </DataList>
        </Container>
      </AppWrapper>
    );
  }
}
