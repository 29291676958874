import React from 'react';
import { Container, Menu } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';

export default ({ organization }) => (
  <Container>
    <Menu tabular>
      <Menu.Item exact name="Overview" to={`/my-booking`} as={NavLink} />
      {organization && organization.enablePayments && (
        <Menu.Item exact name="Payment" to={`/my-booking/payment`} as={NavLink}>
          Payment
        </Menu.Item>
      )}
    </Menu>
  </Container>
);
