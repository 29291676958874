import React from 'react';
import { omit } from 'lodash';

export default class DataList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      page: 1,
      status: {
        request: true,
      },
      sort: {
        field: 'createdAt',
        order: 'asc',
        ...props.sort,
      },
    };
  }

  static defaultProps = {
    onRequest: () => {
      throw Error('DataList.onRequest needs to be implemented');
    },
    processData: (data) => data,
  };

  componentDidUpdate(prevProps, prevState) {
    let changed = Object.keys(this.getOptions()).some((key) => {
      return prevProps[key] !== this.props[key];
    });

    const { sort } = this.state;
    if (sort !== prevState.sort) {
      changed = true;
    }

    if (changed) {
      this.fetchItems();
    }
  }

  getOptions() {
    return omit(this.props, ['children']);
  }

  componentDidMount() {
    this.fetchItems();
    if (this.props.live) {
      this.interval = setInterval(() => {
        this.fetchItemsUpdate();
      }, 5 * 1000);
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getSort = (field) => {
    const { sort } = this.state;
    if (field === sort?.field) {
      return sort.order === 'asc' ? 'ascending' : 'descending';
    }
  };

  setSort = (field) => {
    const { sort } = this.state;
    if (field === sort?.field && sort.order === 'asc') {
      this.setState({
        sort: {
          field,
          order: 'desc',
        },
      });
    } else {
      this.setState({
        sort: {
          field,
          order: 'asc',
        },
      });
    }
  };

  setPage = (page) => {
    return new Promise((resolve) => {
      this.setState(
        {
          page,
        },
        () => {
          resolve(this.fetchItems());
        }
      );
    });
  };

  fetchItems = () => {
    const limit = this.props.limit;
    const { sort } = this.state;
    this.setState({
      status: { request: true },
    });

    const promise = this.props.onRequest({
      limit,
      skip: (this.state.page - 1) * limit,
      sort,
    });

    if (!promise || !promise.then) {
      throw Error('DataList.onRequest needs to return a promise');
    }

    return promise.then(({ data, meta }) => {
      this.setState({
        data: this.props.processData(data),
        meta: meta,
        status: { success: true },
      });
      return { data, meta };
    });
  };

  fetchItemsUpdate = () => {
    const limit = this.props.limit;
    const { sort } = this.state;
    const promise = this.props.onRequest({
      limit,
      skip: (this.state.page - 1) * limit,
      sort,
    });

    if (!promise || !promise.then) {
      throw Error('DataList.onRequest needs to return a promise');
    }

    return promise.then(({ data, meta }) => {
      this.setState({
        data: this.props.processData(data),
        meta: meta,
        status: { success: true },
      });
      return { data, meta };
    });
  };

  render() {
    let { children } = this.props;
    if (typeof children === 'function') {
      return children({
        ...this.state,
        getSort: this.getSort,
        setSort: this.setSort,
        fetchItems: this.fetchItems,
        deleteItem: this.deleteItem,
        setPage: this.setPage,
        ...this.getOptions(),
      });
    } else {
      return React.Children.map(this.props.children, (child) => {
        return React.cloneElement(child, {
          ...this.state,
          getSort: this.getSort,
          setSort: this.setSort,
          fetchItems: this.fetchItems,
          deleteItem: this.deleteItem,
          setPage: this.setPage,
          ...this.getOptions(),
        });
      });
      return children;
    }
  }
}
