import React from 'react';
import { observer, inject } from 'mobx-react';
import request from 'utils/request';

import DataList from 'components/DataList';
import Table from './Table';
import EditLabOrder from 'components/modals/EditLabOrder';
import Protected from 'components/Protected';

const itemLimit = 50;
import { Container, Header, Segment, Grid, Button } from 'semantic-ui-react';

@inject('me', 'appSession')
@observer
export default class LabOrders extends React.Component {
  state = {
    showCreateDialog: false,
    editItem: null,
    dateKey: Date.now(),
  };

  constructor(props) {
    super(props);
    this.createOrEditDialog = React.createRef();
  }

  onPushToLab = async (item) => {
    try {
      await request({
        method: 'PUT',
        path: `/1/lab-orders/${item.id}/sync`,
        body: {
          destination: 'labq',
        },
      });
      this.refresh();
    } catch (error) {
      alert(`Error: ${error.message}`);
    }
  };

  onDeleteItem = (item) => {
    return request({
      method: 'DELETE',
      path: `/1/lab-orders/${item.id}`,
    }).then(() => this.refresh());
  };

  onRequest = (filters = {}) => {
    const { searchId } = this.state;
    return request({
      method: 'POST',
      path: '/1/lab-orders/search',
      body: {
        ...filters,
        ...(searchId ? { searchId } : {}),
        ...this.state.filters,
        limit: itemLimit,
        appointment: this.props.appointment.id,
      },
    });
  };

  handleOnSearch = (search) => {
    this.setState(
      {
        searchId: search,
      },
      () => this.refresh()
    );
  };

  refresh = () => {
    this.setState({
      dataKey: Date.now(),
    });
  };

  onChange = (filters) => {
    this.setState(
      {
        filters: filters.reduce((result, item) => {
          return {
            ...result,
            ...(item.apiFilter || {}),
          };
        }, {}),
      },
      () => this.refresh()
    );
  };

  render() {
    const { organization } = this.props.me;
    const { appointment } = this.props;
    return (
      <>
        <Header as="h2">
          Lab Orders
          <Protected endpoint="patients" permission="write">
            <EditLabOrder
              onSave={this.refresh}
              organization={organization}
              appointment={appointment}
              initialValues={{
                appointment: appointment.id,
              }}
              trigger={
                <Button
                  primary
                  floated="right"
                  style={{ marginTop: '-5px' }}
                  content="New Lab Order"
                  icon="plus"
                />
              }
            />
          </Protected>
        </Header>

        <DataList
          key={this.state.dataKey}
          limit={itemLimit}
          sort={{
            field: 'createdAt',
            order: 'desc',
          }}
          onRequest={this.onRequest}>
          <Table
            onUpdateItem={this.refresh}
            onDeleteItem={this.onDeleteItem}
            onPushToLab={this.onPushToLab}
            appointment={appointment}
          />
        </DataList>
      </>
    );
  }
}
