import React from 'react';
import './slide-message.less';

const TIMEOUT = 3000;

export default class  extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  componentDidMount() {
    this.update(this.props.children);
  }

  componentDidUpdate(lastProps) {
    const { children } = this.props;
    if (children !== lastProps.children) {
      this.update(children);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  update(children) {
    if (children) {
      this.setState({
        show: true,
      });
      this.timer = setTimeout(this.onDelay, TIMEOUT);
    }
  }

  onDelay = () => {
    this.setState({
      show: false,
    });
  }

  getClassNames() {
    const { show } = this.state;
    const classNames = ['slide-message']
    if (show) {
      classNames.push('slide-message--in');
    }
    return classNames.join(' ');
  }

  render() {
    return (
      <div className={this.getClassNames()}>
        <div className="slide-message__message">
          {this.props.children}
        </div>
      </div>
    );
  }

}
