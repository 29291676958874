import React from 'react';
import DayPicker from 'react-day-picker/DayPicker';
import 'react-day-picker/lib/style.css';
import { Form, Label, Input } from 'semantic-ui-react';

import './date-time.less';

const dateToState = (date) => {
  return {
    date,
    hours: date.getHours(),
    minutes: date.getMinutes(),
  };
};

const stateToDate = (state, includeTime = true) => {
  if (!includeTime) {
    return state.date;
  }
  const { date, hours, minutes } = state;
  const newDate = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    hours,
    minutes
  );
  return newDate;
};

export default class DateTime extends React.Component {
  constructor(props) {
    super(props);
    const date = props.value ? new Date(props.value) : new Date();
    this.state = dateToState(date);
  }

  componentDidUpdate(lastProps) {
    const { value } = this.props;
    if (value !== lastProps.value) {
      this.setState(dateToState(value));
    }
  }

  setDate(date) {
    const { hours, minutes } = this.state;
    const newState = {
      date,
      hours,
      minutes,
    };
    this.setState(newState);
    const dateString = stateToDate(
      newState,
      this.props.includeTime
    ).toISOString();
    this.props.onChange(dateString, this.props.name);
  }

  setHours(hoursStr) {
    const { date, minutes } = this.state;
    const newState = {
      date,
      hours: parseInt(hoursStr),
      minutes,
    };
    this.setState(newState);
    const dateString = stateToDate(newState).toISOString();
    this.props.onChange(dateString, this.props.name);
  }

  setMinutes(minutesStr) {
    const { date, hours } = this.state;
    const newState = {
      date,
      minutes: parseInt(minutesStr),
      hours,
    };
    this.setState(newState);
    const dateString = stateToDate(newState).toISOString();
    this.props.onChange(dateString, this.props.name);
  }

  render() {
    const {
      required,
      label,
      includeTime = true,
      dayPickerProps = {},
    } = this.props;
    const { date, hours, minutes } = this.state;

    return (
      <Form.Field required={required}>
        {label && <label>{label}</label>}

        <DayPicker
          style={{ outline: 0, backgroundColor: '#fafafa', width: '100%' }}
          selectedDays={date}
          {...dayPickerProps}
          onDayClick={(date) => this.setDate(date)}
        />

        {includeTime && (
          <div
            style={{
              textAlign: 'center',
              backgroundColor: '#f9f9f9',
              display: 'block',
              clear: 'both',
              height: '60px',
            }}>
            <div style={{ margin: '0 auto', width: '210px' }}>
              <Input
                type="text"
                labelPosition="right"
                style={{ width: '60px', float: 'left', marginRight: '50px' }}
                value={hours}
                onChange={(e, props) => this.setHours(props.value)}>
                <input />
                <Label basic>h</Label>
              </Input>
              <Input
                type="text"
                labelPosition="right"
                style={{ width: '60px', float: 'left' }}
                value={minutes}
                onChange={(e, props) => this.setMinutes(props.value)}>
                <input />
                <Label basic>m</Label>
              </Input>
            </div>
          </div>
        )}
      </Form.Field>
    );
  }
}
