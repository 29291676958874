import React from 'react';
import { Form, Message, Modal, Button, Radio } from 'semantic-ui-react';
import AutoFocus from 'components/AutoFocus';
import SearchDropdown from '../../components/SearchDropdown';
import DateYearField from 'components/form-fields/DateYear';
import request from 'utils/request';
import GeoAddressField from 'components/form-fields/GeoAddress';
import { organizationHasFields } from 'utils/organizations';

export default class AddPatientToAppointment extends React.Component {
  static defaultProps = {
    initialValues: {},
    loading: false,
    error: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      dobMonth: props.initialValues.dob
        ? new Date(props.initialValues.dob)
        : new Date(),
      newFormValues: { ...this.props.initialValues },
      existingFormValues: {},
      existingOrNew: 'new',
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.initialValues !== prevProps.initialValues) {
      this.setState({
        touched: false,
        newFormValues: { ...this.props.initialValues },
      });
    }
  }

  handleSubmit = async () => {
    const { appointment } = this.props;
    const { existingOrNew, newFormValues, existingFormValues } = this.state;
    this.setState({ loading: true, error: null, touched: true });
    let patientId = existingFormValues.patient;
    try {
      if (existingOrNew === 'new') {
        const { data } = await request({
          method: 'POST',
          path: '/1/patients',
          body: newFormValues,
        });
        patientId = data.id;
      }
      await request({
        method: 'POST',
        path: `/1/appointments/${appointment.id}/patients`,
        body: {
          patient: patientId,
        },
      });
      this.setState({
        loading: false,
        touched: false,
        newFormValues: this.props.initialValues,
        open: false,
      });
      if (this.props.onSave) {
        this.props.onSave();
      }
    } catch (error) {
      this.setState({ loading: false, error });
    }
  };

  setField(name, value) {
    this.setState({
      newFormValues: {
        ...this.state.newFormValues,
        [name]: value,
      },
    });
  }

  fetchPatients = (filter) => {
    return request({
      method: 'POST',
      path: '/1/patients/search',
      body: {
        ...filter,
      },
    });
  };

  handleOnConciergeUserChange = (e, { value }, selectedConciergeUser) => {
    this.setState({
      selectedConciergeUser,
      existingFormValues: {
        ...this.state.existingFormValues,
        patient: value,
      },
    });
  };

  render() {
    const { organization, patients, initialValues, trigger } = this.props;
    const {
      newFormValues = {},
      existingFormValues = {},
      touched,
      open,
      loading,
      error,
      existingOrNew,
    } = this.state;

    return (
      <Modal
        closeOnDimmerClick={false}
        closeIcon
        onClose={() =>
          this.setState({
            open: false,
            newFormValues: this.props.initialValues,
            touched: false,
          })
        }
        onOpen={() => this.setState({ open: true })}
        open={open}
        trigger={trigger}>
        <Modal.Header>Add Patient to Appointment</Modal.Header>
        <Modal.Content>
          <AutoFocus>
            {error && <Message error content={error.message} />}
            <Form onSubmit={() => this.handleSubmit()}>
              <Form.Field>
                <Radio
                  label="Lookup existing Patient"
                  name="existingOrNew"
                  value="existing"
                  checked={existingOrNew === 'existing'}
                  onChange={(e, { value }) =>
                    this.setState({ existingOrNew: value })
                  }
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label="Add new Patient"
                  name="existingOrNew"
                  value="new"
                  checked={existingOrNew === 'new'}
                  onChange={(e, { value }) =>
                    this.setState({ existingOrNew: value })
                  }
                />
              </Form.Field>

              {existingOrNew === 'existing' && (
                <>
                  <Form.Field>
                    <label>Search Patient</label>
                    <SearchDropdown
                      textField="searchId"
                      textFieldFn={(item) =>
                        `${item.firstName} ${item.lastName}`
                      }
                      onChange={this.handleOnConciergeUserChange}
                      value={existingFormValues.patient}
                      clearable
                      fetchData={this.fetchPatients}
                      fluid
                    />
                  </Form.Field>
                </>
              )}

              {existingOrNew === 'new' && (
                <>
                  <Form.Input
                    value={newFormValues.firstName || ''}
                    name="firstName"
                    label="First Name"
                    required
                    type="text"
                    onChange={(e, { name, value }) =>
                      this.setField(name, value)
                    }
                  />

                  <Form.Input
                    value={newFormValues.lastName || ''}
                    name="lastName"
                    label="Last Name"
                    required
                    type="text"
                    onChange={(e, { name, value }) =>
                      this.setField(name, value)
                    }
                  />

                  <Form.Input
                    value={newFormValues.email || ''}
                    name="email"
                    label="Email"
                    required
                    type="text"
                    onChange={(e, { name, value }) =>
                      this.setField(name, value)
                    }
                  />

                  <Form.Input
                    value={newFormValues.phoneNo || ''}
                    name="phoneNo"
                    label="Phone Number"
                    required
                    type="text"
                    onChange={(e, { name, value }) =>
                      this.setField(name, value)
                    }
                  />

                  <GeoAddressField
                    value={newFormValues.address || ''}
                    geoLocation={newFormValues.geoLocation}
                    autoComplete="off"
                    label="Address"
                    onChange={({ address, geoLocation }) => {
                      this.setState({
                        newFormValues: {
                          ...this.state.newFormValues,
                          address,
                          geoLocation,
                        },
                      });
                    }}
                  />

                  <DateYearField
                    label="Date of Birth"
                    required={organizationHasFields(organization, 'dob')}
                    month={this.state.dobMonth}
                    selectedDays={
                      newFormValues.dob ? new Date(newFormValues.dob) : null
                    }
                    onDateChange={(date) => this.setField('dob', date)}
                    onMonthChange={(date) => this.setState({ dobMonth: date })}
                  />
                </>
              )}
            </Form>
          </AutoFocus>
        </Modal.Content>
        <Modal.Actions>
          <Button
            loading={loading}
            primary
            content={'Add'}
            onClick={() => this.handleSubmit()}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}
