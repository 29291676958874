import qsStringify from './query-stringify';
import config from 'config';
import appSession from 'stores/AppSession';

function determineApiUrl() {
  if (
    document.location.hostname.match(/local/) ||
    document.location.hostname.match(/192\.168/)
  ) {
    return `http://${document.location.hostname}:2300`;
  }
  return config.API_URL;
}

const API_URL = determineApiUrl();

export function getFilePath(path) {
  return `${API_URL.replace(/\/$/, '')}/${path.replace(/^\//, '')}?jwt=${
    appSession.token
  }`;
}

export default function request(options) {
  const { path, method, body, params, token, files } = Object.assign(
    {
      method: 'GET',
      token: appSession.token,
    },
    options
  );

  const headers = Object.assign(
    {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    options.headers || {}
  );

  const organizationId =
    options.organizationId || localStorage.getItem('setOrganizationId');
  if (organizationId) {
    headers.Organization = organizationId;
  }

  const paramsPath = Object.keys(params || {}).length
    ? `?${qsStringify(params)}`
    : '';
  const endpoint = `${API_URL.replace(/\/$/, '')}/${path.replace(
    /^\//,
    ''
  )}${paramsPath}`;
  if (token) headers.Authorization = `Bearer ${token}`;
  let promise;
  if (method.toUpperCase() === 'GET') {
    promise = fetch(endpoint, { headers });
  } else {
    if (files) {
      const formData = new FormData();
      for (let file of files) {
        formData.append('file', file);
      }
      delete headers['Content-Type'];
      promise = fetch(endpoint, {
        method,
        headers,
        body: formData,
      });
    } else {
      promise = fetch(endpoint, {
        method,
        headers,
        body: JSON.stringify(body),
      });
    }
  }

  return promise.then((res) => {
    if (res.status === 204) return;
    if (
      ['text/csv', 'application/pdf'].includes(
        res.headers.get('Content-type')
      ) &&
      res.headers.get('Content-Disposition').includes('filename=')
    ) {
      return res.blob().then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;

        const filename = res.headers
          .get('Content-Disposition')
          .split(';')[1]
          .replace('filename=', '');

        a.download = filename.trim();
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
        return null;
      });
    }

    if (res.status === 404) {
      throw new Error('Not found');
    }

    return res.text().then((response) => {
      let json;
      try {
        json = JSON.parse(response);
      } catch (e) {
        throw new Error('Bad JSON response from API');
      }
      if (!json) throw new Error('Null JSON response from API');
      const { error } = json;
      if (error) {
        const err = new Error(error.message);
        err.status = res.status;
        throw err;
      }
      return json;
    });
  });
}
