import React from 'react';
import AppWrapper from 'components/AppWrapper';
import request from 'utils/request';
import moment from 'moment';

import { Container, Header, Loader, Divider } from 'semantic-ui-react';
import VisualizeDay from './VisualizeDay';

const numDays = 30;

export default class Availability extends React.Component {
  state = {
    location: null,
  };
  componentDidMount() {
    this.fetch();
  }
  fetch() {
    request({
      method: 'POST',
      path: '/1/locations/search',
      body: {},
    }).then(({ data }) => {
      this.setState({ location: data[0] });
    });
  }
  render() {
    const { location } = this.state;
    if (!location) return <Loader />;
    const days = [];
    for (let i = 0; numDays > i; i++) {
      const date = new Date(Date.now() + i * 24 * 3600 * 1000);
      const momentDate = moment(date);
      days.push({
        date: momentDate.format('YYYY-MM-DD'),
        title: momentDate.format('MMMM DD'),
      });
    }
    return (
      <AppWrapper>
        <Container>
          <Header as="h2">Availability</Header>
          <Divider hidden />
          {days.map(({ date, title }) => {
            return (
              <div key={title}>
                <Header as="h3" dividing>
                  {title}
                </Header>
                <VisualizeDay
                  location={location.id}
                  date={date}
                  limit={location.intervalCapacity}
                />
                <Divider hidden />
              </div>
            );
          })}
        </Container>
      </AppWrapper>
    );
  }
}
