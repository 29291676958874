import React from 'react';
import { Button, Modal, Icon, Message } from 'semantic-ui-react';

export default class ViewAppointmentTravel extends React.Component {
  static defaultProps = {};

  state = {
    open: false,
  };

  render() {
    const { calendarUrl, trigger } = this.props;
    const { open } = this.state;
    return (
      <Modal
        closeOnDimmerClick={false}
        closeIcon
        trigger={trigger}
        onClose={() => this.setState({ open: false })}
        onOpen={() => this.setState({ open: true })}
        open={open}>
        <Modal.Header>Subscribe to Calendar</Modal.Header>
        <Modal.Content>
          <p>
            Use the following iCal subscription to view all appointments in your
            calendar app:
          </p>
          <Message style={{ wordWrap: 'break-word' }}>
            <a href={calendarUrl} target="_blank" rel="noopener noreferrer">
              {calendarUrl}
            </a>
          </Message>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            content="Close"
            onClick={() => this.setState({ open: false })}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}
