import React from 'react';
import Keyboard from 'react-simple-keyboard';
import request from 'utils/request';
import {
  Form,
  Header,
  Grid,
  Button,
  Image,
  Icon,
  Loader,
  Dimmer,
  Label,
} from 'semantic-ui-react';
import { observer, inject } from 'mobx-react';
import PageCenter from 'components/PageCenter';
import SlideMessage from 'components/SlideMessage';
import Logo from 'assets/logo.svg';

const KEYBOARD_ALPHA = {
  baseClass: 'alpha',
  maxLength: 40,
  layout: {
    default: [
      '1 2 3 4 5 6 7 8 9 0 - {bksp}',
      'q w e r t y u i o p',
      'a s d f g h j k l',
      'z x c v b n m',
      '{space}',
    ],
  },
  display: {
    '{bksp}': 'Del',
    '{space}': 'Space',
  },
};

const KEYBOARD_NUMERIC = {
  baseClass: 'numeric',
  maxLength: 8,
  layout: {
    default: ['7 8 9', '4 5 6', '1 2 3', '0 . {bksp}'],
  },
  display: {
    '{bksp}': '⌫',
  },
};

import './measure.less';

@inject('patients')
@observer
export default class CreateAppointments extends React.Component {
  state = {
    query: '',
    temp: '',
    message: null,
    loading: false,
    showResults: false,
    showStatus: true,
    patients: null,
    patient: null,
    error: null,
  };

  componentDidMount() {
    document.documentElement.classList.add('touch-app');
  }

  componentDidUpdate(lastProps, lastState) {
    const { query } = this.state;
    if (query !== lastState.query) {
      this.searchPatients(query);
    }
  }

  componentWillUnmount() {
    document.documentElement.classList.remove('touch-app');
  }

  async submitMeasurement(status, temperature) {
    this.setState({
      loading: true,
    });

    await request({
      method: 'POST',
      path: '/1/measurements',
      body: {
        patient: this.state.patient.id,
        status,
        temperature,
      },
    });
    this.setState({
      message: 'Updated',
    });
    this.reset();
  }

  reset = () => {
    this.setState({
      query: '',
      temp: '',
      loading: false,
      showStatus: true,
      showResults: false,
      patient: null,
      patients: null,
    });
  };

  showPatient = (patient) => {
    this.setState({
      patient,
    });
  };

  onQuerySubmit = () => {
    this.setState({
      showResults: true,
      message: null,
    });
  };

  onTempSubmit = () => {
    this.submitMeasurement('abnormal', this.state.temp);
  };

  onTempCancel = () => {
    this.setState({
      temp: '',
      showStatus: true,
    });
  };

  onNormalClick = () => {
    this.submitMeasurement('normal');
  };

  onAbnormalClick = () => {
    this.setState({
      showStatus: false,
    });
  };

  onKeyboardChange = (query) => {
    if (this.state.patient) {
      this.setState({
        temp: query,
      });
    } else {
      this.setState({
        query,
      });
    }
  };

  async searchPatients(query) {
    if (query) {
      this.setState({
        loading: true,
      });
      const { data } = await this.props.patients.fetchItemsAutocomplete({
        searchId: query,
      });
      this.setState({
        patients: data,
        loading: false,
      });
    } else {
      this.setState({
        patients: null,
      });
    }
  }

  render() {
    const { patient, showResults, message } = this.state;
    return (
      <div className="measure-screen">
        <SlideMessage>{message}</SlideMessage>
        <PageCenter
          maxWidth="960px"
          margin="10px 0"
          style={{ position: 'static' }}>
          <Image
            src={Logo}
            alt="Logo"
            onClick={this.reset}
            style={{ height: '80px', margin: '10px auto' }}
          />
          {patient
            ? this.renderPatient(patient)
            : showResults
            ? this.renderResults()
            : this.renderSearch()}
        </PageCenter>
      </div>
    );
  }

  renderPatient(patient) {
    const { loading, showStatus } = this.state;
    return (
      <div>
        {loading && (
          <Dimmer inverted active>
            <Loader size="massive">Saving</Loader>
          </Dimmer>
        )}
        <Header size="huge">
          <Icon
            onClick={() => this.setState({ patient: null, temp: '' })}
            name="angle left"
            size="large"
            style={{
              display: 'inline-block',
            }}
          />
          {patient.firstName} {patient.lastName}
          {!patient.lastMeasurementNormal && (
            <Icon
              color="red"
              name="thermometer"
              title="Last measurement had abnormal temperature"
              style={{
                display: 'inline-block',
                marginRight: '.2em',
                verticalAlign: 'inherit',
                fontSize: '1em',
              }}
            />
          )}
        </Header>
        {!patient.lastMeasurementNormal && (
          <Header>Last temperature was high</Header>
        )}
        {showStatus ? (
          <Grid columns={2}>
            <Grid.Column>
              <Button
                fluid
                size="massive"
                color="green"
                onClick={this.onNormalClick}
                content="Normal"
              />
            </Grid.Column>
            <Grid.Column>
              <Button
                fluid
                size="massive"
                color="red"
                onClick={this.onAbnormalClick}
                content="Abnormal"
              />
            </Grid.Column>
          </Grid>
        ) : (
          this.renderTemp()
        )}
      </div>
    );
  }

  renderTemp() {
    const { temp } = this.state;
    return (
      <Form onSubmit={this.onTempSubmit}>
        <Header size="huge" style={{ minHeight: '1.4em', fontSize: '4em' }}>
          {temp ? `${temp}°F` : <span style={{ opacity: '0.2' }}>°F</span>}
        </Header>
        <Keyboard {...KEYBOARD_NUMERIC} onChange={this.onKeyboardChange} />
        <Grid style={{ marginTop: '5px' }} columns={2}>
          <Grid.Column>
            <Form.Button
              fluid
              size="massive"
              onClick={this.onTempCancel}
              content="Cancel"
            />
          </Grid.Column>
          <Grid.Column>
            <Form.Button
              fluid
              size="massive"
              color="green"
              disabled={temp.length === 0}
              content="Submit"
            />
          </Grid.Column>
        </Grid>
      </Form>
    );
  }

  renderResults() {
    const { patients } = this.state;
    return (
      <div className="scroll-list">
        <Grid divided="vertically">
          {patients.map((patient) => {
            return (
              <Grid.Row
                key={patient.id}
                style={
                  patient.cleared
                    ? {}
                    : {
                        color: '#ccc',
                      }
                }
                onClick={
                  patient.cleared ? () => this.showPatient(patient) : null
                }>
                <Grid.Column width={15}>
                  <Header
                    style={{
                      color: 'inherit',
                    }}
                    size="huge">
                    <span
                      style={{
                        display: 'inline-block',
                        verticalAlign: 'middle',
                      }}>
                      {patient.firstName} {patient.lastName}
                    </span>
                    {this.renderPatientStatus(patient)}
                  </Header>
                </Grid.Column>
                <Grid.Column verticalAlign="middle" width={1}>
                  {patient.cleared && <Icon name="angle right" size="big" />}
                </Grid.Column>
              </Grid.Row>
            );
          })}
        </Grid>
      </div>
    );
  }

  renderPatientStatus(patient) {
    if (!patient.cleared) {
      return (
        <span
          style={{
            marginLeft: '.5em',
            verticalAlign: 'middle',
            fontSize: '.7em',
          }}>
          (not cleared for entry)
        </span>
      );
    } else if (!patient.lastMeasurementNormal) {
      return (
        <Label
          color="red"
          icon="thermometer"
          content="Abnormal"
          title="Last measurement had abnormal temperature"
          style={{
            float: 'right',
            fontSize: '.8em',
            marginLeft: '.2em',
            display: 'inline-block',
            verticalAlign: 'inherit',
          }}
        />
      );
    }
  }

  renderSearch() {
    const { query, patients } = this.state;
    return (
      <Form onSubmit={this.onQuerySubmit}>
        <Header size="huge" style={{ minHeight: '1.4em', fontSize: '2.5em' }}>
          {query || <span style={{ opacity: '0.2' }}>Enter name</span>}
        </Header>
        <Keyboard {...KEYBOARD_ALPHA} onChange={this.onKeyboardChange} />
        <Grid style={{ marginTop: '5px' }} textAlign="right">
          <Grid.Column verticalAlign="middle" width={12}>
            <Header size="huge">{this.renderSearchResults()}</Header>
          </Grid.Column>
          <Grid.Column floated="right" width={4}>
            <Form.Button
              primary
              size="massive"
              disabled={!patients || patients.length === 0}
              content="Search"
            />
          </Grid.Column>
        </Grid>
      </Form>
    );
  }

  renderSearchResults() {
    const { loading, patients } = this.state;
    if (loading) {
      return <Loader inline active />;
    } else if (patients) {
      const len = patients.length;
      return `${patients.length} result${len === 0 || len > 1 ? 's' : ''}`;
    }
  }
}
