import React from 'react';
import { observer, inject } from 'mobx-react';
import Weekdays from 'components/form-fields/Weekdays';
import { Form, Label, Message, Button, Modal } from 'semantic-ui-react';
import GeoAddressField from 'components/form-fields/GeoAddress';
import SearchDropdown from 'components/SearchDropdown';
import request from 'utils/request';
import moment from 'moment-timezone';
import { escapeRegExp } from 'lodash';

import AutoFocus from 'components/AutoFocus';

@inject('locations')
@observer
export default class EditLocation extends React.Component {
  static defaultProps = {
    initialValues: {
      timeZone: 'America/New_York',
      openWeekdays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
    },
  };

  state = {
    open: false,
    formValues: { ...this.props.initialValues },
  };

  componentDidUpdate(prevProps) {
    if (this.props.initialValues !== prevProps.initialValues) {
      this.setState({
        touched: false,
        formValues: { ...this.props.initialValues },
      });
    }
  }

  handleOnClose = () => {
    this.setState({
      touched: false,
      formValues: { ...this.props.initialValues },
      open: false,
    });
  };

  handleSubmit = () => {
    const { locations, initialValues } = this.props;
    this.setState({
      touched: true,
    });

    const action = initialValues.id
      ? locations.update.bind(locations)
      : locations.create.bind(locations);

    return action(this.state.formValues).then((err) => {
      if (err instanceof Error) return;
      this.setState({
        formValues: this.props.initialValues,
        open: false,
      });
    });
  };

  setField(name, value) {
    this.setState({
      touched: false,
      formValues: {
        ...this.state.formValues,
        [name]: value,
      },
    });
  }

  render() {
    const { locations, initialValues, trigger } = this.props;
    const { formValues = {}, touched, open } = this.state;

    const isUpdate = !!initialValues.id;
    const status = isUpdate
      ? locations.getStatus('update')
      : locations.getStatus('create');

    return (
      <Modal
        closeOnDimmerClick={false}
        closeIcon
        trigger={trigger}
        onClose={this.handleOnClose}
        onOpen={() => this.setState({ open: true })}
        open={open}>
        <Modal.Header>
          {isUpdate ? `Edit Location "${initialValues.name}"` : 'New Location'}
        </Modal.Header>
        <Modal.Content>
          <AutoFocus>
            <Form
              error={touched && Boolean(status.error)}
              onSubmit={() => this.handleSubmit()}>
              {status.error && <Message error content={status.error.message} />}

              <Form.Input
                error={touched && !(formValues.name || '').length}
                required
                name="name"
                label="Name"
                type="text"
                value={formValues.name || ''}
                onChange={(e, { name, value }) => this.setField(name, value)}
              />

              <GeoAddressField
                value={formValues.address || ''}
                geoLocation={formValues.geoLocation}
                autoComplete="off"
                label="Address"
                onChange={({ address, geoLocation }) => {
                  this.setState({
                    formValues: {
                      ...this.state.formValues,
                      address,
                      geoLocation,
                    },
                  });
                }}
              />

              <Form.Input
                labelPosition="right"
                label="Session Duration"
                name="intervalMinutes"
                type="number"
                onChange={(e, { name, value }) =>
                  this.setField(name, parseInt(value, 10))
                }
                value={formValues.intervalMinutes || 0}>
                <input />
                <Label style={{ minWidth: '100px' }}>Minutes</Label>
              </Form.Input>

              <Form.Input
                labelPosition="right"
                label="Maximum per Session"
                name="intervalCapacity"
                type="number"
                onChange={(e, { name, value }) =>
                  this.setField(name, parseInt(value, 10))
                }
                value={formValues.intervalCapacity || 0}>
                <input />
                <Label style={{ minWidth: '100px' }}>People</Label>
              </Form.Input>

              <Form.Input
                labelPosition="right"
                label="Maximum Booking Horizon"
                name="maximumDaysInAdvance"
                type="number"
                onChange={(e, { name, value }) =>
                  this.setField(name, parseInt(value, 10))
                }
                value={formValues.maximumDaysInAdvance || 0}>
                <input />
                <Label style={{ minWidth: '100px' }}>Days</Label>
              </Form.Input>

              <Weekdays
                name="openWeekdays"
                label="Open Days"
                required
                value={formValues.openWeekdays}
                onChange={(e, { name, value }) => this.setField(name, value)}
              />

              <Form.Checkbox
                label="Booking Enabled?"
                name="bookingEnabled"
                checked={formValues.bookingEnabled || false}
                onChange={(e, { name, checked }) =>
                  this.setField(name, checked)
                }
              />

              <Form.Checkbox
                label="Is Hidden?"
                name="isHidden"
                checked={formValues.isHidden || false}
                onChange={(e, { name, checked }) =>
                  this.setField(name, checked)
                }
              />

              <Form.Checkbox
                label="Is self-testing location?"
                name="isSelfTesting"
                checked={formValues.isSelfTesting || false}
                onChange={(e, { name, checked }) =>
                  this.setField(name, checked)
                }
              />

              <Form.Input
                required
                name="openHour"
                label="Hour Start"
                type="text"
                value={formValues.openHour || ''}
                onChange={(e, { name, value }) => this.setField(name, value)}
              />

              <Form.Input
                required
                name="closeHour"
                label="Hour End"
                type="text"
                value={formValues.closeHour || ''}
                onChange={(e, { name, value }) => this.setField(name, value)}
              />

              <Form.Dropdown
                name="bookingMailingLists"
                search
                selection
                multiple
                allowAdditions
                options={
                  formValues.bookingMailingLists?.map((value) => {
                    return {
                      value,
                      text: value,
                    };
                  }) || []
                }
                label="Booking Mailing Lists (CC every booking)"
                onAddItem={(evt, { name, value }) => {
                  this.setField(name, [
                    ...(formValues.bookingMailingLists || []),
                    value,
                  ]);
                }}
                onChange={(e, { name, value }) => this.setField(name, value)}
                value={formValues.bookingMailingLists || []}
              />

              <Form.Dropdown
                name="timeZone"
                selection
                search={(options, query) => {
                  const re = new RegExp(escapeRegExp(query), 'i');
                  return options.filter((opt) => re.test(opt.text));
                }}
                options={moment.tz.names().map((key) => {
                  return {
                    key,
                    value: key,
                    text: key,
                  };
                })}
                label="Time Zone"
                onChange={(e, { name, value }) => this.setField(name, value)}
                value={formValues.timeZone}
              />

              <Form.Field>
                <label>Default Provider Payment Account</label>
                <SearchDropdown
                  onChange={(e, { value }) => {
                    this.setField('defaultProviderPaymentAccount', value);
                  }}
                  value={formValues.defaultProviderPaymentAccount}
                  clearable
                  fetchData={(filter) => {
                    return request({
                      method: 'POST',
                      path: '/1/payment-accounts/search',
                      body: {
                        type: 'provider',
                        ...filter,
                      },
                    });
                  }}
                  fluid
                />
              </Form.Field>
            </Form>
          </AutoFocus>
        </Modal.Content>
        <Modal.Actions>
          <Button
            loading={status.request === true}
            primary
            content={isUpdate ? 'Update' : 'Create'}
            onClick={this.handleSubmit}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}
