import React from 'react';
import { DateTime } from 'luxon';
import { urlForUpload } from 'utils/uploads';
import { Table, Button } from 'semantic-ui-react';
import Map from 'components/Map';
import { directionsUrl } from 'utils/maps';

export default class Overview extends React.Component {
  render() {
    const { patient } = this.props;
    return (
      <Table definition>
        <Table.Body>
          <Table.Row>
            <Table.Cell>Name</Table.Cell>
            <Table.Cell>
              {patient.firstName} {patient.lastName}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Type</Table.Cell>
            <Table.Cell>{patient.type}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Date of Birth</Table.Cell>
            <Table.Cell>
              {patient.dob
                ? DateTime.fromJSDate(patient.dob).toLocaleString(
                    DateTime.DATE_FULL
                  )
                : 'None'}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Email</Table.Cell>
            <Table.Cell>{patient.email}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Phone No.</Table.Cell>
            <Table.Cell>{patient.phoneNo}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Consent Form</Table.Cell>
            <Table.Cell>
              {patient.consentForm ? (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={urlForUpload(patient.consentForm)}>
                  {patient.consentForm.filename}
                </a>
              ) : (
                'None'
              )}
            </Table.Cell>
          </Table.Row>
          {patient.type === 'student' && (
            <React.Fragment>
              <Table.Row>
                <Table.Cell>Grade</Table.Cell>
                <Table.Cell>{patient.grade}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Parent/Guardian</Table.Cell>
                <Table.Cell>
                  {patient.parentFirstName} {patient.parentLastName}
                </Table.Cell>
              </Table.Row>
            </React.Fragment>
          )}

          {patient.insurancePlanName && (
            <React.Fragment>
              <Table.Row>
                <Table.Cell>Insurance Plan Name</Table.Cell>
                <Table.Cell>{patient.insurancePlanName}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Insurance Member ID</Table.Cell>
                <Table.Cell>{patient.insuranceMemberId}</Table.Cell>
              </Table.Row>
            </React.Fragment>
          )}

          {patient.address && (
            <Table.Row>
              <Table.Cell verticalAlign="top">Address</Table.Cell>
              <Table.Cell>
                {patient.address}
                {patient.geoLocation && (
                  <Map geoLocations={[patient.geoLocation]} />
                )}
                <Button
                  basic
                  icon="car"
                  content="Directions"
                  as="a"
                  rel="noopener noreferrer"
                  target="_blank"
                  href={directionsUrl(patient.address)}
                />
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    );
  }
}
