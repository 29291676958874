import React from 'react';
import { observer, inject } from 'mobx-react';
import { Switch, Route, Link } from 'react-router-dom';
import AppWrapper from 'components/AppWrapper';

import {
  Container,
  Divider,
  Breadcrumb,
  Button,
  Message,
  Icon,
  Progress,
} from 'semantic-ui-react';
import Menu from './Menu';
import PageLoader from 'components/PageLoader';
import Overview from './Overview';
import Patients from './Patients';
import LabOrders from './LabOrders';
import EditAppointment from 'components/modals/EditAppointment';
import request from 'utils/request';
import { determineProgress } from 'utils/appointments';
import SendPaymentLink from 'components/modals/SendPaymentLink';

const setupCtaStyle = {
  float: 'right',
  position: 'absolute',
  right: '1.3em',
  top: '1em',
};

@inject('me', 'appointments')
@observer
export default class Appointment extends React.Component {
  state = {
    itemId: this.props.match.params.id,
  };

  componentDidMount() {
    this.props.appointments.fetchItem(this.state.itemId);
  }

  sendEmails = (item) => {
    return request({
      method: 'POST',
      path: `/1/appointments/${item.id}/emails/confirmation`,
    }).then(() => this.refresh());
  };

  refresh() {
    this.props.appointments.fetchItem(this.state.itemId);
  }

  render() {
    const { appointments, me } = this.props;
    const { organization } = me;
    const item = appointments.get(this.state.itemId);
    const mainPatient =
      item && item.patients.length ? item.patients[0] : undefined;

    const progress =
      item && organization.type === 'conciergeService'
        ? determineProgress(organization, item)
        : undefined;
    return (
      <AppWrapper>
        <Container>
          {item &&
            (!progress ||
              progress.completed ||
              progress.nextAction !== 'EditAppointment') && (
              <>
                <EditAppointment
                  appointment={item}
                  order={item.order}
                  patient={mainPatient}
                  organization={item.organization}
                  onUpdate={() => {
                    document.location.reload();
                  }}
                  trigger={
                    <Button
                      primary
                      style={{ marginTop: '-5px' }}
                      floated="right"
                      content="Edit"
                      icon="edit"
                    />
                  }
                />
              </>
            )}
          <Breadcrumb size="big">
            <Breadcrumb.Section link as={Link} to="/">
              Home
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon="right chevron" />
            <Breadcrumb.Section link as={Link} to="/appointments">
              Appointments
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon="right chevron" />
            <Breadcrumb.Section active>
              {item
                ? mainPatient
                  ? [mainPatient.firstName, mainPatient.lastName].join(' ')
                  : 'No Patient'
                : 'Loading...'}
            </Breadcrumb.Section>
          </Breadcrumb>
          {progress && !progress.completed && (
            <div>
              <Divider hidden />
              <Message icon info>
                <Icon name={progress.icon} />
                <Message.Content>
                  <Message.Header style={{ marginBottom: '6px ' }}>
                    {progress.nextStep}
                  </Message.Header>
                  <p>{progress.description}</p>
                  <Progress
                    percent={progress.percent}
                    size="small"
                    color="teal"
                    style={{ marginBottom: '0.1em' }}
                  />
                  {progress.nextAction === 'EditAppointment' && (
                    <EditAppointment
                      appointment={item}
                      order={item.order}
                      patient={mainPatient}
                      organization={item.organization}
                      onUpdate={() => {
                        document.location.reload();
                      }}
                      trigger={
                        <Button
                          color="teal"
                          content="Edit Appointment"
                          icon="edit"
                          style={setupCtaStyle}
                        />
                      }
                    />
                  )}
                  {progress.nextAction === 'SendConfirmations' && (
                    <Button
                      content="Send Confirmation Email"
                      color="teal"
                      icon="send"
                      disabled={
                        item.status !== 'scheduled' || item.confirmationSentAt
                      }
                      onClick={() => this.sendEmails(item)}
                      style={setupCtaStyle}
                    />
                  )}
                  {progress.nextAction === 'SendPaymentLink' && (
                    <SendPaymentLink
                      appointment={item}
                      onSave={() => {
                        document.location.reload();
                      }}
                      trigger={
                        <Button
                          content="Send Payment Link"
                          color="teal"
                          icon="send"
                          style={setupCtaStyle}
                        />
                      }
                    />
                  )}
                </Message.Content>
              </Message>
            </div>
          )}
        </Container>
        <Divider hidden />
        <Menu
          itemId={this.state.itemId}
          numPatients={item ? item.patients.length : 0}
        />
        <Divider hidden />
        {!item && <PageLoader />}
        {item && (
          <Switch>
            <Route
              exact
              path="/appointments/:id"
              item={item}
              component={(props) => <Overview {...props} appointment={item} />}
            />
            <Route
              exact
              path="/appointments/:id/patients"
              item={item}
              component={(props) => (
                <Patients
                  {...props}
                  appointment={item}
                  refresh={() => this.refresh()}
                />
              )}
            />
            <Route
              exact
              path="/appointments/:id/lab-orders"
              item={item}
              component={(props) => (
                <LabOrders
                  {...props}
                  appointment={item}
                  refresh={() => this.refresh()}
                />
              )}
            />
          </Switch>
        )}
      </AppWrapper>
    );
  }
}
