import appSession from './AppSession';
import Auth from './Auth';
import Me from './Me';
import Users from './Users';
import Products from './Products';
import Appointments from './Appointments';
import Invites from './Invites';
import Locations from './Locations';
import Organizations from './Organizations';
import Patients from './Patients';
import ScheduleOverrides from './ScheduleOverrides';
import PaymentAccounts from './PaymentAccounts';
import BillingAccounts from './BillingAccounts';

export default {
  appSession,
  auth: new Auth(),
  me: new Me(),
  products: new Products(),
  appointments: new Appointments(),
  users: new Users(),
  invites: new Invites(),
  locations: new Locations(),
  organizations: new Organizations(),
  patients: new Patients(),
  scheduleOverrides: new ScheduleOverrides(),
  paymentAccounts: new PaymentAccounts(),
  billingAccounts: new BillingAccounts(),
};
