import React from 'react';
import Pagination from 'components/Pagination';

import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import EditPatient from 'components/modals/EditPatient';
import { DateTime } from 'luxon';
import ExportButton from 'components/ExportButton';
import Protected from 'components/Protected';
import { organizationHasFields } from 'utils/organizations';

import {
  Table,
  Loader,
  Segment,
  Dimmer,
  Message,
  Modal,
  Button,
  Icon,
} from 'semantic-ui-react';

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

export default class DataTable extends React.Component {
  render() {
    const {
      status,
      data: items,
      meta = {},
      getSort,
      setSort,
      page,
    } = this.props;
    return (
      <div className="list">
        {status.success && !items.length && (
          <Message>No patients created yet</Message>
        )}

        {status.success && items.length > 0 && (
          <Table celled sortable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  sorted={getSort('lastName')}
                  onClick={() => setSort('lastName')}>
                  Name
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={getSort('email')}
                  onClick={() => setSort('email')}>
                  Email
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={getSort('phoneNo')}
                  onClick={() => setSort('phoneNo')}>
                  Phone No.
                </Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell
                  width={3}
                  sorted={getSort('createdAt')}
                  onClick={() => setSort('createdAt')}>
                  Created
                </Table.HeaderCell>
                <Table.HeaderCell width={3} textAlign="center">
                  Actions
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {!items.length && (
                <Table.Row>
                  <Table.Cell>No patients added yet</Table.Cell>
                </Table.Row>
              )}
              {items.map((item) => {
                return (
                  <Table.Row key={item.id}>
                    <Table.Cell>
                      <NavLink to={`/patients/${item.id}`}>
                        {item.firstName} {item.lastName}
                      </NavLink>
                    </Table.Cell>
                    <Table.Cell>{item.email}</Table.Cell>
                    <Table.Cell>{item.phoneNo}</Table.Cell>
                    <Table.Cell>
                      {organizationHasFields(item.organization, 'cleared') ? (
                        <>
                          <Icon
                            title={
                              item.cleared
                                ? 'Clear for entry'
                                : 'Not clear for entry'
                            }
                            color={item.cleared ? 'green' : 'red'}
                            name={`${item.cleared ? 'check' : 'times'} circle`}
                          />
                          {!item.lastMeasurementNormal && (
                            <Icon
                              color="red"
                              name="thermometer"
                              title="Last measurement had abnormal temperature"
                            />
                          )}
                        </>
                      ) : (
                        '-'
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {DateTime.fromJSDate(
                        new Date(item.createdAt)
                      ).toLocaleString(DateTime.DATETIME_MED)}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <Protected endpoint="patients" permission="write">
                        <EditPatient
                          organization={item.organization}
                          initialValues={item}
                          trigger={<Button basic icon="edit" />}
                        />
                        <Modal
                          header={`Are you sure you want to delete "${item.firstName} ${item.lastName}"?`}
                          content="All data will be permanently deleted"
                          trigger={<Button basic icon="trash" />}
                          closeIcon
                          actions={[
                            {
                              key: 'delete',
                              primary: true,
                              content: 'Delete',
                              onClick: () => this.props.onDeleteItem(item),
                            },
                          ]}
                        />
                      </Protected>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        )}
        {status.success && meta.total > this.props.limit && (
          <Center>
            <Pagination
              limit={this.props.limit}
              page={page}
              total={meta.total}
              onPageChange={(e, { activePage }) => {
                this.props.setPage(activePage).then(() => {
                  window.scrollTo(0, 0);
                });
              }}
            />
          </Center>
        )}
        {status.request && (
          <Segment style={{ height: '100px' }}>
            <Dimmer active inverted>
              <Loader>Loading</Loader>
            </Dimmer>
          </Segment>
        )}
        {status.error && <Message error content={status.error.message} />}
        {status.success && meta.total > 0 && (
          <ExportButton
            content="Export All"
            path={'/1/patients/search'}
            filename="patients.csv"
          />
        )}
      </div>
    );
  }
}
