import React from 'react';
import { Button, Modal, Message, Icon } from 'semantic-ui-react';
import OrderSummary from 'components/OrderSummary';
import StripeForm from 'components/StripeForm';
import request from 'utils/request';

function orderIsPaid(order) {
  return (
    order.stripePaymentInfo &&
    order.stripePaymentInfo.payment_status === 'succeeded'
  );
}

export default class ViewLabInstructions extends React.Component {
  static defaultProps = {};

  state = {
    open: false,
    order: this.props.order,
  };

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
  startChecking() {
    this.interval = setInterval(() => this.fetch(), 4000);
  }

  fetch() {
    const { order } = this.props;
    request({
      method: 'GET',
      path: `/1/orders/${order.id}`,
    }).then(({ data }) => {
      this.setState({
        order: data.order,
      });
      if (orderIsPaid(data.order)) {
        clearInterval(this.interval);
      }
    });
  }

  render() {
    const { trigger, readOnly } = this.props;
    const { order, open, processing } = this.state;
    const isPaid = orderIsPaid(order);
    return (
      <Modal
        closeOnDimmerClick={false}
        closeIcon
        trigger={trigger}
        onClose={() => this.setState({ open: false })}
        onOpen={() => this.setState({ open: true })}
        open={open}>
        <Modal.Header>View Order</Modal.Header>
        <Modal.Content>
          <OrderSummary order={order} />
          {!readOnly &&
            (isPaid ? (
              <Message
                success
                header="Stripe payment completed"
                icon="checkmark"
              />
            ) : processing ? (
              <Message icon>
                <Icon name="circle notched" loading />
                <Message.Content>
                  <Message.Header>Processing your payment</Message.Header>
                  This can take a few seconds
                </Message.Content>
              </Message>
            ) : (
              <StripeForm
                getPaymentIntent={() =>
                  request({
                    method: 'POST',
                    path: `/1/orders/${order.id}/payment-intent`,
                    body: {},
                  })
                }
                onSuccess={() => {
                  this.setState({ processing: true });
                  this.startChecking();
                }}
              />
            ))}
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            content="Close"
            onClick={() => this.setState({ open: false })}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}
