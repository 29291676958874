import React from 'react';
import { DateTime } from 'luxon';
import { urlForUpload } from 'utils/uploads';
import { Header, Table, Modal, Button, Message } from 'semantic-ui-react';
import request from '../../utils/request';

function formatTime(hour, minute) {
  const meridian = hour > 11 ? 'pm' : 'am';
  const hour12Str = hour % 12 || 12;
  const minuteStr = `${minute}`.padStart(2, '0');
  return `${hour12Str}:${minuteStr}${meridian}`;
}

export default class Overview extends React.Component {
  cancel() {
    return request({
      method: 'POST',
      path: `/1/appointments/cancel`,
      body: {
        token: this.props.token,
      },
    }).then(() => this.props.onRefresh());
  }
  render() {
    const { appointment } = this.props;
    const mainPatient = appointment.patients[0];
    return (
      <div>
        <Header as="h2">Booking Details</Header>
        {appointment.cancelledAt && (
          <Message
            info
            icon="info circle"
            content="This booking has been cancelled"
          />
        )}
        <Table definition collapsing>
          <Table.Row>
            <Table.Cell>Patient Name</Table.Cell>
            <Table.Cell>
              {mainPatient.firstName} {mainPatient.lastName}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Patient Phone No</Table.Cell>
            <Table.Cell>
              {appointment.patient && mainPatient.phoneNo}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Appointment Date</Table.Cell>
            <Table.Cell>
              {DateTime.fromISO(appointment.requestedDate, {
                zone: 'America/New_York',
              }).toLocaleString(DateTime.DATE_MED)}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Appointment Time</Table.Cell>
            <Table.Cell>
              {formatTime(
                appointment.requestedHour,
                appointment.requestedMinute
              )}{' '}
              ET
            </Table.Cell>
          </Table.Row>
        </Table>
        {appointment.cancelledAt ? (
          <p>
            <Button basic icon="edit" content="Rebook Appointment" />
          </p>
        ) : (
          <>
            <p>
              In order to change your booking, please cancel this booking and
              rebook an appointment.
            </p>
            <Modal
              header="Confirmation"
              content="Are you sure you want to cancel this appointment?"
              trigger={
                <Button basic icon="ban" color="red" content="Cancel Booking" />
              }
              closeIcon
              actions={[
                {
                  key: 'confirm',
                  primary: true,
                  content: 'Confirm Cancellation',
                  onClick: () => this.cancel(appointment),
                },
              ]}
            />
          </>
        )}
      </div>
    );
  }
}
