import React from 'react';
import { Segment, Message } from 'semantic-ui-react';
import { observer, inject } from 'mobx-react';

import PageCenter from 'components/PageCenter';
import LogoTitle from 'components/LogoTitle';

import Form from './Form';
import request from '../../../utils/request';

@inject('routing')
@observer
export default class Login extends React.Component {
  state = {
    error: null,
    success: false,
    email: null,
  };

  componentDidMount() {
    const expired = new URLSearchParams(document.location.search).get(
      'expired'
    );
    if (expired) {
      this.setState({ error: new Error(`Your session has expired`) });
    }
  }

  handleOnSubmit = (body) => {
    request({
      method: 'POST',
      path: '/1/results/login',
      body,
    })
      .then(({ data }) => {
        this.setState({ success: true, loading: false });
      })
      .catch((error) => {
        this.setState({ error, loading: false });
      });
  };

  render() {
    const { error, loading, success } = this.state;
    return (
      <PageCenter>
        <LogoTitle title="Result Login Link" />
        <Segment.Group>
          <Segment padded>
            {success ? (
              <Message info>
                <Message.Header>Mail sent!</Message.Header>
                <p>Please follow the instructions in the email we sent</p>
              </Message>
            ) : (
              <Form
                onSubmit={this.handleOnSubmit}
                error={error}
                loading={loading}
              />
            )}
          </Segment>
          <Segment secondary>
            Need Help? <a href="mailto:info@openclear.nyc">E-Mail</a> or call{' '}
            <a href="tel:+1 (646) 397-6784">+1 (646) 397-6784</a>
          </Segment>
        </Segment.Group>
      </PageCenter>
    );
  }
}
