import React from 'react';
import Keyboard from 'react-simple-keyboard';
import request from 'utils/request';
import {
  Input,
  Form,
  Header,
  Grid,
  Button,
  Image,
  Icon,
  Loader,
  Dimmer,
} from 'semantic-ui-react';
import { observer, inject } from 'mobx-react';
import PageCenter from 'components/PageCenter';
import SlideMessage from 'components/SlideMessage';
import Logo from 'assets/logo.svg';
import moment from 'moment-timezone';

import './index.less';

function patientIsRecentlyTested(patient) {
  return Date.now() - Date.parse(patient.lastTestedAt) <= 24 * 3600 * 1000;
}

function patientIsVeryRecentlyTested(patient) {
  return Date.now() - Date.parse(patient.lastTestedAt) <= 1 * 3600 * 1000;
}

@inject('patients', 'me')
@observer
export default class CreateAppointments extends React.Component {
  state = {
    query: '',
    temp: '',
    message: null,
    loading: false,
    showResults: false,
    showStatus: true,
    patients: null,
    patient: null,
    error: null,
  };

  componentDidUpdate(lastProps, lastState) {
    const { query } = this.state;
    if (query !== lastState.query) {
      this.searchPatients(query);
    }
  }

  componentDidMount() {
    this.searchPatients();
    this.interval = setInterval(() => this.updateResults(), 4000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  updateResults() {
    const { query, patient } = this.state;
    if (!query && !patient) {
      this.searchPatients(query);
    }
  }

  async submitTestResult(result) {
    const { organization } = this.props.me;
    this.setState({
      loading: true,
      query: this.state.patient.firstName,
    });

    try {
      const now = new Date();
      await request({
        method: 'POST',
        path: '/1/results',
        body: {
          testType: organization.defaultTestResultType,
          patient: this.state.patient.id,
          result,
          sampleType: 'Nasal',
          testedAt: now,
          resultsReceivedAt: now,
          sendEmail: organization.sendResultOnQuickEntry,
        },
      });
    } catch (error) {
      alert(`Error while submitting result: ${error.message}`);
    }
    this.setState({
      message: 'Updated',
    });
    this.reset();
  }

  reset = () => {
    this.setState({
      query: '',
      temp: '',
      loading: false,
      showStatus: true,
      showResults: false,
      patient: null,
      patients: null,
    });
  };

  showPatient = (patient) => {
    this.setState({
      patient,
    });
  };

  onQuerySubmit = () => {
    this.setState({
      showResults: true,
      message: null,
    });
  };

  onSubmitResult(patient, result) {
    if (patientIsVeryRecentlyTested(patient)) {
      const isCorrection = confirm(
        'Is this a correction of the previous result?'
      );
      if (isCorrection) {
        alert(`Please use the dashboard to update the patient's test result`);
        return;
      }
    }
    this.submitTestResult(result);
  }

  async searchPatients(query) {
    this.setState({
      loading: true,
    });
    const params = {
      sort: {
        order: 'desc',
        field: 'lastBookedAt',
      },
    };
    if (query) {
      params.searchId = query;
    } else {
      params.notRecentlyTested = true;
    }
    const { data } = await this.props.patients.fetchItemsAutocomplete(params);
    this.setState({
      patients: data,
      loading: false,
    });
  }

  render() {
    const { patient, patients, message } = this.state;
    return (
      <div className="quick-entry-results">
        <SlideMessage>{message}</SlideMessage>
        <PageCenter
          maxWidth="960px"
          margin="10px 0"
          style={{ position: 'static' }}>
          <Image
            src={Logo}
            alt="Logo"
            onClick={this.reset}
            style={{ height: '80px', margin: '10px auto' }}
          />
          {patient && this.renderPatient(patient)}
          {!patient && this.renderSearch()}
          {!patient && patients && this.renderResults()}
        </PageCenter>
      </div>
    );
  }

  renderPatient(patient) {
    const { loading } = this.state;
    return (
      <div className={`test-result-detail`}>
        {loading && (
          <Dimmer inverted active>
            <Loader size="massive">Saving</Loader>
          </Dimmer>
        )}
        <Header size="huge">
          <Icon
            onClick={() =>
              this.setState({ patient: null, temp: '', query: '' })
            }
            name="angle left"
            size="large"
            style={{
              display: 'inline-block',
            }}
          />
          {patient.firstName} {patient.lastName}
        </Header>
        {patient.lastTestedAt && (
          <p>
            Was tested <strong>{patient.lastTestedResult}</strong>{' '}
            {moment(patient.lastTestedAt).fromNow()}
          </p>
        )}
        <Grid stackable columns={3}>
          <Grid.Column>
            <Button
              fluid
              size="massive"
              color="green"
              onClick={() => this.onSubmitResult(patient, 'negative')}
              content="NEGATIVE"
            />
          </Grid.Column>
          <Grid.Column>
            <Button
              fluid
              size="massive"
              color="gray"
              onClick={() => this.onSubmitResult(patient, 'inconclusive')}
              content="INCONCLUSIVE"
            />
          </Grid.Column>
          <Grid.Column>
            <Button
              fluid
              size="massive"
              color="red"
              onClick={() => this.onSubmitResult(patient, 'positive')}
              content="POSITIVE"
            />
          </Grid.Column>
        </Grid>
      </div>
    );
  }

  renderResults() {
    const { patients } = this.state;
    return (
      <div className="tests-scroll-list">
        <Grid divided="vertically">
          {patients.map((patient) => {
            return (
              <Grid.Row
                key={patient.id}
                style={
                  patient.cleared
                    ? {}
                    : {
                        color: '#ccc',
                      }
                }
                className={
                  patientIsRecentlyTested(patient) ? 'recently-tested' : ''
                }
                onClick={
                  patient.cleared ? () => this.showPatient(patient) : null
                }>
                <Grid.Column width={16} verticalAlign="middle">
                  <Header
                    style={{
                      color: 'inherit',
                      paddingLeft: '0.5em',
                      fontSize: '1.6em',
                    }}
                    size="huge">
                    <Icon
                      name="angle right"
                      style={{ float: 'right' }}
                      size="big"
                    />
                    <span
                      style={{
                        width: '80%',
                        height: '30px',
                        display: 'inline-block',
                        verticalAlign: 'middle',
                        overflow: 'hidden',
                      }}>
                      {patient.firstName} {patient.lastName}
                      {patientIsRecentlyTested(patient) && (
                        <Icon
                          name="check"
                          style={{ marginLeft: '10px', marginTop: '0.2em' }}
                        />
                      )}
                    </span>
                  </Header>
                </Grid.Column>
              </Grid.Row>
            );
          })}
        </Grid>
      </div>
    );
  }

  renderSearch() {
    const { query } = this.state;
    return (
      <div className="tests-query">
        <Form onSubmit={this.onQuerySubmit}>
          <Input
            icon="search"
            defaultValue={query}
            placeholder="Search patient by name"
            clearable
            size="huge"
            fluid
            onChange={(e, { value }) => {
              if (this.state.patient) {
                this.setState({
                  temp: value,
                });
              } else {
                this.setState({
                  query: value,
                });
              }
            }}
          />
        </Form>
      </div>
    );
  }

  renderSearchResults() {
    const { loading, patients } = this.state;
    if (loading) {
      return <Loader inline active />;
    } else if (patients) {
      const len = patients.length;
      return `${patients.length} result${len === 0 || len > 1 ? 's' : ''}`;
    }
  }
}
