import React from 'react';
import Pagination from 'components/Pagination';

import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import EditLabOrder from 'components/modals/EditLabOrder';
import { DateTime } from 'luxon';
import Protected from 'components/Protected';

import {
  Table,
  Loader,
  Segment,
  Dimmer,
  Message,
  Modal,
  Button,
  Divider,
} from 'semantic-ui-react';
import ViewLabInstructions from 'components/modals/ViewLabInstructions';
import { formatLabOrderStatus } from 'utils/formatting';

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

export default class DataTable extends React.Component {
  render() {
    const {
      status,
      data: items,
      meta = {},
      getSort,
      setSort,
      page,
      appointment,
    } = this.props;
    return (
      <div className="list">
        {status.success && !items.length && (
          <Message>No lab orders created yet</Message>
        )}

        {status.success && items.length > 0 && (
          <Table celled sortable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Patient</Table.HeaderCell>
                <Table.HeaderCell>Product</Table.HeaderCell>
                <Table.HeaderCell
                  sorted={getSort('status')}
                  onClick={() => setSort('status')}>
                  Status
                </Table.HeaderCell>
                <Table.HeaderCell
                  width={3}
                  sorted={getSort('createdAt')}
                  onClick={() => setSort('createdAt')}>
                  Created
                </Table.HeaderCell>
                <Table.HeaderCell width={3} textAlign="center">
                  Actions
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {!items.length && (
                <Table.Row>
                  <Table.Cell>No lab orders added yet</Table.Cell>
                </Table.Row>
              )}
              {items.map((item) => {
                return (
                  <Table.Row key={item.id}>
                    <Table.Cell>
                      <NavLink to={`/patients/${item.patient.id}`}>
                        {item.patient.firstName} {item.patient.lastName}
                      </NavLink>
                    </Table.Cell>
                    <Table.Cell>{item.product.name}</Table.Cell>
                    <Table.Cell>{formatLabOrderStatus(item, true)}</Table.Cell>
                    <Table.Cell>
                      {DateTime.fromJSDate(
                        new Date(item.createdAt)
                      ).toLocaleString(DateTime.DATETIME_MED)}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <Protected endpoint="labOrders" permission="write">
                        <Button
                          basic
                          disabled={item.labExternalId}
                          icon="flask"
                          title="Push to LabQ"
                          onClick={() => this.props.onPushToLab(item)}
                        />
                        <EditLabOrder
                          appointment={appointment}
                          organization={item.organization}
                          initialValues={item}
                          onSave={() => this.props.onUpdateItem()}
                          trigger={<Button basic icon="edit" />}
                        />
                        <Modal
                          header={`Are you sure you want to delete lab order for "${item.patient.firstName} ${item.patient.lastName}"?`}
                          content="All data will be permanently deleted"
                          trigger={<Button basic icon="trash" />}
                          closeIcon
                          actions={[
                            {
                              key: 'delete',
                              primary: true,
                              content: 'Delete',
                              onClick: () => this.props.onDeleteItem(item),
                            },
                          ]}
                        />
                      </Protected>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        )}
        <Divider hidden />
        {status.success && meta.total > this.props.limit && (
          <Center>
            <Pagination
              limit={this.props.limit}
              page={page}
              total={meta.total}
              onPageChange={(e, { activePage }) => {
                this.props.setPage(activePage).then(() => {
                  window.scrollTo(0, 0);
                });
              }}
            />
          </Center>
        )}
        {status.request && (
          <Segment style={{ height: '100px' }}>
            <Dimmer active inverted>
              <Loader>Loading</Loader>
            </Dimmer>
          </Segment>
        )}
        {status.error && <Message error content={status.error.message} />}
      </div>
    );
  }
}
