import React from 'react';
import {
  Form,
  Table,
  Button,
  Input,
  Icon,
  Modal,
  Label,
} from 'semantic-ui-react';
import SearchDropdown from '../SearchDropdown';
import request from 'utils/request';
import { formatUsd } from 'utils/formatting';
import { customTypes } from 'utils/orders';

const customTypeOptions = Object.keys(customTypes).map((key) => {
  return {
    key,
    value: key,
    ...customTypes[key],
  };
});

export default class OrdersField extends React.Component {
  constructor(props) {
    super(props);
    const items = props.items || [];
    this.state = {
      items,
      totalPrice: this.calculateTotal(items),
      currentProduct: undefined,
      currentQuantity: '1',
      customType: 'travelFee',
    };
  }

  componentDidMount() {
    this.save();
  }

  async fetchProducts(filter) {
    return await request({
      method: 'POST',
      path: '/1/products/search',
      body: {
        ...filter,
      },
      organizationId: this.props.organizationId,
    });
  }

  save() {
    const { totalPrice, items } = this.state;
    this.props.onChange({
      items,
      totalPrice,
    });
  }

  calculateTotal(items) {
    let totalPrice = 0;
    items.forEach((item) => {
      totalPrice += item.quantity * item.price;
    });
    return totalPrice;
  }

  addProduct() {
    const { items, currentProduct, currentQuantity } = this.state;
    items.push({
      type: 'product',
      product: currentProduct.id,
      name: currentProduct.name,
      externalName: currentProduct.externalName,
      quantity: currentQuantity,
      price: currentProduct.price,
    });
    const totalPrice = this.calculateTotal(items);
    this.setState({ items, totalPrice }, () => this.save());
  }

  addCustom() {
    const { items, customType, customPrice } = this.state;
    items.push({
      type: customType,
      name: customTypes[customType].text,
      quantity: 1,
      price:
        customType === 'discount'
          ? 0 - parseInt(customPrice * 100)
          : parseInt(customPrice * 100),
    });
    const totalPrice = this.calculateTotal(items);
    this.setState({ items, totalPrice }, () => this.save());
  }

  delete(index) {
    const { items } = this.state;
    const newItems = [];
    items.forEach((item, i) => {
      if (i !== index) {
        newItems.push(item);
      }
    });
    const totalPrice = this.calculateTotal(newItems);
    this.setState({ items: newItems, totalPrice }, () => this.save());
  }

  render() {
    const {
      items,
      totalPrice,
      currentProduct,
      currentQuantity,
      customOrderModalOpen,
    } = this.state;
    const { required } = this.props;
    return (
      <Form.Field required={required}>
        <label>Orders</label>
        <Table compact celled definition style={{ marginTop: 0 }}>
          <Table.Body>
            {items.map((item, i) => {
              return (
                <Table.Row key={i}>
                  <Table.Cell>
                    <a
                      style={{ cursor: 'pointer' }}
                      onClick={() => this.delete(i)}>
                      <Icon name="delete" />
                    </a>
                    {item.type === 'product' ? (
                      `${item.name} x ${item.quantity}`
                    ) : (
                      <>
                        {item.name} <Icon name={customTypes[item.type].icon} />
                      </>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {formatUsd(item.quantity * item.price)}
                  </Table.Cell>
                </Table.Row>
              );
            })}

            <Table.Row>
              <Table.Cell textAlign="right">Total</Table.Cell>
              <Table.Cell>
                <strong>{formatUsd(totalPrice)}</strong>
              </Table.Cell>
            </Table.Row>
          </Table.Body>

          <Table.Footer fullWidth>
            <Table.Row>
              <Table.HeaderCell colSpan="2">
                <SearchDropdown
                  size="small"
                  style={{ marginBottom: '0.5em' }}
                  fluid
                  value={currentProduct && currentProduct.id}
                  selectFirst
                  onChange={(e, { value }, product) =>
                    this.setState({ currentProduct: product })
                  }
                  fetchData={(filter) => this.fetchProducts(filter)}
                  search={false}
                />
                Quantity:{' '}
                <Input
                  value={currentQuantity}
                  onChange={(e, { value }) =>
                    this.setState({ currentQuantity: value })
                  }
                  style={{ width: '50px' }}
                  size="small"
                />
                <Button
                  primary
                  content="Add"
                  size="small"
                  style={{ float: 'right', marginRight: 0 }}
                  onClick={(e) => {
                    e.preventDefault();
                    this.addProduct();
                  }}
                />
                <Button
                  basic
                  icon="plus"
                  content="Custom"
                  size="small"
                  style={{ float: 'right', marginRight: '0.3em' }}
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ customOrderModalOpen: true });
                  }}
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
        <Modal
          closeOnDimmerClick={false}
          closeIcon
          size="small"
          dimmer
          onClose={() => this.setState({ customOrderModalOpen: false })}
          onOpen={() => this.setState({ customOrderModalOpen: true })}
          open={customOrderModalOpen}>
          <Modal.Header>Add Custom Line Item</Modal.Header>
          <Modal.Content>
            <Form>
              <Form.Select
                name="customType"
                required
                options={customTypeOptions}
                label="Type"
                onChange={(e, { value }) =>
                  this.setState({ customType: value })
                }
                value={this.state.customType}
              />

              <Form.Input
                labelPosition="right"
                value={this.state.customPrice}
                type="number"
                name="customPrice"
                label={
                  this.state.customType === 'discount'
                    ? 'Discount Amount'
                    : 'Amount'
                }
                onChange={(e, { value }) =>
                  this.setState({ customPrice: value })
                }>
                <Label basic>$</Label>
                <input />
                <Label>.00</Label>
              </Form.Input>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button
              basic
              content="Close"
              onClick={(e) => {
                e.preventDefault();
                this.setState({ customOrderModalOpen: false });
              }}
            />
            <Button
              primary
              content="Add"
              onClick={(e) => {
                e.preventDefault();
                this.addCustom();
                this.setState({ customOrderModalOpen: false });
              }}
            />
          </Modal.Actions>
        </Modal>
      </Form.Field>
    );
  }
}
