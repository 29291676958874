import React from 'react';
import { Form, Message, Modal, Button, Grid } from 'semantic-ui-react';
import Availability from 'components/form-fields/Availability';
import LocationField from 'components/form-fields/LocationField';
import GeoAddressField from 'components/form-fields/GeoAddress';
import { formatDateStr } from 'utils/formatting';
import request from 'utils/request';
import { get, set } from 'lodash';

export default class NewAppointment extends React.Component {
  static defaultProps = {
    appointment: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      error: null,
      loading: false,
      location: null,
      appointmentFormValues: { ...props.appointment },
    };
  }

  componentDidUpdate(prevProps) {
    const { open } = this.state;
    if (open) return;
    if (this.props.appointment !== prevProps.appointment) {
      this.setState({
        touched: false,
        appointmentFormValues: { ...this.props.appointment },
      });
    }
  }

  setAppointmentField(name, value) {
    const { appointmentFormValues } = this.state;
    set(appointmentFormValues, name, value);
    this.setState({
      appointmentFormValues,
    });
  }

  onSubmit = async (redirect = false) => {
    this.setState({
      error: null,
      touched: true,
      loading: true,
    });
    const { appointment } = this.props;
    try {
      const appointmentBody = this.state.appointmentFormValues;
      if (appointmentBody.location && appointmentBody.location.id) {
        appointmentBody.location = appointmentBody.location.id;
      }
      appointmentBody.skipEmails = true;
      await request({
        method: 'POST',
        path: `/1/appointments/`,
        body: appointmentBody,
      });
      this.setState({ open: false, loading: false, touched: false });
      if (redirect) {
        document.location = `/appointments/${appointment.id}`;
      } else {
        this.props.onUpdate && this.props.onUpdate();
      }
    } catch (error) {
      this.setState({ error });
    }
    this.setState({ loading: false });
  };

  onError = (error) => {
    this.setState({
      error,
    });
  };

  onLocationChange = (evt, { value, location }) => {
    this.setAppointmentField('location', value);
    this.setState({
      location,
    });
  };

  onDateChange = (name, value) => {
    const dateObj = new Date(Date.parse(value));
    this.setAppointmentField(name, formatDateStr(dateObj));
  };

  onSlotChange = (slot) => {
    this.setState({
      appointmentFormValues: {
        ...this.state.appointmentFormValues,
        requestedHour: slot.hour,
        requestedMinute: slot.minute,
      },
    });
  };

  handleOnClinicianUserChange = (e, { value }, selectedClinicianUser) => {
    this.setState({
      selectedClinicianUser,
      appointmentFormValues: {
        ...this.state.appointmentFormValues,
      },
    });
  };

  render() {
    const { trigger } = this.props;
    const { appointmentFormValues = {}, error, loading, open } = this.state;

    const locationId =
      appointmentFormValues.location && appointmentFormValues.location.id
        ? appointmentFormValues.location.id
        : appointmentFormValues.location;

    return (
      <Modal
        size="medium"
        closeOnDimmerClick={false}
        closeIcon
        onClose={() =>
          this.setState({
            open: false,
            appointmentFormValues: this.props.appointment,
            touched: false,
          })
        }
        onOpen={() => this.setState({ open: true })}
        open={open}
        trigger={trigger}>
        <Modal.Header>New Appointment</Modal.Header>
        <Modal.Content>
          {error && <Message error content={error.message} />}
          <Form onSubmit={this.onSubmit}>
            <Grid columns={1} divided>
              <Grid.Row>
                <Grid.Column>
                  <LocationField
                    required
                    value={locationId}
                    onChange={this.onLocationChange}
                  />

                  <Form.Group widths="equal">
                    <Form.Input
                      value={get(appointmentFormValues, 'patient.firstName')}
                      name="patient.firstName"
                      label="First Name"
                      type="text"
                      onChange={(e, { name, value }) =>
                        this.setAppointmentField(name, value)
                      }
                    />

                    <Form.Input
                      value={get(appointmentFormValues, 'patient.lastName')}
                      name="patient.lastName"
                      label="Last Name"
                      type="text"
                      onChange={(e, { name, value }) =>
                        this.setAppointmentField(name, value)
                      }
                    />
                  </Form.Group>

                  <Form.Group widths="equal">
                    <Form.Input
                      value={get(appointmentFormValues, 'patient.email')}
                      name="patient.email"
                      label="E-mail Address"
                      type="text"
                      onChange={(e, { name, value }) =>
                        this.setAppointmentField(name, value)
                      }
                    />

                    <Form.Input
                      value={get(appointmentFormValues, 'patient.phoneNo')}
                      name="patient.phoneNo"
                      label="Phone No"
                      type="text"
                      onChange={(e, { name, value }) =>
                        this.setAppointmentField(name, value)
                      }
                    />
                  </Form.Group>

                  <GeoAddressField
                    value={get(appointmentFormValues, 'patient.address')}
                    geoLocation={appointmentFormValues.geoLocation}
                    autoComplete="off"
                    label="Patient Address"
                    onChange={({ address, geoLocation }) => {
                      const { appointmentFormValues } = this.state;
                      set(appointmentFormValues, 'patient.address', address);
                      set(
                        appointmentFormValues,
                        'patient.geoLocation',
                        geoLocation
                      );
                      this.setState({
                        appointmentFormValues,
                      });
                    }}
                  />

                  <Availability
                    location={this.state.location}
                    dateStr={appointmentFormValues.requestedDate}
                    hour={appointmentFormValues.requestedHour}
                    minute={appointmentFormValues.requestedMinute}
                    onError={this.onError}
                    onDateChange={this.onDateChange}
                    onSlotChange={this.onSlotChange}
                    onAvailabilityReceived={this.onAvailabilityReceived}
                  />

                  <Form.TextArea
                    value={appointmentFormValues.comment || ''}
                    name="comment"
                    label="Client Comment"
                    type="text"
                    onChange={(e, { name, value }) =>
                      this.setAppointmentField(name, value)
                    }
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            loading={loading}
            primary
            content="Next"
            onClick={() => this.onSubmit()}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}
