import React from 'react';
import { Form, Dropdown } from 'semantic-ui-react';

const GRADES = [
  'JK',
  'K',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
];

export default class GradeField extends React.Component {

  render() {
    return (
      <Form.Field required>
        <label>Grade</label>
        <Dropdown
          fluid
          required
          selection
          name="grade"
          value={this.props.value}
          options={GRADES.map((grade) => {
            return {
              text: grade,
              value: grade,
            };
          })}
          onChange={this.props.onChange}
        />
      </Form.Field>
    );
  }

}
