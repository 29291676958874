import { createOptions } from 'utils/form';

export function determineProgress(organization, appointment) {
  const total = organization.enablePayments ? 7 : 5;
  const progress = {
    total,
    current: total,
    state: 'Completed',
    nextAction: undefined,
    nextStep: '',
    icon: 'settings',
    description: '',
  };

  if (appointment.order && appointment.order.status !== 'paid') {
    progress.state = 'Incomplete';
    if (organization.enablePayments) {
      progress.nextStep = 'Waiting on Payment';
      progress.description =
        'The customer has received an email with a payment link.';
    } else {
      if (appointment.order.status === 'sent') {
        progress.nextStep = 'Needs Payment';
        progress.description =
          'The customer needs to pay bill. A manual status change to "paid" is needed to complete this booking.';
      } else {
        progress.nextStep = 'Needs Invoice';
        progress.description =
          'The customer needs to be sent payment info. A manual status change to "sent" is needed.';
      }
      progress.current -= 1;
    }

    progress.icon = 'payment';
  }

  if (
    organization.enablePayments &&
    appointment.order &&
    appointment.order.status !== 'paid' &&
    !appointment.paymentLinkSentAt
  ) {
    progress.state = 'Incomplete';
    progress.nextAction = 'SendPaymentLink';
    progress.nextStep = 'Payment Needed';
    progress.description =
      'Customer appointment is scheduled, payment is needed.';
    progress.current -= 1;
    progress.icon = 'mail outline';
  }

  if (
    organization.enablePayments &&
    appointment.order &&
    !appointment.order.providerPaymentAccount
  ) {
    progress.state = 'Incomplete';
    progress.nextAction = 'EditAppointment';
    progress.nextStep = 'Provider Payment Account Needed';
    progress.description =
      'The order needs a provider payment account specified to which payments will be dispatched';
    progress.current -= 1;
    progress.icon = 'user md';
  }

  if (appointment.status === 'scheduled' && !appointment.confirmationSentAt) {
    progress.state = 'Incomplete';
    progress.nextAction = 'SendConfirmations';
    progress.nextStep = 'Send Confirmation Mails';
    progress.description = 'All patients need to receive a confirmation email.';
    progress.current -= 1;
    progress.icon = 'mail outline';
  }

  if (appointment.status !== 'scheduled') {
    progress.state = 'Incomplete';
    progress.nextAction = 'EditAppointment';
    progress.nextStep = 'Needs Scheduling';
    progress.description = 'This appointment still needs to be scheduled.';
    progress.current -= 1;
    progress.icon = 'calendar outline';
  }

  if (appointment.order && appointment.order.totalPrice === 0) {
    progress.state = 'Incomplete';
    progress.nextAction = 'EditAppointment';
    progress.nextStep = 'Add Purchases';
    progress.description =
      'The order is currently empty, please add purchased products.';
    progress.current -= 1;
    progress.icon = 'table';
  }

  if (appointment.conciergeStatus !== 'completed') {
    progress.state = 'Incomplete';
    progress.nextAction = 'EditAppointment';
    progress.nextStep = 'Needs Closing';
    progress.description = 'This booking still needs to be closed.';
    progress.current -= 1;
    progress.icon = 'coffee';
  }

  progress.percent = (progress.current * 100) / progress.total;
  progress.color = progress.state === 'Incomplete' ? undefined : 'olive';
  progress.completed = progress.state === 'Completed';
  if (progress.completed) {
    progress.nextStep = 'Done!';
    progress.description = 'No further actions are required.';
  }
  return progress;
}

export const statuses = {
  pending: {
    name: 'Pending',
    icon: 'wait',
  },
  scheduled: {
    name: 'Scheduled',
    icon: 'checkmark',
    color: 'olive',
  },
  cancelled: {
    name: 'Cancelled',
    icon: 'dont',
    color: 'red',
  },
};

export const appointmentStatusOptions = createOptions(statuses);

export const conciergeStatuses = {
  new: {
    name: 'New',
    icon: 'wait',
  },
  needsFollowUp: {
    name: 'Needs Follow Up',
    icon: 'phone',
    color: 'yellow',
  },
  completed: {
    name: 'Closed',
    icon: 'handshake',
    color: 'olive',
  },
  lost: {
    name: 'Lost',
    icon: 'thumbs down',
  },
};

export const conciergeStatusOptions = createOptions(conciergeStatuses);

export const reasonsForLost = [
  'Got test elsewhere',
  'No longer needed',
  'Too expensive',
  'Travel fee too high',
  'Duplicate',
];

export const industries = [
  'Accounting',
  'Airlines/Aviation',
  'Alternative Dispute Resolution',
  'Alternative Medicine',
  'Animation',
  'Apparel & Fashion',
  'Architecture & Planning',
  'Arts & Crafts',
  'Automotive',
  'Aviation & Aerospace',
  'Banking',
  'Biotechnology',
  'Broadcast Media',
  'Building Materials',
  'Business Supplies & Equipment',
  'Capital Markets',
  'Chemicals',
  'Civic & Social Organization',
  'Civil Engineering',
  'Commercial Real Estate',
  'Computer & Network Security',
  'Computer Games',
  'Computer Hardware',
  'Computer Networking',
  'Computer Software',
  'Construction',
  'Consumer Electronics',
  'Consumer Goods',
  'Consumer Services',
  'Cosmetics',
  'Dairy',
  'Defense & Space',
  'Design',
  'Education Management',
  'E-learning',
  'Electrical & Electronic Manufacturing',
  'Entertainment',
  'Environmental Services',
  'Events Services',
  'Executive Office',
  'Facilities Services',
  'Farming',
  'Financial Services',
  'Fine Art',
  'Fishery',
  'Food & Beverages',
  'Food Production',
  'Fundraising',
  'Furniture',
  'Gambling & Casinos',
  'Glass, Ceramics & Concrete',
  'Government Administration',
  'Government Relations',
  'Graphic Design',
  'Health, Wellness & Fitness',
  'Higher Education',
  'Hospital & Health Care',
  'Hospitality',
  'Human Resources',
  'Import & Export',
  'Individual & Family Services',
  'Industrial Automation',
  'Information Services',
  'Information Technology & Services',
  'Insurance',
  'International Affairs',
  'International Trade & Development',
  'Internet',
  'Investment Banking/Venture',
  'Investment Management',
  'Judiciary',
  'Law Enforcement',
  'Law Practice',
  'Legal Services',
  'Legislative Office',
  'Leisure & Travel',
  'Libraries',
  'Logistics & Supply Chain',
  'Luxury Goods & Jewelry',
  'Machinery',
  'Management Consulting',
  'Maritime',
  'Marketing & Advertising',
  'Market Research',
  'Mechanical or Industrial Engineering',
  'Media Production',
  'Medical Device',
  'Medical Practice',
  'Mental Health Care',
  'Military',
  'Mining & Metals',
  'Motion Pictures & Film',
  'Museums & Institutions',
  'Music',
  'Nanotechnology',
  'Newspapers',
  'Nonprofit Organization Management',
  'Oil & Energy',
  'Online Publishing',
  'Outsourcing/Offshoring',
  'Package/Freight Delivery',
  'Packaging & Containers',
  'Paper & Forest Products',
  'Performing Arts',
  'Pharmaceuticals',
  'Philanthropy',
  'Photography',
  'Plastics',
  'Political Organization',
  'Primary/Secondary',
  'Printing',
  'Professional Training',
  'Program Development',
  'Public Policy',
  'Public Relations',
  'Public Safety',
  'Publishing',
  'Railroad Manufacture',
  'Ranching',
  'Real Estate',
  'Recreational',
  'Facilities & Services',
  'Religious Institutions',
  'Renewables & Environment',
  'Research',
  'Restaurants',
  'Retail',
  'Security & Investigations',
  'Semiconductors',
  'Shipbuilding',
  'Sporting Goods',
  'Sports',
  'Staffing & Recruiting',
  'Supermarkets',
  'Telecommunications',
  'Textiles',
  'Think Tanks',
  'Tobacco',
  'Translation & Localization',
  'Transportation/Trucking/Railroad',
  'Utilities',
  'Venture Capital',
  'Veterinary',
  'Warehousing',
  'Wholesale',
  'Wine & Spirits',
  'Wireless',
  'Writing & Editing',
];
