import React from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import {
  Button,
  Modal,
  Form,
  Label,
  Icon,
  Grid,
  Checkbox,
} from 'semantic-ui-react';

function getDefaultState(/*props*/) {
  return {
    open: false,
  };
}

const columnDefinitions = {
  location: {
    name: 'Location',
  },
  contact: {
    name: 'Contact',
  },
  progress: {
    name: 'Progress',
    textAlign: 'center',
    width: 3,
  },
  conciergeStatus: {
    name: 'Concierge Status',
    textAlign: 'center',
  },
  status: {
    name: 'Appointment',
    textAlign: 'center',
  },
  dispatchingStatus: {
    name: 'Dispatching Status',
    textAlign: 'center',
  },
  'order.status': {
    name: 'Order Status',
    textAlign: 'center',
  },
  promoCode: {
    name: 'Promo Code',
  },
  'salesInfo.referralCode': {
    name: 'Referral Code',
  },
  'salesInfo.clickIdentifier': {
    name: 'Click ID',
  },
  'order.totalPrice': {
    name: 'Order Total',
  },
  numPatients: {
    name: 'Number of Patients',
    shortName: 'Patients',
  },
  createdAt: {
    name: 'Created',
    shortName: 'Created',
  },
};

function getSavedState() {
  const str = localStorage.getItem('appointmentScreenColumns');
  if (str) {
    return JSON.parse(str);
  }
  return {
    location: false,
    conciergeStatus: true,
    dispatchingStatus: false,
    status: true,
    progress: true,
  };
}

function saveState(fields) {
  localStorage.setItem('appointmentScreenColumns', JSON.stringify(fields));
}

export default class Columns extends React.Component {
  state = {
    ...getDefaultState(this.props),
    appliedColumns: [],
    fields: getSavedState(),
    open: false,
  };

  open = () => this.setState({ open: true });
  close = () => {
    this.setState(getDefaultState(this.props));
  };

  componentDidMount() {
    const appliedColumns = this.computeColumns();
    this.props.onChange(appliedColumns);
    this.setState({
      appliedColumns: appliedColumns,
    });
  }

  computeColumns() {
    const { fields } = this.state;
    return Object.keys(fields)
      .filter((key) => fields[key])
      .map((key) => {
        return {
          key,
          ...columnDefinitions[key],
        };
      });
  }

  handleApply = () => {
    const { fields } = this.state;
    saveState(fields);
    const appliedColumns = this.computeColumns();
    this.props.onChange(appliedColumns);
    this.setState({
      appliedColumns: appliedColumns,
    });
    this.close();
  };

  handleReset = () => {
    this.props.onChange([]);
    this.setState({
      appliedColumns: [],
    });
    this.close();
  };

  toggleField = (path, checked) => {
    const { fields } = this.state;
    fields[path] = checked;
    this.setState({ fields });
  };

  render() {
    const { fields } = this.state;

    return (
      <>
        <Modal
          size="tiny"
          open={this.state.open}
          onOpen={this.open}
          onClose={this.close}
          trigger={
            <Button primary={true} floated="left" style={{ float: 'left' }}>
              <Icon name="columns" /> Columns
            </Button>
          }
          closeIcon>
          <Modal.Header>Columns</Modal.Header>
          <Modal.Content>
            <Form>
              <Grid divided>
                <Grid.Row>
                  <Grid.Column width={8}>
                    {Object.keys(columnDefinitions).map((key) => {
                      const column = columnDefinitions[key];
                      return (
                        <Form.Checkbox
                          key={key}
                          label={column.name}
                          checked={fields[key]}
                          onChange={(e, { checked }) => {
                            this.toggleField(key, checked);
                          }}
                          toggle
                        />
                      );
                    })}
                  </Grid.Column>
                  <Grid.Column width={8}></Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button content="Reset" onClick={this.handleReset} />
            <Button
              primary
              icon="check"
              content="Apply"
              onClick={this.handleApply}
            />
          </Modal.Actions>
        </Modal>
      </>
    );
  }
}
