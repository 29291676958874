import React from 'react';
import { observer, inject } from 'mobx-react';
import BookingAppWrapper from 'components/BookingAppWrapper';
import { Switch, Route } from 'react-router-dom';

import { Message, Divider } from 'semantic-ui-react';
import PageLoader from 'components/PageLoader';
import Submit from './Submit';
import PastSubmissions from './PastSubmissions';
import request from 'utils/request';
import Menu from './Menu';

@inject('patients')
@observer
export default class ViewResultSubmission extends React.Component {
  state = {
    token: localStorage.getItem('resultSubmissionToken'),
    results: null,
    error: null,
    loading: true,
  };

  componentDidMount() {
    this.fetch();
  }

  fetch() {
    this.setState({ loading: true });
    const { token } = this.state;
    if (!token) {
      document.location = '/my-result-submission/login';
      return;
    }
    request({
      method: 'POST',
      path: '/1/results/submissions/fetch',
      body: {
        token,
      },
    })
      .then(({ data }) => {
        this.setState({ results: data, loading: false });
      })
      .catch((error) => {
        if (error.message.match(/invalid/i)) {
          document.location = '/my-result-submission/login?expired=true';
        } else {
          this.setState({ error, loading: false });
        }
      });
  }

  render() {
    const { loading, results, error, token } = this.state;
    return (
      <BookingAppWrapper logoutPath="/result-submission/logout">
        <Divider hidden />
        <Menu
          results={results}
          token={token}
          organization={results && results[0] && results[0].organization}
        />
        <Divider hidden />
        {error && <Message error content={error.message} />}
        {loading && <PageLoader />}
        {!error && (
          <Switch>
            <Route
              exact
              path="/my-result-submission"
              component={(props) => (
                <Submit
                  {...props}
                  token={token}
                  results={results}
                  onRefresh={() => {
                    this.fetch();
                  }}
                />
              )}
            />
            <Route
              exact
              path="/my-result-submission/past"
              component={(props) => (
                <PastSubmissions
                  {...props}
                  token={token}
                  results={results}
                  onRefresh={() => {
                    this.fetch();
                  }}
                />
              )}
            />
          </Switch>
        )}
      </BookingAppWrapper>
    );
  }
}
