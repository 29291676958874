import React from 'react';
import BookingAppWrapper from 'components/BookingAppWrapper';

import { Message, Divider } from 'semantic-ui-react';
import PageLoader from 'components/PageLoader';

function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return undefined;
  if (!results[2]) return undefined;
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export default class AppointmentTokenLogin extends React.Component {
  state = {
    error: null,
    loading: true,
  };

  componentDidMount() {
    localStorage.setItem('appointmentToken', this.props.match.params.token);
    if (getParameterByName('payment')) {
      document.location = '/my-booking/payment';
    } else {
      document.location = '/my-booking';
    }
  }

  render() {
    const { loading, error } = this.state;
    return (
      <BookingAppWrapper>
        <Divider hidden />
        {error && <Message error={error.message} />}
        {loading && <PageLoader />}
      </BookingAppWrapper>
    );
  }
}
