import React from 'react';
import AppWrapper from 'components/AppWrapper';
import { Switch, Route } from 'react-router-dom';

import { Container, Header, Menu, Divider } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import Financials from './Financials';

export default class Reports extends React.Component {
  render() {
    return (
      <AppWrapper>
        <Container>
          <Header as="h2">Concierge Reporting</Header>
          <Divider hidden />

          <Menu tabular>
            <Menu.Item
              exact
              name="Raw Financials"
              to={`/reports/concierge-service`}
              as={NavLink}
            />
          </Menu>
          <Divider hidden />
          <Switch>
            <Route
              exact
              path="/reports/concierge-service"
              component={(props) => <Financials {...props} />}
            />
          </Switch>
        </Container>
      </AppWrapper>
    );
  }
}
