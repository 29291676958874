import React from 'react';
import { Form } from 'semantic-ui-react';

const daysOfWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

const weekdayOptions = daysOfWeek.map((key) => {
  return {
    key,
    value: key,
    text: key
  };
});

export default (props) => (
  <Form.Dropdown
    label="Weekdays"
    fluid
    selection
    multiple
    options={weekdayOptions}
    {...props}
  />
);
