import React from 'react';
import { observer, inject } from 'mobx-react';
import { DateTime } from 'luxon';
import AppWrapper from 'components/AppWrapper';
import styled from 'styled-components';

import EditOrganization from 'components/modals/EditOrganization';
import Pagination from 'components/Pagination';
import Protected from 'components/Protected';
import { types } from 'utils/organizations';

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

import {
  Container,
  Header,
  Table,
  Loader,
  Segment,
  Dimmer,
  Message,
  Modal,
  Button,
  Icon,
  Label,
} from 'semantic-ui-react';

@inject('appSession', 'organizations')
@observer
export default class Organizations extends React.Component {
  state = {
    showCreateDialog: false,
    editItem: null,
    currentPage: 1,
  };
  constructor(props) {
    super(props);
    this.createOrEditDialog = React.createRef();
  }

  componentDidMount() {
    this.props.organizations.fetchItems();
  }

  handleRemove = (item) => {
    const { organizations } = this.props;
    organizations.delete(item);
  };

  render() {
    const { organizations } = this.props;
    const listStatus = organizations.getStatus('list');
    const deleteStatus = organizations.getStatus('delete');

    return (
      <AppWrapper>
        <Container>
          <Header as="h2">
            Organizations
            <Protected endpoint="organizations" permission="write">
              <EditOrganization
                trigger={
                  <Button
                    primary
                    floated="right"
                    style={{ marginTop: '-5px' }}
                    content="New Organization"
                    icon="plus"
                  />
                }
              />
            </Protected>
          </Header>
          <div className="list">
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={3}>ID</Table.HeaderCell>
                  <Table.HeaderCell width={4}>Name</Table.HeaderCell>
                  <Table.HeaderCell>Created</Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">
                    Actions
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {!organizations.items.length && (
                  <Table.Row>
                    <Table.Cell>No organizations added yet</Table.Cell>
                  </Table.Row>
                )}
                {organizations.items.map((item) => {
                  return (
                    <Table.Row key={item.id}>
                      <Table.Cell>{item.slug} </Table.Cell>
                      <Table.Cell>
                        <Icon
                          name={types[item.type].icon}
                          style={{ float: 'right' }}
                        />
                        {item.name}
                      </Table.Cell>
                      <Table.Cell>
                        {DateTime.fromJSDate(item.createdAt).toLocaleString(
                          DateTime.DATETIME_MED
                        )}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        <Protected endpoint="organizations" permission="write">
                          <EditOrganization
                            initialValues={item}
                            trigger={
                              <Button
                                style={{ marginLeft: '20px' }}
                                basic
                                icon="edit"
                              />
                            }
                          />
                          <Modal
                            header={`Are you sure you want to delete "${item.name}"?`}
                            content="All data will be permanently deleted"
                            status={deleteStatus}
                            trigger={<Button basic icon="trash" />}
                            closeIcon
                            actions={[
                              {
                                key: 'delete',
                                primary: true,
                                content: 'Delete',
                                onClick: () => this.handleRemove(item),
                              },
                            ]}
                          />
                        </Protected>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
            {listStatus.success &&
              organizations.totalItems > organizations.limit && (
                <Center>
                  <Pagination
                    limit={organizations.limit}
                    page={organizations.page}
                    total={organizations.totalItems}
                    onPageChange={(e, { activePage }) => {
                      organizations.setPage(activePage);
                      organizations.fetchItems().then(() => {
                        window.scrollTo(0, 0);
                      });
                    }}
                  />
                </Center>
              )}
            {listStatus.request && (
              <Segment style={{ height: '100px' }}>
                <Dimmer active inverted>
                  <Loader>Loading</Loader>
                </Dimmer>
              </Segment>
            )}
            {listStatus.error && (
              <Message error content={listStatus.error.message} />
            )}
          </div>
        </Container>
      </AppWrapper>
    );
  }
}
