import React from 'react';
import { Grid } from 'semantic-ui-react';

export default ({ maxWidth = '550px', margin = '40px 0', style = {}, children }) => (
  <div style={{ height: '100%' }}>
    <Grid style={{ minHeight: '100%' }} centered verticalAlign="middle">
      <Grid.Column style={{
        maxWidth,
        margin,
        ...style,
      }}>{children}</Grid.Column>
    </Grid>
  </div>
);
