import React from 'react';
import request from 'utils/request';
import { Form, Loader, Message, Modal, Button } from 'semantic-ui-react';
import AutoFocus from 'components/AutoFocus';
import UploadsField from 'components/form-fields/Uploads';
import DateTimeField from 'components/form-fields/DateTime';
import {
  resultStatusOptions,
  resultSendStatusOptions,
  testResultTypeOptions,
} from 'utils/results';

export default class EditResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      error: null,
      result: props.result || {
        testedAt: new Date().toISOString(),
      },
    };
  }

  componentDidUpdate(prevProps) {
    const { result } = this.props;
    if (prevProps.result && result.id !== prevProps.result.id) {
      this.setState({
        result,
      });
    }
  }

  isUpdate() {
    return !!this.state.result.id;
  }

  onSubmit = async () => {
    try {
      const { result } = this.state;
      this.setState({
        error: null,
        loading: true,
      });
      if (this.isUpdate()) {
        await request({
          method: 'PATCH',
          path: `/1/results/${result.id}`,
          body: {
            ...result,
          },
        });
      } else {
        await request({
          method: 'POST',
          path: '/1/results',
          body: {
            patient: this.props.patient.id,
            ...result,
          },
        });
      }
      this.setState({
        open: false,
        loading: false,
        result: {},
      });
      this.props.onSave();
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  setField(name, value) {
    this.setState({
      result: {
        ...this.state.result,
        [name]: value,
      },
    });
  }

  render() {
    const { trigger } = this.props;
    const { result, open, loading, error } = this.state;
    return (
      <Modal
        closeOnDimmerClick={false}
        closeIcon
        onClose={() =>
          this.setState({
            open: false,
            error: null,
          })
        }
        onOpen={() => this.setState({ open: true })}
        open={open}
        trigger={trigger}>
        <Modal.Header>
          {this.isUpdate() ? 'Edit Result' : 'New Result'}
        </Modal.Header>
        <Modal.Content scrolling>
          <AutoFocus>
            <Form id="result" error={Boolean(error)} onSubmit={this.onSubmit}>
              {loading && <Loader active />}
              {error && <Message error content={error.message} />}
              <Form.Dropdown
                selection
                name="testType"
                label="Test Type"
                placeholder="Select"
                value={result.testType}
                options={testResultTypeOptions}
                onChange={(e, { name, value }) => this.setField(name, value)}
              />

              {result.testType === 'Antibody' && (
                <Form.Dropdown
                  selection
                  label="Antibody Type"
                  name="antibodyType"
                  placeholder="Select"
                  value={result.antibodyType}
                  options={[
                    {
                      text: 'IgG',
                      value: 'IgG',
                    },
                    {
                      text: 'IgM',
                      value: 'IgM',
                    },
                  ]}
                  onChange={(e, { name, value }) => this.setField(name, value)}
                />
              )}

              <Form.Dropdown
                selection
                label="Lab Requirements"
                name="labRequirements"
                placeholder="Select"
                value={result.labRequirements}
                options={[
                  {
                    text: 'High Complexity',
                    value: 'H',
                  },
                  {
                    text: 'Moderate Complexity',
                    value: 'M',
                  },
                  {
                    text: 'Point of Care Test',
                    value: 'POCT',
                  },
                ]}
                onChange={(e, { name, value }) => this.setField(name, value)}
              />

              <Form.Dropdown
                selection
                label="Sample Type"
                name="sampleType"
                placeholder="Select"
                value={result.sampleType}
                options={[
                  {
                    text: 'Nasopharyngeal',
                    value: 'Nasopharyngeal',
                  },
                  {
                    text: 'Nasal',
                    value: 'Nasal',
                  },
                  {
                    text: 'Oral',
                    value: 'Oral',
                  },
                  {
                    text: 'Saliva',
                    value: 'Saliva',
                  },
                  {
                    text: 'Blood (Full Draw)',
                    value: 'Blood (Full Draw)',
                  },
                  {
                    text: 'Blood (Finger Prick)',
                    value: 'Blood (Finger Prick)',
                  },
                ]}
                onChange={(e, { name, value }) => this.setField(name, value)}
              />

              <Form.Dropdown
                selection
                label="Result"
                name="result"
                placeholder="Select"
                value={result.result}
                options={resultStatusOptions}
                onChange={(e, { name, value }) => this.setField(name, value)}
              />

              <DateTimeField
                name="testedAt"
                label="Tested Date"
                includeTime={false}
                value={new Date(result.testedAt)}
                onChange={(value, name) => this.setField(name, value)}
              />

              <DateTimeField
                name="resultsReceivedAt"
                label="Result Date"
                includeTime={false}
                value={
                  result.resultsReceivedAt
                    ? new Date(result.resultsReceivedAt)
                    : null
                }
                onChange={(value, name) => this.setField(name, value)}
              />

              <UploadsField
                type="pdf"
                label="PDF Upload"
                value={result.upload || null}
                onChange={({ value }) => this.setField('upload', value)}
              />

              <Form.TextArea
                name="comment"
                label="Comments"
                value={result.comment || ''}
                onChange={(evt, { name, value }) => this.setField(name, value)}
              />

              <Form.Dropdown
                selection
                label="Status"
                name="status"
                placeholder="Select"
                value={result.status}
                options={resultSendStatusOptions}
                onChange={(e, { name, value }) => this.setField(name, value)}
              />
            </Form>
          </AutoFocus>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            form="result"
            loading={loading}
            content={this.isUpdate() ? 'Update' : 'Create'}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}
