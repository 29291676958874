import React from 'react';
import { Container, Menu } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';

export default ({ itemId }) => (
  <Container>
    <Menu tabular>
      <Menu.Item exact name="Overview" to={`/patients/${itemId}`} as={NavLink} />
      <Menu.Item exact name="Results" to={`/patients/${itemId}/results`} as={NavLink} />
      <Menu.Item exact name="Measurements" to={`/patients/${itemId}/measurements`} as={NavLink} />
    </Menu>
  </Container>
);
