import React from 'react';
import { Button, Modal, Divider, Message } from 'semantic-ui-react';

export default class ViewAppointmentTravel extends React.Component {
  static defaultProps = {};

  state = {
    open: false,
  };

  render() {
    const { location, trigger } = this.props;
    const { open } = this.state;
    const organizationBookingUrl = `${document.location.protocol}//${document.location.host}/book/${location.organization.slug}`;
    const bookingUrl = `${organizationBookingUrl}/${location.name
      .toLowerCase()
      .replace(/\s/g, '-')}`;
    const bookingUrlHidden = `${bookingUrl}?hideLocation=true`;
    const bookingUrlReferralCode = `${bookingUrl}?referralCode=campaign-x`;
    return (
      <Modal
        closeOnDimmerClick={false}
        closeIcon
        trigger={trigger}
        onClose={() => this.setState({ open: false })}
        onOpen={() => this.setState({ open: true })}
        open={open}>
        <Modal.Header>Location Booking Link</Modal.Header>
        <Modal.Content>
          <p>Use the following URL to allow booking for any location:</p>
          <Message>
            <a
              href={organizationBookingUrl}
              target="_blank"
              rel="noopener noreferrer">
              {organizationBookingUrl}
            </a>
          </Message>
          <Button
            as="a"
            target="_blank"
            href={`generate-qr?url=${organizationBookingUrl}`}
            rel="noopener noreferrer"
            basic
            size="small"
            icon="qrcode"
            content="QR Code"
          />
          <Divider hidden />

          <p>
            Use the following URL to pre-select this location for new bookings:
          </p>
          <Message>
            <a href={bookingUrl} target="_blank" rel="noopener noreferrer">
              {bookingUrl}
            </a>
          </Message>
          <Button
            as="a"
            target="_blank"
            href={`generate-qr?url=${bookingUrl}`}
            rel="noopener noreferrer"
            basic
            size="small"
            icon="qrcode"
            content="QR Code"
          />
          <Divider hidden />
          <p>Use the following URL to hide the location field:</p>
          <Message>
            <a
              href={bookingUrlHidden}
              target="_blank"
              rel="noopener noreferrer">
              {bookingUrlHidden}
            </a>
          </Message>
          <Button
            as="a"
            target="_blank"
            href={`generate-qr?url=${bookingUrlHidden}`}
            rel="noopener noreferrer"
            basic
            size="small"
            icon="qrcode"
            content="QR Code"
          />
          <Divider hidden />
          <p>Use the following URL to add referral code information:</p>
          <Message>
            <a
              href={bookingUrlReferralCode}
              target="_blank"
              rel="noopener noreferrer">
              {bookingUrlReferralCode}
            </a>
          </Message>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            content="Close"
            onClick={() => this.setState({ open: false })}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}
