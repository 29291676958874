import React from 'react';
import { Form, Message, Modal, Button, Grid, Label } from 'semantic-ui-react';
import { toJS } from 'mobx';
import Availability from 'components/form-fields/Availability';
import LocationField from 'components/form-fields/LocationField';
import GeoAddressField from 'components/form-fields/GeoAddress';
import SearchDropdown from '../../components/SearchDropdown';
import OrdersField from 'components/form-fields/Orders';
import { formatDateStr } from 'utils/formatting';
import request from 'utils/request';
import {
  appointmentStatusOptions,
  reasonsForLost,
  conciergeStatusOptions,
  industries,
} from 'utils/appointments';
import { orderStatusOptions } from 'utils/orders';
import { patientSexOptions } from 'utils/patients';
import { get, set } from 'lodash';

function determineFieldsTouched(order) {
  const fieldsTouched = {};
  if (get(order, 'costs.travel')) {
    fieldsTouched['costs.travel'] = true;
  }
  if (get(order, 'costs.provider')) {
    fieldsTouched['costs.provider'] = true;
  }
  return fieldsTouched;
}

function costsToCents(costs = {}) {
  const newCosts = {};
  if (costs.travel) {
    newCosts.travel = parseFloat(costs.travel, 10) * 100;
  }
  if (costs.provider) {
    newCosts.provider = parseFloat(costs.provider, 10) * 100;
  }
  return newCosts;
}

function costsToDollars(costs = {}) {
  const newCosts = {};
  if (costs.travel) {
    newCosts.travel = parseFloat(costs.travel, 10) / 100;
  }
  if (costs.provider) {
    newCosts.provider = parseFloat(costs.provider, 10) / 100;
  }
  return newCosts;
}

export default class EditAppointment extends React.Component {
  static defaultProps = {
    appointment: {},
    patient: {},
    order: {},
  };

  constructor(props) {
    super(props);
    const order = toJS(props.order);
    this.state = {
      open: false,
      error: null,
      loading: false,
      location: null,
      appointmentFormValues: { ...props.appointment },
      patientFormValues: { ...props.patient },
      orderFormValues: {
        ...order,
        costs: costsToDollars(order.costs),
      },
      fieldsTouched: determineFieldsTouched(order),
      reasonsForLostOptions: reasonsForLost.map((key) => {
        return {
          key,
          value: key,
          text: key,
        };
      }),
    };
  }

  componentDidUpdate(prevProps) {
    const { open } = this.state;
    if (open) return;
    if (this.props.appointment !== prevProps.appointment) {
      this.setState({
        touched: false,
        appointmentFormValues: { ...this.props.appointment },
      });
    }
    if (this.props.patient !== prevProps.patient) {
      this.setState({
        touched: false,
        patientFormValues: { ...this.props.patient },
      });
    }
    if (this.props.order !== prevProps.order) {
      const order = toJS(this.props.order);
      this.setState({
        touched: false,
        orderFormValues: {
          ...order,
          costs: costsToDollars(order.costs),
        },
        fieldsTouched: determineFieldsTouched(order),
      });
    }
  }

  setAppointmentField(name, value) {
    const { appointmentFormValues } = this.state;
    set(appointmentFormValues, name, value);
    this.setState({
      appointmentFormValues,
    });
  }

  setPatientField(name, value) {
    const { patientFormValues } = this.state;
    set(patientFormValues, name, value);
    this.setState({
      patientFormValues,
    });
  }

  setOrderField(name, value) {
    const { orderFormValues } = this.state;
    set(orderFormValues, name, value);
    this.setState({
      orderFormValues,
    });
  }

  onSubmit = async (redirect = false) => {
    this.setState({
      error: null,
      touched: true,
      loading: true,
    });
    const { appointment, patient, order } = this.props;
    try {
      const appointmentBody = this.state.appointmentFormValues;
      if (appointmentBody.conciergeUser && appointmentBody.conciergeUser.id) {
        appointmentBody.conciergeUser = appointmentBody.conciergeUser.id;
      }
      if (appointmentBody.clinicianUser && appointmentBody.clinicianUser.id) {
        appointmentBody.clinicianUser = appointmentBody.clinicianUser.id;
      }
      if (appointmentBody.location && appointmentBody.location.id) {
        appointmentBody.location = appointmentBody.location.id;
      }
      await request({
        method: 'PATCH',
        path: `/1/appointments/${appointment.id}`,
        body: appointmentBody,
      });

      const patientBody = this.state.patientFormValues;
      await request({
        method: 'PATCH',
        path: `/1/patients/${patient.id}`,
        body: patientBody,
      });

      if (order && order.id) {
        const orderBody = this.state.orderFormValues;
        await request({
          method: 'PATCH',
          path: `/1/orders/${order.id}`,
          body: {
            ...orderBody,
            costs: costsToCents(orderBody.costs),
          },
        });
      }
      if (
        appointmentBody.status === 'scheduled' &&
        !appointment.confirmationSentAt
      ) {
        const sendConfirmation = confirm(
          'Do you want to send all patients a confirmation email?'
        );
        if (sendConfirmation) {
          await request({
            method: 'POST',
            path: `/1/appointments/${appointment.id}/emails/confirmation`,
          });
        }
      }
      this.setState({ open: false, loading: false, touched: false });
      if (redirect) {
        document.location = `/appointments/${appointment.id}`;
      } else {
        this.props.onUpdate && this.props.onUpdate();
      }
    } catch (error) {
      this.setState({ error });
    }
    this.setState({ loading: false });
  };

  onError = (error) => {
    this.setState({
      error,
    });
  };

  onLocationChange = (evt, { value, location }) => {
    this.setAppointmentField('location', value);
    this.setState({
      location,
    });
  };

  onDateChange = (name, value) => {
    const dateObj = new Date(Date.parse(value));
    this.setAppointmentField(name, formatDateStr(dateObj));
  };

  onSlotChange = (slot) => {
    this.setState({
      appointmentFormValues: {
        ...this.state.appointmentFormValues,
        requestedHour: slot.hour,
        requestedMinute: slot.minute,
      },
    });
  };

  fetchConciergeUsers = (filter) => {
    return request({
      method: 'POST',
      path: '/1/users/organization/search',
      body: {
        role: 'concierge',
        ...filter,
      },
    });
  };

  handleOnConciergeUserChange = (e, { value }, selectedConciergeUser) => {
    this.setState({
      selectedConciergeUser,
      appointmentFormValues: {
        ...this.state.appointmentFormValues,
        conciergeUser: value,
      },
    });
  };

  fetchClinicianUsers = (filter) => {
    return request({
      method: 'POST',
      path: '/1/users/organization/search',
      body: {
        role: 'clinician',
        ...filter,
      },
    });
  };

  handleOnClinicianUserChange = (e, { value }, selectedClinicianUser) => {
    this.setState({
      selectedClinicianUser,
      appointmentFormValues: {
        ...this.state.appointmentFormValues,
        clinicianUser: value,
      },
    });
  };

  calculateCosts(items) {
    let provider = 100;
    let travel = 0;
    items.forEach((item) => {
      if (item.type === 'travelFee') {
        travel += parseInt(item.price, 10) / 100;
      }
      if (item.type === 'product') {
        provider += 50;
      }
    });
    return { travel, provider };
  }

  autoSetCosts(items) {
    const costs = this.calculateCosts(items);
    const { fieldsTouched } = this.state;
    if (!fieldsTouched['costs.travel']) {
      this.setOrderField('costs.travel', costs.travel);
    }
    if (!fieldsTouched['costs.provider']) {
      this.setOrderField('costs.provider', costs.provider);
    }
  }

  render() {
    const { organization, appointment, trigger } = this.props;
    const {
      appointmentFormValues = {},
      patientFormValues = {},
      orderFormValues = {},
      error,
      loading,
      open,
      reasonsForLostOptions,
    } = this.state;

    const locationId =
      appointmentFormValues.location && appointmentFormValues.location.id
        ? appointmentFormValues.location.id
        : appointmentFormValues.location;

    const isUpdate = !!appointment.id;

    const conciergeUserId =
      appointmentFormValues.conciergeUser &&
      appointmentFormValues.conciergeUser.id
        ? appointmentFormValues.conciergeUser.id
        : appointmentFormValues.conciergeUser;

    const clinicianUserId =
      appointmentFormValues.clinicianUser &&
      appointmentFormValues.clinicianUser.id
        ? appointmentFormValues.clinicianUser.id
        : appointmentFormValues.clinicianUser;

    const reasonForLost = get(appointmentFormValues, 'salesInfo.reasonForLost');
    if (!reasonsForLostOptions.map((o) => o.value).includes(reasonForLost)) {
      reasonsForLostOptions.push({
        value: reasonForLost,
        text: reasonForLost,
        key: reasonForLost,
      });
    }

    const mainPatient = appointment.patients.length
      ? appointment.patients[0]
      : undefined;

    return (
      <Modal
        size="large"
        closeOnDimmerClick={false}
        closeIcon
        onClose={() =>
          this.setState({
            open: false,
            appointmentFormValues: this.props.appointment,
            touched: false,
          })
        }
        onOpen={() => this.setState({ open: true })}
        open={open}
        trigger={trigger}>
        <Modal.Header>
          {isUpdate
            ? `Edit Appointment for ${mainPatient &&
                mainPatient.firstName} ${mainPatient && mainPatient.lastName}`
            : 'New Appointment'}
        </Modal.Header>
        <Modal.Content>
          {error && <Message error content={error.message} />}
          <Form onSubmit={this.onSubmit}>
            <Grid columns={3} divided>
              <Grid.Row>
                <Grid.Column>
                  <LocationField
                    required
                    value={locationId}
                    onChange={this.onLocationChange}
                  />

                  <GeoAddressField
                    value={get(patientFormValues, 'address')}
                    geoLocation={patientFormValues.geoLocation}
                    autoComplete="off"
                    label="Patient Address"
                    onChange={({ address, geoLocation }) => {
                      const { patientFormValues } = this.state;
                      set(patientFormValues, 'address', address);
                      set(patientFormValues, 'geoLocation', geoLocation);
                      this.setState({
                        patientFormValues,
                      });
                    }}
                  />

                  <Form.Select
                    name="sex"
                    clearable
                    options={patientSexOptions}
                    label="Patient Sex"
                    onChange={(e, { value }) => {
                      const { patientFormValues } = this.state;
                      set(patientFormValues, 'sex', value);
                      this.setState({
                        patientFormValues,
                      });
                    }}
                    value={patientFormValues.sex}
                  />

                  <Availability
                    location={appointmentFormValues.location}
                    dateStr={appointmentFormValues.requestedDate}
                    hour={appointmentFormValues.requestedHour}
                    minute={appointmentFormValues.requestedMinute}
                    onError={this.onError}
                    onDateChange={this.onDateChange}
                    onSlotChange={this.onSlotChange}
                    onAvailabilityReceived={this.onAvailabilityReceived}
                  />

                  <Form.TextArea
                    value={appointmentFormValues.comment || ''}
                    name="comment"
                    label="Client Comment"
                    type="text"
                    onChange={(e, { name, value }) =>
                      this.setAppointmentField(name, value)
                    }
                  />

                  {organization.requireEmployeeId && (
                    <Form.Input
                      value={appointmentFormValues.employeeId || ''}
                      name="employeeId"
                      label="Employee ID"
                      type="text"
                      onChange={(e, { name, value }) =>
                        this.setAppointmentField(name, value)
                      }
                    />
                  )}
                </Grid.Column>
                <Grid.Column>
                  <Form.Select
                    name="conciergeStatus"
                    required
                    options={conciergeStatusOptions}
                    label="Concierge Status"
                    onChange={(e, { value }) =>
                      this.setAppointmentField('conciergeStatus', value)
                    }
                    value={appointmentFormValues.conciergeStatus}
                  />

                  {appointmentFormValues.conciergeStatus === 'lost' && (
                    <Form.Dropdown
                      name="reasonForLost"
                      search
                      selection
                      allowAdditions
                      options={reasonsForLostOptions}
                      label="Reason for Sales Loss"
                      onAddItem={(evt, { value }) => {
                        this.setState({
                          reasonsForLostOptions: reasonsForLostOptions.concat({
                            value,
                            key: value,
                            text: value,
                          }),
                        });
                      }}
                      onChange={(e, { value }) =>
                        this.setAppointmentField(
                          'salesInfo.reasonForLost',
                          value
                        )
                      }
                      value={get(
                        appointmentFormValues,
                        'salesInfo.reasonForLost'
                      )}
                    />
                  )}

                  <Form.Select
                    name="status"
                    required
                    options={appointmentStatusOptions}
                    label="Appointment Status"
                    onChange={(e, { value }) =>
                      this.setAppointmentField('status', value)
                    }
                    value={appointmentFormValues.status}
                  />

                  <Form.Field>
                    <label>Concierge / Contact Person</label>
                    <SearchDropdown
                      onChange={this.handleOnConciergeUserChange}
                      value={conciergeUserId}
                      clearable
                      fetchData={this.fetchConciergeUsers}
                      fluid
                    />
                  </Form.Field>

                  <Form.Field>
                    <label>Assigned to Clinician/PA</label>
                    <SearchDropdown
                      onChange={this.handleOnClinicianUserChange}
                      value={clinicianUserId}
                      clearable
                      fetchData={this.fetchClinicianUsers}
                      fluid
                    />
                  </Form.Field>

                  <Form.TextArea
                    value={appointmentFormValues.schedulingNotes || ''}
                    name="schedulingNotes"
                    label="Internal Scheduling Notes"
                    type="text"
                    rows={10}
                    onChange={(e, { name, value }) =>
                      this.setAppointmentField(name, value)
                    }
                  />

                  <Form.Select
                    name="salesInfo.industryName"
                    search
                    clearable
                    options={industries.map((key) => {
                      return {
                        key,
                        value: key,
                        text: key,
                      };
                    })}
                    label="Industry"
                    onChange={(e, { value }) =>
                      this.setAppointmentField('salesInfo.industryName', value)
                    }
                    value={get(appointmentFormValues, 'salesInfo.industryName')}
                  />

                  <Form.Input
                    value={get(
                      appointmentFormValues,
                      'salesInfo.organizationName'
                    )}
                    name="salesInfo.organizationName"
                    label="Organization Name"
                    type="text"
                    onChange={(e, { name, value }) =>
                      this.setAppointmentField(name, value)
                    }
                  />
                </Grid.Column>
                <Grid.Column>
                  <Form.Select
                    name="orderStatus"
                    required
                    options={orderStatusOptions}
                    label="Order Status"
                    onChange={(e, { value }) =>
                      this.setOrderField('status', value)
                    }
                    value={get(orderFormValues, 'status')}
                  />

                  <Form.Field>
                    <label>Provider</label>
                    <SearchDropdown
                      onChange={(e, { value }) => {
                        this.setOrderField('providerPaymentAccount', value);
                      }}
                      value={get(orderFormValues, 'providerPaymentAccount')}
                      clearable
                      fetchData={(filter) => {
                        return request({
                          method: 'POST',
                          path: '/1/payment-accounts/search',
                          body: {
                            type: 'provider',
                            ...filter,
                          },
                        });
                      }}
                      fluid
                    />
                  </Form.Field>

                  <OrdersField
                    items={orderFormValues.items}
                    organizationId={orderFormValues.organization}
                    onChange={({ items, totalPrice }) => {
                      this.setOrderField('items', items);
                      this.setOrderField('totalPrice', totalPrice);
                      this.autoSetCosts(items);
                    }}
                  />

                  <Form.Input
                    value={get(orderFormValues, 'costs.travel')}
                    name="costs.travel"
                    label="Travel Costs"
                    labelPosition="right"
                    type="text"
                    onChange={(e, { name, value }) => {
                      this.setOrderField(name, value);
                      const { fieldsTouched } = this.state;
                      fieldsTouched[name] = true;
                      this.setState({ fieldsTouched });
                    }}>
                    <Label basic>$</Label>
                    <input />
                    <Label>.00</Label>
                  </Form.Input>

                  <Form.Input
                    value={get(orderFormValues, 'costs.provider')}
                    name="costs.provider"
                    label="Provider Costs"
                    labelPosition="right"
                    type="text"
                    onChange={(e, { name, value }) => {
                      this.setOrderField(name, value);
                      const { fieldsTouched } = this.state;
                      fieldsTouched[name] = true;
                      this.setState({ fieldsTouched });
                    }}>
                    <Label basic>$</Label>
                    <input />
                    <Label>.00</Label>
                  </Form.Input>

                  <Form.Field>
                    <label>Corporate Billing Account</label>
                    <SearchDropdown
                      onChange={(e, { value }) => {
                        this.setOrderField('billingAccount', value);
                        if (value) {
                          this.setOrderField('status', 'paid');
                        }
                      }}
                      value={get(orderFormValues, 'billingAccount')}
                      clearable
                      fetchData={(filter) => {
                        return request({
                          method: 'POST',
                          path: '/1/billing-accounts/search',
                          body: {
                            ...filter,
                          },
                        });
                      }}
                      fluid
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            loading={loading}
            basic
            content="Save &amp; View"
            onClick={() => this.onSubmit(true)}
          />
          <Button
            loading={loading}
            primary
            content={isUpdate ? 'Save' : 'Create'}
            onClick={() => this.onSubmit()}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}
