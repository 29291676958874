import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { Form, Button, Segment, Message } from 'semantic-ui-react';

const stripePromise = loadStripe('pk_test_ddxSIzelDYbo9suCKhYWprHG');

const CheckoutForm = ({ onSuccess, getPaymentIntent }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const { data } = await getPaymentIntent();
      const { client_secret } = data;

      const result = await stripe.confirmCardPayment(client_secret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      });

      setLoading(false);

      if (result.error) {
        throw Error(result.error.message);
      }

      if (result.paymentIntent.status !== 'succeeded') {
        throw Error(
          `Bad status from payment provider: ${result.paymentIntent.status}`
        );
      }

      onSuccess();
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  return (
    <Segment>
      {error && <Message error content={error.message} />}
      <Form onSubmit={handleSubmit}>
        <div
          style={{
            border: '1px solid rgba(34, 36, 38, 0.15)',
            borderRadius: '0.28571429rem',
            margin: '0.4em 0',
            padding: '0.67857143em 1em',
          }}>
          <CardElement
            options={{
              style: {
                base: {
                  fontSize: '1em',
                  fontFamily:
                    "'Work Sans', 'Helvetica Neue', Arial, Helvetica, sans-serif",
                  color: 'rgba(0, 0, 0, 0.87)',
                },
                invalid: {
                  color: '#9e2146',
                },
              },
            }}
          />
        </div>
        <Button
          type="submit"
          disabled={!stripe}
          loading={loading}
          primary
          content="Pay"
          fluid
        />
      </Form>
    </Segment>
  );
};

export default (props) => (
  <Elements stripe={stripePromise}>
    <CheckoutForm {...props} />
  </Elements>
);
