import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import styled from 'styled-components';
import { Transition } from 'semantic-ui-react';

const StyledMarker = styled.div`
  color: #fff;
  background: #1478c1;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Marker = (props) => (
  <Transition visible={props.visible} animation="browse" duration={500}>
    <StyledMarker {...props} />
  </Transition>
);

function createOptions() {
  return {
    fullscreenControl: false,
  };
}

class Map extends Component {
  state = {
    visible: false,
  };
  static defaultProps = {
    label: 'Address',
    height: '200px',
    zoom: 10,
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({ visible: true });
    }, 100);
  }

  render() {
    const { height, zoom, geoLocations } = this.props;
    const lat = geoLocations.map((g) => g.coordinates[1])[0];
    const lng = geoLocations.map((g) => g.coordinates[0])[0];
    const center = {
      lat,
      lng,
    };
    return (
      <div
        style={{
          height,
          width: '100%',
          marginBottom: '0.5em',
          marginTop: '0.5em',
        }}>
        <GoogleMapReact
          options={createOptions}
          bootstrapURLKeys={{ key: 'AIzaSyBqHjeA2yv6ZzSOqIUYNGaNAZT-0vOdtWQ' }}
          defaultCenter={center}
          defaultZoom={zoom}>
          {geoLocations.map((geoLocation, i) => {
            return (
              <Marker
                key={i}
                lat={geoLocation.coordinates[1]}
                lng={geoLocation.coordinates[0]}
                visible={this.state.visible}
              />
            );
          })}
        </GoogleMapReact>
      </div>
    );
  }
}

export default Map;
