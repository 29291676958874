import React from 'react';
import Pagination from 'components/Pagination';

import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import ExportButton from 'components/ExportButton';
import { formatOrderStatus } from 'utils/formatting';
import ViewOrder from 'components/modals/ViewOrder';

import {
  Table,
  Loader,
  Segment,
  Dimmer,
  Message,
  Modal,
  Button,
  Icon,
} from 'semantic-ui-react';

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

const stripeBaseUrl = document.location.hostname.match(/\.co/)
  ? 'https://dashboard.stripe.com/payments'
  : 'https://dashboard.stripe.com/test/payments';

export default class DataTable extends React.Component {
  render() {
    const {
      status,
      data: items,
      meta = {},
      getSort,
      setSort,
      page,
    } = this.props;
    return (
      <div className="list">
        {status.success && !items.length && (
          <Message>No lab orders created yet</Message>
        )}

        {status.success && items.length > 0 && (
          <Table celled sortable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Patient</Table.HeaderCell>
                <Table.HeaderCell textAlign="center" width={2}>
                  Status
                </Table.HeaderCell>
                <Table.HeaderCell>Created At</Table.HeaderCell>
                <Table.HeaderCell width={3} textAlign="center">
                  Actions
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {!items.length && (
                <Table.Row>
                  <Table.Cell>No orders created yet</Table.Cell>
                </Table.Row>
              )}
              {items.map((item) => {
                return (
                  <Table.Row key={item.id}>
                    <Table.Cell>
                      <NavLink to={`/patients/${item.patient.id}`}>
                        {item.patient.firstName} {item.patient.lastName}
                      </NavLink>
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      {formatOrderStatus(item)}
                    </Table.Cell>
                    <Table.Cell>
                      {DateTime.fromJSDate(
                        new Date(item.createdAt)
                      ).toLocaleString(DateTime.DATETIME_MED)}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <ViewOrder
                        order={item}
                        trigger={<Button basic icon="list" />}
                      />
                      {item.stripePaymentInfo &&
                        item.stripePaymentInfo.payment_intent && (
                          <Button
                            icon="stripe"
                            href={`${stripeBaseUrl}/${item.stripePaymentInfo.payment_intent}`}
                            target="_blank"
                            basic
                          />
                        )}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        )}
        {status.success && meta.total > this.props.limit && (
          <Center>
            <Pagination
              limit={this.props.limit}
              page={page}
              total={meta.total}
              onPageChange={(e, { activePage }) => {
                this.props.setPage(activePage).then(() => {
                  window.scrollTo(0, 0);
                });
              }}
            />
          </Center>
        )}
        {status.request && (
          <Segment style={{ height: '100px' }}>
            <Dimmer active inverted>
              <Loader>Loading</Loader>
            </Dimmer>
          </Segment>
        )}
        {status.error && <Message error content={status.error.message} />}
        {status.success && meta.total > 0 && (
          <ExportButton
            content="Export All"
            path={'/1/orders/search'}
            filename="orders.csv"
          />
        )}
      </div>
    );
  }
}
