import React from 'react';
import QRCode from 'react-qr-code';

export default class Home extends React.Component {
  render() {
    const url = new URLSearchParams(document.location.search).get('url');
    return (
      <div style={{ width: '100%', marginTop: '2em', fontSize: '24px' }}>
        <center>
          <QRCode value={url} size={256 * 2} />
          <p />
          <p>
            <strong>{url}</strong>
          </p>
        </center>
      </div>
    );
  }
}
