import React from 'react';
import { userHasEndpointAccess } from 'utils/permissions';
import { observer, inject } from 'mobx-react';

@inject('me')
@observer
export default class Protected extends React.Component {
  render() {
    const { me } = this.props;
    const { endpoint, permission = 'read', scope = undefined } = this.props;
    let hasAccess =
      me.user &&
      userHasEndpointAccess(me.user, { endpoint, permission, scope });

    return <React.Fragment>{hasAccess && this.props.children}</React.Fragment>;
  }
}
