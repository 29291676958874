import React from 'react';
import request from 'utils/request';
import { Statistic } from 'semantic-ui-react';
import { formatUsd } from '../../utils/formatting';

export default class Overview extends React.Component {
  state = {
    data: null,
  };
  componentDidMount() {
    this.fetch();
  }
  fetch() {
    request({
      method: 'GET',
      path: `/1/billing-accounts/${this.props.billingAccount.id}/stats`,
    }).then(({ data }) => {
      this.setState({ data });
    });
  }
  render() {
    const { billingAccount } = this.props;
    const { data } = this.state;
    return (
      <div>
        {data && (
          <Statistic.Group>
            <Statistic>
              <Statistic.Value>{formatUsd(data.totalPaid)}</Statistic.Value>
              <Statistic.Label>Total Paid</Statistic.Label>
            </Statistic>
            <Statistic>
              <Statistic.Value>{formatUsd(data.totalUnpaid)}</Statistic.Value>
              <Statistic.Label>Total Outstanding</Statistic.Label>
            </Statistic>
          </Statistic.Group>
        )}
      </div>
    );
  }
}
