import React from 'react';
import { observer, inject } from 'mobx-react';
import { DateTime } from 'luxon';
import AppWrapper from 'components/AppWrapper';
import styled from 'styled-components';

import EditProduct from 'components/modals/EditProduct';
import Pagination from 'components/Pagination';
import Protected from 'components/Protected';
import { formatUsd } from 'utils/formatting';

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

import {
  Container,
  Header,
  Table,
  Loader,
  Segment,
  Dimmer,
  Message,
  Modal,
  Button,
} from 'semantic-ui-react';

@inject('appSession', 'products')
@observer
export default class Products extends React.Component {
  state = {
    showCreateDialog: false,
    editItem: null,
    currentPage: 1,
  };
  constructor(props) {
    super(props);
    this.createOrEditDialog = React.createRef();
  }

  componentDidMount() {
    this.props.products.fetchItems();
  }

  handleRemove = (item) => {
    const { products } = this.props;
    products.delete(item);
  };

  render() {
    const { products } = this.props;
    const listStatus = products.getStatus('list');
    const deleteStatus = products.getStatus('delete');

    return (
      <AppWrapper>
        <Container>
          <Header as="h2">
            Products
            <Protected endpoint="products" permission="write" disabled>
              <EditProduct
                trigger={
                  <Button
                    primary
                    floated="right"
                    style={{ marginTop: '-5px' }}
                    content="New Product"
                    icon="plus"
                  />
                }
              />
            </Protected>
          </Header>
          <div className="list">
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={3}>Name</Table.HeaderCell>
                  <Table.HeaderCell>Description</Table.HeaderCell>
                  <Table.HeaderCell width={3}>Price</Table.HeaderCell>
                  <Table.HeaderCell width={3}>Created</Table.HeaderCell>
                  <Table.HeaderCell textAlign="center" width={2}>
                    Actions
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {!products.items.length && (
                  <Table.Row>
                    <Table.Cell>No products added yet</Table.Cell>
                  </Table.Row>
                )}
                {products.items.map((item) => {
                  return (
                    <Table.Row key={item.id}>
                      <Table.Cell>{item.name}</Table.Cell>
                      <Table.Cell>{item.description}</Table.Cell>
                      <Table.Cell>{formatUsd(item.price)}</Table.Cell>
                      <Table.Cell>
                        {DateTime.fromJSDate(item.createdAt).toLocaleString(
                          DateTime.DATETIME_MED
                        )}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        <Protected
                          endpoint="products"
                          permission="write"
                          disabled>
                          <EditProduct
                            initialValues={item}
                            trigger={<Button basic icon="edit" />}
                          />
                          <Modal
                            header={`Are you sure you want to delete "${item.name}"?`}
                            content="All data will be permanently deleted"
                            status={deleteStatus}
                            trigger={<Button basic icon="trash" />}
                            closeIcon
                            actions={[
                              {
                                key: 'delete',
                                primary: true,
                                content: 'Delete',
                                onClick: () => this.handleRemove(item),
                              },
                            ]}
                          />
                        </Protected>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
            {listStatus.success && products.totalItems > products.limit && (
              <Center>
                <Pagination
                  limit={products.limit}
                  page={products.page}
                  total={products.totalItems}
                  onPageChange={(e, { activePage }) => {
                    products.setPage(activePage);
                    products.fetchItems().then(() => {
                      window.scrollTo(0, 0);
                    });
                  }}
                />
              </Center>
            )}
            {listStatus.request && (
              <Segment style={{ height: '100px' }}>
                <Dimmer active inverted>
                  <Loader>Loading</Loader>
                </Dimmer>
              </Segment>
            )}
            {listStatus.error && (
              <Message error content={listStatus.error.message} />
            )}
          </div>
        </Container>
      </AppWrapper>
    );
  }
}
