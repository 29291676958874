import React from 'react';
import { Button } from 'semantic-ui-react';
import request from 'utils/request';
import DataList from 'components/DataList';
import ResultsTable from './ResultsTable';
import EditResult from 'components/modals/EditResult';
import Protected from 'components/Protected';

const itemLimit = 50;

export default class Results extends React.Component {
  state = {
    dateKey: Date.now(),
  };

  onDeleteItem = async (item) => {
    await request({
      method: 'DELETE',
      path: `/1/results/${item.id}`,
    });
    this.refresh();
  };

  onUpdateItem = () => {
    this.refresh();
  };

  onRequest = async () => {
    return await request({
      method: 'POST',
      path: '/1/results/search',
      body: {
        patient: this.props.patient.id,
      },
    });
  };

  refresh() {
    this.setState({
      dataKey: Date.now(),
    });
  }

  render() {
    return (
      <React.Fragment>
        <div style={{ textAlign: 'right', marginBottom: '1em' }}>
          <Protected endpoint="results" permission="write">
            <EditResult
              patient={this.props.patient}
              onSave={this.onUpdateItem}
              trigger={
                <Button primary size="small" content="New Result" icon="plus" />
              }
            />
          </Protected>
        </div>
        <DataList
          key={this.state.dataKey}
          limit={itemLimit}
          onRequest={this.onRequest}>
          <ResultsTable
            onDeleteItem={this.onDeleteItem}
            onUpdateItem={this.onUpdateItem}
          />
        </DataList>
      </React.Fragment>
    );
  }
}
