import React from 'react';
import { observer, inject } from 'mobx-react';
import { Switch, Route, Link } from 'react-router-dom';
import AppWrapper from 'components/AppWrapper';

import { Container, Divider, Breadcrumb, Button } from 'semantic-ui-react';
import Menu from './Menu';
import PageLoader from 'components/PageLoader';
import Overview from './Overview';
import Orders from './Orders';

@inject('billingAccounts')
@observer
export default class Patient extends React.Component {
  state = {
    itemId: this.props.match.params.id,
  };

  componentDidMount() {
    this.props.billingAccounts.fetchItem(this.state.itemId);
  }

  render() {
    const { billingAccounts } = this.props;
    const item = billingAccounts.get(this.state.itemId);
    return (
      <AppWrapper>
        <Container>
          <Breadcrumb size="big">
            <Breadcrumb.Section link as={Link} to="/">
              Home
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon="right chevron" />
            <Breadcrumb.Section link as={Link} to="/billing-accounts">
              Billing Accounts
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon="right chevron" />
            <Breadcrumb.Section active>
              {item ? [item.name].join(' ') : 'Loading...'}
            </Breadcrumb.Section>
          </Breadcrumb>
        </Container>
        <Divider hidden />
        <Menu itemId={this.state.itemId} />
        <Divider hidden />
        {!item && <PageLoader />}
        {item && (
          <Switch>
            <Route
              exact
              path="/billing-accounts/:id"
              item={item}
              component={(props) => (
                <Overview {...props} billingAccount={item} />
              )}
            />
            <Route
              exact
              path="/billing-accounts/:id/orders"
              item={item}
              component={(props) => <Orders {...props} billingAccount={item} />}
            />
          </Switch>
        )}
      </AppWrapper>
    );
  }
}
