import React from 'react';
import Pagination from 'components/Pagination';

import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import EditResult from 'components/modals/EditResult';
import SendResult from 'components/modals/SendResult';
import Protected from 'components/Protected';
import { urlForUpload } from 'utils/uploads';
import { formatResult } from 'utils/formatting';

import {
  Table,
  Loader,
  Segment,
  Dimmer,
  Message,
  Modal,
  Button,
  Label,
} from 'semantic-ui-react';

function resultIsShareable(result) {
  if (result.status === 'pending' && result.result !== 'pending') {
    return true;
  }
  return false;
}

function formatStatus(result) {
  if (result.status === 'sent') {
    return <Label content="Sent" icon="mail" color="olive" />;
  }
  if (resultIsShareable(result)) {
    return <Label content="Ready to Send" icon="checkmark" color="yellow" />;
  }
  return <Label content="Pending" icon="wait" />;
}

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

export default class DataTable extends React.Component {
  render() {
    const {
      status,
      data: items,
      meta = {},
      getSort,
      setSort,
      page,
      hideResultsDueAt = false,
    } = this.props;
    return (
      <div className="list">
        {status.success && !items.length && (
          <Message>No lab orders created yet</Message>
        )}

        {status.success && items.length > 0 && (
          <Table celled sortable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Patient</Table.HeaderCell>
                <Table.HeaderCell>Result</Table.HeaderCell>
                <Table.HeaderCell
                  width={3}
                  sorted={getSort('testedAt')}
                  onClick={() => setSort('testedAt')}>
                  Test Date
                </Table.HeaderCell>
                {!hideResultsDueAt && (
                  <Table.HeaderCell
                    width={3}
                    sorted={getSort('resultsDueAt')}
                    onClick={() => setSort('resultsDueAt')}>
                    Results Due
                  </Table.HeaderCell>
                )}
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell>File</Table.HeaderCell>
                <Table.HeaderCell width={3} textAlign="center">
                  Actions
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {!items.length && (
                <Table.Row>
                  <Table.Cell>No patients added yet</Table.Cell>
                </Table.Row>
              )}
              {items.map((item) => {
                return (
                  <Table.Row key={item.id}>
                    <Table.Cell>
                      <NavLink to={`/patients/${item.patient.id}`}>
                        {item.patient.firstName} {item.patient.lastName}
                      </NavLink>
                    </Table.Cell>
                    <Table.Cell>{formatResult(item.result)}</Table.Cell>
                    <Table.Cell>
                      {item.testedAt
                        ? DateTime.fromJSDate(
                            new Date(item.testedAt)
                          ).toRelative()
                        : '-'}
                    </Table.Cell>
                    {!hideResultsDueAt && (
                      <Table.Cell>
                        {DateTime.fromJSDate(
                          new Date(item.resultsDueAt)
                        ).toRelative()}
                      </Table.Cell>
                    )}
                    <Table.Cell>{formatStatus(item)}</Table.Cell>
                    <Table.Cell>
                      {item.upload && (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={urlForUpload(item.upload)}>
                          <Button basic icon="file pdf" />
                        </a>
                      )}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <Protected endpoint="labOrders" permission="write">
                        {resultIsShareable(item) && (
                          <SendResult
                            organization={item.organization}
                            result={item}
                            onSave={() => this.props.onUpdateItem()}
                            trigger={<Button basic icon="send" />}
                          />
                        )}
                        <EditResult
                          appointment={item.appointment}
                          organization={item.organization}
                          result={item}
                          onSave={() => this.props.onUpdateItem()}
                          trigger={<Button basic icon="edit" />}
                        />
                        <Button
                          basic
                          icon="file pdf"
                          onClick={() => this.props.onDownloadItem(item)}
                        />
                        <Modal
                          header={`Are you sure you want to delete lab order for "${item.patient.firstName} ${item.patient.lastName}"?`}
                          content="All data will be permanently deleted"
                          trigger={<Button basic icon="trash" />}
                          closeIcon
                          actions={[
                            {
                              key: 'delete',
                              primary: true,
                              content: 'Delete',
                              onClick: () => this.props.onDeleteItem(item),
                            },
                          ]}
                        />
                      </Protected>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        )}
        {status.success && meta.total > this.props.limit && (
          <Center>
            <Pagination
              limit={this.props.limit}
              page={page}
              total={meta.total}
              onPageChange={(e, { activePage }) => {
                this.props.setPage(activePage).then(() => {
                  window.scrollTo(0, 0);
                });
              }}
            />
          </Center>
        )}
        {status.request && (
          <Segment style={{ height: '100px' }}>
            <Dimmer active inverted>
              <Loader>Loading</Loader>
            </Dimmer>
          </Segment>
        )}
        {status.error && <Message error content={status.error.message} />}
      </div>
    );
  }
}
