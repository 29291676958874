import { hot } from 'react-hot-loader/root';
import 'theme/semantic.less';

import { Switch, Route, Redirect } from 'react-router-dom';
import React from 'react';

import AuthSwitchRoute from 'components/routes/AuthSwitch';
import Protected from 'components/routes/Protected';

import DocsGettingStarted from './screens/Docs/GettingStarted';
import Dashboard from './screens/Dashboard';
import Settings from './screens/Settings';
import Invites from './screens/Invites';
import Locations from './screens/Locations';
import Products from './screens/Products';
import PaymentAccounts from './screens/PaymentAccounts';
import BillingAccount from './screens/BillingAccount';
import BillingAccounts from './screens/BillingAccounts';
import Organizations from './screens/Organizations';
import Users from './screens/Users';
import AcceptInvite from './screens/Auth/AcceptInvite';
import Login from './screens/Auth/Login';
import MyAppointments from './screens/MyAppointments';
import Appointments from './screens/Appointments';
import Appointment from './screens/Appointment';
import Patients from './screens/Patients';
import Patient from './screens/Patient';
import Availability from './screens/Availability';
import Logout from './screens/Auth/Logout';
import ForgotPassword from './screens/Auth/ForgotPassword';
import Signup from './screens/Auth/Signup';
import ResetPassword from './screens/Auth/ResetPassword';
import AppointmentLogin from './screens/Auth/AppointmentLogin';
import ResultLogin from './screens/Auth/ResultLogin';
import ResultSubmissionLogin from './screens/Auth/ResultSubmissionLogin';
import CreateAppointment from './screens/CreateAppointment';
import ScheduleOverrides from './screens/ScheduleOverrides';
import AppointmentTokenLogin from './screens/AppointmentTokenAuth/Login';
import AppointmentTokenLogout from './screens/AppointmentTokenAuth/Logout';
import ResultTokenLogin from './screens/ResultTokenAuth/Login';
import ResultTokenLogout from './screens/ResultTokenAuth/Logout';
import ResultSubmissionTokenLogin from './screens/ResultSubmissionTokenAuth/Login';
import ResultSubmissionTokenLogout from './screens/ResultSubmissionTokenAuth/Logout';
import ManageAppointment from './screens/ManageAppointment';
import ViewResults from './screens/ViewResults';
import ViewResultSubmission from './screens/ViewResultSubmission';
import LabOrders from './screens/LabOrders';
import Results from './screens/Results';
import ReportsConciergeService from './screens/Reports/ConciergeService';
import Orders from './screens/Orders';
import GenerateQr from './screens/GenerateQr';

import QuickEntryMeasure from './screens/QuickEntry/Measure';
import QuickEntryResults from './screens/QuickEntry/Results';

const App = () => (
  <Switch>
    <AuthSwitchRoute exact path="/" loggedIn={Dashboard} loggedOut={Login} />
    <Protected
      endpoint="labOrders"
      permission="read"
      exact
      path="/lab-orders"
      component={LabOrders}
    />
    <Protected
      endpoint="orders"
      permission="read"
      exact
      path="/orders"
      component={Orders}
    />
    <Protected
      endpoint="results"
      permission="read"
      exact
      path="/results"
      component={Results}
    />
    <Protected
      endpoint="myAppointments"
      permission="read"
      exact
      path="/my-appointments"
      component={MyAppointments}
    />
    <Protected
      endpoint="appointments"
      permission="read"
      exact
      path="/appointments"
      component={Appointments}
    />
    <Protected
      endpoint="appointments"
      permission="read"
      exact
      path="/appointments/:id/:screen?"
      component={Appointment}
    />
    <Protected
      endpoint="patients"
      permission="read"
      exact
      path="/patients"
      component={Patients}
    />
    <Protected
      endpoint="patients"
      permission="read"
      exact
      path="/patients/:id/:screen?"
      component={Patient}
    />
    <Protected exact path="/settings" component={Settings} />
    <Protected
      endpoint="users"
      permission="read"
      exact
      path="/invites"
      component={Invites}
    />
    <Protected
      endpoint="scheduleOverrides"
      permission="read"
      exact
      path="/availability"
      component={Availability}
    />
    <Protected
      endpoint="users"
      permission="read"
      scope="global"
      exact
      path="/users"
      component={Users}
    />
    <Protected
      endpoint="locations"
      permission="read"
      exact
      path="/locations"
      component={Locations}
    />
    <Protected
      endpoint="products"
      permission="read"
      exact
      path="/products"
      component={Products}
    />
    <Protected
      endpoint="paymentAccounts"
      permission="read"
      exact
      path="/payment-accounts"
      component={PaymentAccounts}
    />
    <Protected
      endpoint="billingAccounts"
      permission="read"
      exact
      path="/billing-accounts"
      component={BillingAccounts}
    />
    <Protected
      endpoint="billingAccounts"
      permission="read"
      exact
      path="/billing-accounts/:id/:screen?"
      component={BillingAccount}
    />
    <Protected
      endpoint="organizations"
      permission="read"
      exact
      path="/organizations"
      component={Organizations}
    />
    <Protected
      endpoint="reports"
      permission="read"
      exact
      path="/reports/concierge-service"
      component={ReportsConciergeService}
    />
    <Protected
      endpoint="scheduleOverrides"
      permission="read"
      exact
      path="/schedule-overrides"
      component={ScheduleOverrides}
    />
    <Protected
      endpoint="measurements"
      permission="read"
      exact
      path="/quick-entry/measure"
      component={QuickEntryMeasure}
    />
    <Protected
      endpoint="results"
      permission="write"
      exact
      path="/quick-entry/results"
      component={QuickEntryResults}
    />
    <Protected
      endpoint="users"
      permission="read"
      exact
      path="/docs/getting-started"
      component={DocsGettingStarted}
    />
    <Route exact path="/logout" component={Logout} />
    <AuthSwitchRoute
      exact
      path="/login"
      loggedOut={Login}
      loggedIn={() => <Redirect to="/" />}
    />
    <AuthSwitchRoute
      exact
      path="/signup"
      loggedOut={Signup}
      loggedIn={() => <Redirect to="/" />}
    />
    <Route exact path="/book" component={CreateAppointment} />
    <Route exact path="/book/:organizationSlug" component={CreateAppointment} />
    <Route
      exact
      path="/book/:organizationSlug/:locationName"
      component={CreateAppointment}
    />
    <Route exact path="/generate-qr" component={GenerateQr} />
    <Route exact path="/my-booking/login" component={AppointmentLogin} />
    <Route exact path="/my-booking" component={ManageAppointment} />
    <Route exact path="/my-booking/*" component={ManageAppointment} />
    <Route exact path="/my-results/login" component={ResultLogin} />
    <Route exact path="/my-results" component={ViewResults} />
    <Route exact path="/my-results/*" component={ViewResults} />
    <Route
      exact
      path="/my-result-submission/login"
      component={ResultSubmissionLogin}
    />
    <Route
      exact
      path="/my-result-submission"
      component={ViewResultSubmission}
    />
    <Route
      exact
      path="/my-result-submission/*"
      component={ViewResultSubmission}
    />
    <Route exact path="/booking/logout" component={AppointmentTokenLogout} />
    <Route exact path="/booking/:token" component={AppointmentTokenLogin} />
    <Route exact path="/view-results/logout" component={ResultTokenLogout} />
    <Route exact path="/view-results/:token" component={ResultTokenLogin} />
    <Route
      exact
      path="/result-submission/logout"
      component={ResultSubmissionTokenLogout}
    />
    <Route
      exact
      path="/result-submission/:token"
      component={ResultSubmissionTokenLogin}
    />
    <Route exact path="/accept-invite" component={AcceptInvite} />
    <Route exact path="/forgot-password" component={ForgotPassword} />
    <Route exact path="/reset-password" component={ResetPassword} />
  </Switch>
);

export default hot(App);
