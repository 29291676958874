import React from 'react';
import { Button, Modal } from 'semantic-ui-react';
import Map from 'components/Map';
import { directionsUrl } from 'utils/maps';

export default class ViewAppointmentTravel extends React.Component {
  static defaultProps = {};

  state = {
    open: false,
  };

  render() {
    const { location, appointment, trigger } = this.props;
    const { open } = this.state;

    return (
      <Modal
        closeOnDimmerClick={false}
        closeIcon
        trigger={trigger}
        onClose={() => this.setState({ open: false })}
        onOpen={() => this.setState({ open: true })}
        open={open}>
        <Modal.Header>View Travel from Location</Modal.Header>
        <Modal.Content>
          {location.geoLocation &&
            appointment.patients[0] &&
            appointment.patients[0].geoLocation && (
              <Map
                zoom={8}
                height="400px"
                geoLocations={[
                  location.geoLocation,
                  appointment.patients[0].geoLocation,
                ]}
              />
            )}
        </Modal.Content>
        <Modal.Actions>
          {appointment.patients[0] && (
            <Button
              basic
              icon="car"
              content="Directions"
              as="a"
              rel="noopener noreferrer"
              target="_blank"
              href={directionsUrl(
                appointment.patients[0].address,
                location.address
              )}
            />
          )}
          <Button
            primary
            content="Close"
            onClick={() => this.setState({ open: false })}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}
