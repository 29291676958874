import React from 'react';
import Pagination from 'components/Pagination';

import { Link } from 'react-router-dom';
import styled from 'styled-components';
import EditAppointment from 'components/modals/EditAppointment';
import { DateTime } from 'luxon';
import ExportButton from 'components/ExportButton';
import Protected from 'components/Protected';
import { get } from 'lodash';

import ViewAppointmentTravel from 'components/modals/ViewAppointmentTravel';
import SendPaymentLink from 'components/modals/SendPaymentLink';

import {
  Table,
  Loader,
  Segment,
  Dimmer,
  Message,
  Modal,
  Button,
  Icon,
  Dropdown,
  Progress,
  Popup,
} from 'semantic-ui-react';

import {
  formatUsd,
  formatAppointmentStatus,
  formatConciergeStatus,
  formatDispatchingStatus,
  formatOrderStatus,
  formatTime,
} from '../../utils/formatting';
import { determineProgress } from '../../utils/appointments';

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

function formatColumn(organization, item, path, mainPatient) {
  if (path === 'location') {
    return (
      <>
        {item.location &&
          item.location.geoLocation &&
          item.patients &&
          item.patients[0].geoLocation && (
            <ViewAppointmentTravel
              location={item.location}
              appointment={item}
              trigger={
                <a style={{ float: 'right', cursor: 'pointer' }}>
                  <Icon name="map signs" />
                </a>
              }
            />
          )}
        {item.location ? item.location.name : '-'}
      </>
    );
  }
  if (path === 'contact') {
    return (
      <>
        {mainPatient && mainPatient.email && (
          <a style={{ float: 'right' }} href={`mailto:${mainPatient.email}`}>
            <Icon name="mail" />
          </a>
        )}
        {mainPatient && (
          <a href={`tel:+1${mainPatient.phoneNo}`}>{mainPatient.phoneNo}</a>
        )}
      </>
    );
  }
  if (path === 'conciergeStatus') {
    return formatConciergeStatus(item, true);
  }
  if (path === 'dispatchingStatus') {
    return formatDispatchingStatus(item);
  }
  if (path === 'order.status') {
    return formatOrderStatus(item.order);
  }
  if (path === 'order.totalPrice') {
    return formatUsd(get(item, path));
  }
  if (path === 'numPatients') {
    return `${item.patients.length}`;
  }
  if (path === 'createdAt') {
    return DateTime.fromJSDate(new Date(item.createdAt)).toRelative();
  }
  if (path === 'status') {
    return formatAppointmentStatus(item, true);
  }
  if (path === 'progress') {
    const progress = determineProgress(organization, item);
    return (
      <>
        <Popup
          content={progress.description}
          trigger={
            <Progress
              percent={progress.percent}
              label={progress.nextStep}
              color={progress.color}
              size="tiny"
            />
          }
        />
      </>
    );
  }
  return get(item, path);
}

export default class DataTable extends React.Component {
  render() {
    const {
      organization,
      status,
      data: items,
      meta = {},
      getSort,
      setSort,
      page,
      columns,
    } = this.props;
    const hasNumPatients = columns.map((c) => c.key).includes('numPatients');
    return (
      <div className="list">
        {status.success && !items.length && (
          <Message>No appointments created yet</Message>
        )}

        {status.success && items.length > 0 && (
          <Table celled sortable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Patient Name</Table.HeaderCell>
                {columns.map((column) => (
                  <Table.HeaderCell
                    key={column.key}
                    textAlign={column.textAlign}
                    width={column.width || 2}>
                    {column.shortName || column.name}
                  </Table.HeaderCell>
                ))}
                <Table.HeaderCell
                  width={3}
                  sorted={getSort('requestedAt')}
                  onClick={() => setSort('requestedAt')}>
                  Appointment Time
                </Table.HeaderCell>
                <Table.HeaderCell width={2} textAlign="center">
                  Actions
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {items.map((item) => {
                const mainPatient = item.patients.length
                  ? item.patients[0]
                  : undefined;
                const numAdditionalPatients = item.patients.length - 1;
                return (
                  <Table.Row key={item.id}>
                    <Table.Cell>
                      {mainPatient && (
                        <a
                          style={{ float: 'right', cursor: 'pointer' }}
                          href={`tel:${mainPatient.phoneNo}`}>
                          <Icon name="phone" />
                        </a>
                      )}
                      {item.status === 'cancelled' && (
                        <Icon
                          name="ban"
                          color="red"
                          title="This appointment was cancelled"
                          style={{ float: 'right' }}
                        />
                      )}
                      <Link
                        to={`/appointments/${item.id}`}
                        style={{
                          textDecoration:
                            item.status === 'cancelled'
                              ? 'line-through'
                              : 'normal',
                        }}>
                        {mainPatient
                          ? `${mainPatient.firstName} ${mainPatient.lastName}`
                          : 'No Patient'}
                      </Link>
                      {!hasNumPatients && numAdditionalPatients > 0 && (
                        <p>
                          <small>
                            +{numAdditionalPatients} more patient
                            {numAdditionalPatients > 1 ? 's' : ''}
                          </small>
                        </p>
                      )}
                    </Table.Cell>
                    {columns.map((column) => (
                      <Table.Cell key={column.key} textAlign={column.textAlign}>
                        {formatColumn(
                          this.props.organization,
                          item,
                          column.key,
                          mainPatient
                        )}
                      </Table.Cell>
                    ))}
                    <Table.Cell>
                      {DateTime.fromISO(item.requestedDate, {
                        zone: 'America/New_York',
                      }).toLocaleString(DateTime.DATE_MED)}
                      ,&nbsp;
                      {formatTime(item.requestedHour, item.requestedMinute)}
                      {item.clinicianUser ? (
                        <p style={{ fontSize: '12px' }}>
                          for {item.clinicianUser.name}
                        </p>
                      ) : (
                        <p></p>
                      )}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <Protected endpoint="appointments" permission="write">
                        <EditAppointment
                          appointment={item}
                          order={item.order}
                          patient={mainPatient}
                          organization={item.organization}
                          onUpdate={this.props.onUpdateItem}
                          trigger={<Button basic icon="edit" />}
                        />

                        <Dropdown button basic text="" className="compact-more">
                          <Dropdown.Menu>
                            {item.status === 'scheduled' && (
                              <Modal
                                header="Confirmation"
                                content="Are you sure you want to cancel this appointment?"
                                trigger={
                                  <Dropdown.Item
                                    icon="ban"
                                    text="Cancel"
                                    disabled={item.status === 'cancelled'}
                                  />
                                }
                                closeIcon
                                actions={[
                                  {
                                    key: 'confirm',
                                    primary: true,
                                    content: 'Confirm Cancellation',
                                    onClick: () =>
                                      this.props.onCancelItem(item),
                                  },
                                ]}
                              />
                            )}
                            <Modal
                              header={`Are you sure you want to delete?`}
                              content="All data will be permanently deleted"
                              //status={deleteStatus}
                              trigger={
                                <Dropdown.Item icon="trash" text="Delete" />
                              }
                              closeIcon
                              actions={[
                                {
                                  key: 'delete',
                                  primary: true,
                                  content: 'Delete',
                                  onClick: () => this.props.onDeleteItem(item),
                                },
                              ]}
                            />
                            <Modal
                              header={`Do you want to send confirmation emails?`}
                              content="All patients will get an email confirmation for this appointment."
                              //status={deleteStatus}
                              trigger={
                                item.confirmationSentAt ? (
                                  <Dropdown.Item
                                    icon="redo"
                                    text="Re-send Confirmation"
                                  />
                                ) : (
                                  <Dropdown.Item
                                    icon="send"
                                    text="Send Confirmation"
                                    disabled={item.status !== 'scheduled'}
                                  />
                                )
                              }
                              closeIcon
                              actions={[
                                {
                                  key: 'send',
                                  primary: true,
                                  content: 'Send',
                                  onClick: () =>
                                    this.props.onResendEmails(item),
                                },
                              ]}
                            />
                            <Modal
                              header={`Do you want to send request confirmation emails?`}
                              content="All patients will get an email with 'Booking Request Confirmation' content for this appointment."
                              trigger={
                                <Dropdown.Item
                                  icon="send"
                                  text="Send Request Confirmation"
                                  disabled={
                                    !organization.bookingRequestConfirmationEmail ||
                                    item.requestConfirmationSentAt
                                  }
                                />
                              }
                              closeIcon
                              actions={[
                                {
                                  key: 'send',
                                  primary: true,
                                  content: 'Send',
                                  onClick: () =>
                                    this.props.onResendRequestEmails(item),
                                },
                              ]}
                            />
                            <SendPaymentLink
                              appointment={item}
                              trigger={
                                <Dropdown.Item
                                  icon="send"
                                  text="Send Payment Link"
                                  disabled={
                                    !this.props.organization.enablePayments ||
                                    (item.order && item.order.status !== 'paid')
                                  }
                                />
                              }
                            />
                          </Dropdown.Menu>
                        </Dropdown>
                      </Protected>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        )}
        {status.success && meta.total > this.props.limit && (
          <Center>
            <Pagination
              limit={this.props.limit}
              page={page}
              total={meta.total}
              onPageChange={(e, { activePage }) => {
                this.props.setPage(activePage).then(() => {
                  window.scrollTo(0, 0);
                });
              }}
            />
          </Center>
        )}
        {status.request && (
          <Segment style={{ height: '100px' }}>
            <Dimmer active inverted>
              <Loader>Loading</Loader>
            </Dimmer>
          </Segment>
        )}
        {status.error && <Message error content={status.error.message} />}
        <ExportButton
          content="Export All"
          path={'/1/appointments/search'}
          filename="appointments.csv"
          body={{
            hideCancellation: true,
            sort: {
              field: 'requestedAt',
              order: 'desc',
            },
          }}
        />
      </div>
    );
  }
}
