import React from 'react';
import { observer, inject } from 'mobx-react';
import { Modal, Menu, List, Loader, Message } from 'semantic-ui-react';
import request from 'utils/request';
import { types } from 'utils/organizations';

@inject('me')
@observer
export default class OrganizationSwitcher extends React.Component {
  state = {
    organizations: null,
    loading: true,
    error: null,
  };
  componentDidMount() {
    this.fetchOrganizations();
  }
  fetchOrganizations() {
    request({
      method: 'GET',
      path: '/1/organizations/me',
    })
      .then(({ data }) => {
        this.setState({ loading: false, organizations: data });
      })
      .catch((error) => {
        this.setState({ loading: false, error });
      });
  }
  setOrganization(item) {
    window.localStorage.setItem('setOrganizationId', item.id);
    document.location.reload();
  }
  render() {
    const { loading, error, organizations } = this.state;
    const { me } = this.props;
    const { organization } = me;
    return (
      <Modal
        header="Switch Organization"
        size="tiny"
        content={
          <Modal.Content>
            {loading && <Loader active />}
            {error && <Message error content={error.message} />}
            {organizations && (
              <List selection verticalAlign="middle">
                {organizations.map((item) => {
                  return (
                    <List.Item
                      key={item.id}
                      onClick={() => this.setOrganization(item)}>
                      <List.Icon
                        name={types[item.type].icon}
                        size="large"
                        verticalAlign="middle"
                      />
                      <List.Content>
                        <List.Header>{item.name}</List.Header>
                      </List.Content>
                    </List.Item>
                  );
                })}
              </List>
            )}
          </Modal.Content>
        }
        trigger={
          <Menu.Item
            icon={types[organization.type].icon}
            content={organization.name}
          />
        }
        closeIcon
        actions={[]}
      />
    );
  }
}
