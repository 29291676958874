import React from 'react';
import { Form } from 'semantic-ui-react';

export default class Filters extends React.Component {
  state = {
    search: ''
  };
  handleSubmit = () => {
    this.props.onChange(this.state.search);
  };

  setField(field, value = '') {
    this.setState({ [field]: value.length ? value : undefined });
  }
  render() {
    return (
      <Form onSubmit={() => this.handleSubmit()}>
        <Form.Input
          value={this.state.search || ''}
          name="search"
          placeholder={this.props.placeholder}
          icon="search"
          type="text"
          onChange={(e, { name, value }) => this.setField(name, value)}
        />
      </Form>
    );
  }
}
