import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { get, omit, flatten, uniqBy } from 'lodash';

export default class SearchDropdown extends React.Component {
  static defaultProps = {
    valueField: 'id',
    textField: 'name',
  };

  state = {
    defaultOptions: [],
    options: [],
    loading: false,
    error: false,
  };

  constructor(props) {
    super(props);
    this.index = {};
  }

  componentDidMount() {
    const { valueField, multiple } = this.props;
    const fetchField = multiple ? `${valueField}s` : valueField;
    Promise.all(
      [
        this.fetchOptions({}),
        this.props.value &&
          this.fetchOptions({
            [fetchField]: this.props.value,
          }),
      ].filter(Boolean)
    ).then((options) => {
      let onChangeFired = false;
      if (this.props.selectName && flatten(options).length) {
        const option = flatten(options).filter((option) =>
          option.text
            .toLowerCase()
            .match(this.props.selectName.replace(/\-/g, ' '))
        )[0];
        if (option) {
          const value = option.value;
          this.props.onChange(
            null,
            { value },
            this.index[value],
            false,
            flatten(options).length
          );
          onChangeFired = true;
        }
      }
      if (
        !onChangeFired &&
        this.props.selectFirst &&
        !this.props.value &&
        flatten(options).length
      ) {
        const value = flatten(options)[0].value;
        this.props.onChange(
          null,
          { value },
          this.index[value],
          true,
          flatten(options).length
        );
        onChangeFired = true;
      }
      this.setState({
        defaultOptions: flatten(options),
      });
    });
  }

  componentWillUnmount() {
    this.index = {};
  }

  fetchOptions(search) {
    const { valueField, textField, textFieldFn, fetchData } = this.props;
    return fetchData(search)
      .then(({ data }) => {
        const options = data.map((item) => {
          const key = get(item, valueField);
          this.index[key] = item;
          return {
            text: textFieldFn ? textFieldFn(item) : get(item, textField),
            value: key,
          };
        });
        return options;
      })
      .catch(() => {
        this.setState({
          loading: false,
          error: true,
        });
      });
  }

  loadOptions(search) {
    const { valueField, textField, textFieldFn, fetchData } = this.props;
    this.setState({
      search,
      loading: true,
      error: false,
    });

    return fetchData(search)
      .then(({ data }) => {
        const options = data.map((item) => {
          const key = get(item, valueField);
          this.index[key] = item;
          return {
            text: textFieldFn ? textFieldFn(item) : get(item, textField),
            value: key,
          };
        });
        this.setState({
          loading: false,
          options,
        });
        return options;
      })
      .catch(() => {
        this.setState({
          loading: false,
          error: true,
        });
      });
  }

  onSearchChange = (e, { searchQuery }) => {
    const { textField } = this.props;
    if (searchQuery.length) {
      this.loadOptions({ [textField]: searchQuery });
    }
  };

  onChange = (e, { value }) => {
    this.props.onChange(null, { value }, this.index[value]);
  };

  render() {
    const { state } = this;
    const { multiple } = this.props;
    const props = omit(this.props, [
      'fetchData',
      'valueField',
      'textFieldFn',
      'textField',
      'selectFirst',
      'selectName',
    ]);
    let options = state.defaultOptions;
    /*
    if (state.search) {
      if (multiple) {
        options = uniqBy(
          [...state.defaultOptions, ...state.options],
          (option) => option.value
        );
      } else {
        options = state.options;
      }
    }
*/
    options = uniqBy(
      [...state.defaultOptions, ...state.options],
      (option) => option.value
    );

    return (
      <Dropdown
        error={state.error}
        search
        selection
        clearable={this.props.clearable}
        loading={state.loading}
        options={options}
        onSearchChange={this.onSearchChange}
        {...props}
        onChange={this.onChange}
      />
    );
  }
}
