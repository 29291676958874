import React from 'react';
import { observer, inject } from 'mobx-react';
import { DateTime } from 'luxon';
import AppWrapper from 'components/AppWrapper';
import styled from 'styled-components';

import EditLocation from 'components/modals/EditLocation';
import ViewLocationLinks from 'components/modals/ViewLocationLinks';
import Pagination from 'components/Pagination';
import Protected from 'components/Protected';
import CalendarLink from './CalendarLink';

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

import {
  Container,
  Header,
  Table,
  Loader,
  Segment,
  Dimmer,
  Message,
  Modal,
  Button,
} from 'semantic-ui-react';

@inject('appSession', 'me', 'locations')
@observer
export default class Locations extends React.Component {
  state = {
    showCreateDialog: false,
    editItem: null,
    currentPage: 1,
  };
  constructor(props) {
    super(props);
    this.createOrEditDialog = React.createRef();
  }

  componentDidMount() {
    this.props.locations.fetchItems();
  }

  handleRemove = (item) => {
    const { locations } = this.props;
    locations.delete(item);
  };

  render() {
    const { locations, me } = this.props;
    const { organization } = me;
    const listStatus = locations.getStatus('list');
    const deleteStatus = locations.getStatus('delete');

    return (
      <AppWrapper>
        <Container>
          <Header as="h2">
            Locations
            <Protected endpoint="locations" permission="write" disabled>
              <EditLocation
                trigger={
                  <Button
                    primary
                    floated="right"
                    style={{ marginTop: '-5px' }}
                    content="New Location"
                    icon="plus"
                  />
                }
              />
            </Protected>
          </Header>
          <div className="list">
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={3}>Name</Table.HeaderCell>
                  <Table.HeaderCell>Address</Table.HeaderCell>
                  {organization && organization.enablePayments && (
                    <Table.HeaderCell width={2}>
                      Payment Accounts
                    </Table.HeaderCell>
                  )}
                  <Table.HeaderCell width={3}>Created</Table.HeaderCell>
                  <Table.HeaderCell textAlign="center" width={3}>
                    Actions
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {!locations.items.length && (
                  <Table.Row>
                    <Table.Cell>No locations added yet</Table.Cell>
                  </Table.Row>
                )}
                {locations.items.map((item) => {
                  return (
                    <Table.Row key={item.id}>
                      <Table.Cell>
                        {item.name}
                        <CalendarLink id={item.id} />
                      </Table.Cell>
                      <Table.Cell>{item.address}</Table.Cell>
                      {organization && organization.enablePayments && (
                        <Table.Cell>
                          {item.defaultProviderPaymentAccount
                            ? item.defaultProviderPaymentAccount.name
                            : '-'}
                        </Table.Cell>
                      )}
                      <Table.Cell>
                        {DateTime.fromJSDate(item.createdAt).toLocaleString(
                          DateTime.DATETIME_MED
                        )}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        <Protected
                          endpoint="locations"
                          permission="write"
                          disabled>
                          <ViewLocationLinks
                            location={item}
                            trigger={<Button basic icon="linkify" />}
                          />
                          <EditLocation
                            initialValues={item}
                            trigger={<Button basic icon="edit" />}
                          />
                          <Modal
                            header={`Are you sure you want to delete "${item.name}"?`}
                            content="All data will be permanently deleted"
                            status={deleteStatus}
                            trigger={<Button basic icon="trash" />}
                            closeIcon
                            actions={[
                              {
                                key: 'delete',
                                primary: true,
                                content: 'Delete',
                                onClick: () => this.handleRemove(item),
                              },
                            ]}
                          />
                        </Protected>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
            {listStatus.success && locations.totalItems > locations.limit && (
              <Center>
                <Pagination
                  limit={locations.limit}
                  page={locations.page}
                  total={locations.totalItems}
                  onPageChange={(e, { activePage }) => {
                    locations.setPage(activePage);
                    locations.fetchItems().then(() => {
                      window.scrollTo(0, 0);
                    });
                  }}
                />
              </Center>
            )}
            {listStatus.request && (
              <Segment style={{ height: '100px' }}>
                <Dimmer active inverted>
                  <Loader>Loading</Loader>
                </Dimmer>
              </Segment>
            )}
            {listStatus.error && (
              <Message error content={listStatus.error.message} />
            )}
          </div>
        </Container>
      </AppWrapper>
    );
  }
}
