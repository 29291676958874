import React from 'react';
import { observer, inject } from 'mobx-react';
import BookingAppWrapper from 'components/BookingAppWrapper';
import { Switch, Route } from 'react-router-dom';

import { Message, Divider } from 'semantic-ui-react';
import PageLoader from 'components/PageLoader';
import Overview from './Overview';
import request from '../../utils/request';
import Menu from './Menu';

@inject('patients')
@observer
export default class ViewResults extends React.Component {
  state = {
    token: localStorage.getItem('resultToken'),
    results: null,
    error: null,
    loading: true,
  };

  componentDidMount() {
    this.fetch();
  }

  fetch() {
    this.setState({ loading: true });
    const { token } = this.state;
    request({
      method: 'POST',
      path: '/1/results/fetch',
      body: {
        token,
      },
    })
      .then(({ data }) => {
        this.setState({ results: data, loading: false });
      })
      .catch((error) => {
        document.location = '/my-results/login?expired=true';
        //this.setState({ error, loading: false });
      });
  }

  render() {
    const { loading, results, error, token } = this.state;
    return (
      <BookingAppWrapper>
        <Divider hidden />
        <Menu
          token={token}
          organization={results && results[0] && results[0].organization}
        />
        <Divider hidden />
        {error && <Message error={error.message} />}
        {loading && <PageLoader />}
        {!error && results && results.length > 0 && (
          <Switch>
            <Route
              exact
              path="/my-results"
              component={(props) => (
                <Overview
                  {...props}
                  token={token}
                  results={results}
                  onRefresh={() => {
                    this.fetch();
                  }}
                />
              )}
            />
          </Switch>
        )}
      </BookingAppWrapper>
    );
  }
}
