import React from 'react';
import Pagination from 'components/Pagination';

import { Link } from 'react-router-dom';
import styled from 'styled-components';
import EditAppointment from 'components/modals/EditAppointment';
import { DateTime } from 'luxon';
import ExportButton from 'components/ExportButton';
import Protected from 'components/Protected';
import { get } from 'lodash';

import ViewAppointmentTravel from 'components/modals/ViewAppointmentTravel';

import {
  Table,
  Loader,
  Segment,
  Dimmer,
  Message,
  Modal,
  Button,
  Icon,
  Dropdown,
} from 'semantic-ui-react';

import {
  formatUsd,
  formatAppointmentStatus,
  formatConciergeStatus,
  formatDispatchingStatus,
  formatOrderStatus,
  formatTime,
} from '../../utils/formatting';

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

export default class DataTable extends React.Component {
  render() {
    const {
      status,
      data: items,
      meta = {},
      getSort,
      setSort,
      page,
      columns = [],
    } = this.props;
    const hasNumPatients = columns.map((c) => c.key).includes('numPatients');
    return (
      <div className="list">
        {status.success && !items.length && (
          <Message>No appointments created yet</Message>
        )}

        {status.success && items.length > 0 && (
          <Table celled sortable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Patient Name</Table.HeaderCell>
                <Table.HeaderCell width={4} textAlign="center">
                  Address
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center">
                  Clinician/PA
                </Table.HeaderCell>
                <Table.HeaderCell
                  width={3}
                  sorted={getSort('requestedAt')}
                  onClick={() => setSort('requestedAt')}>
                  Appointment Time
                </Table.HeaderCell>
                <Table.HeaderCell
                  width={2}
                  sorted={getSort('createdAt')}
                  onClick={() => setSort('createdAt')}>
                  Created
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {items.map((item) => {
                const mainPatient = item.patients.length
                  ? item.patients[0]
                  : undefined;
                const numAdditionalPatients = item.patients.length - 1;
                return (
                  <Table.Row key={item.id}>
                    <Table.Cell>
                      {mainPatient && (
                        <a
                          style={{ float: 'right', cursor: 'pointer' }}
                          href={`tel:${mainPatient.phoneNo}`}>
                          <Icon name="phone" />
                        </a>
                      )}
                      {item.status === 'cancelled' && (
                        <Icon
                          name="ban"
                          color="red"
                          title="This appointment was cancelled"
                          style={{ float: 'right' }}
                        />
                      )}
                      <Link
                        to={`/appointments/${item.id}`}
                        style={{
                          textDecoration:
                            item.status === 'cancelled'
                              ? 'line-through'
                              : 'normal',
                        }}>
                        {mainPatient
                          ? `${mainPatient.firstName} ${mainPatient.lastName}`
                          : 'No Patient'}
                      </Link>
                      {!hasNumPatients && numAdditionalPatients > 0 && (
                        <p>
                          <small>
                            +{numAdditionalPatients} more patient
                            {numAdditionalPatients > 1 ? 's' : ''}
                          </small>
                        </p>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      <>
                        {item.location &&
                          item.location.geoLocation &&
                          mainPatient &&
                          mainPatient.geoLocation && (
                            <ViewAppointmentTravel
                              location={item.location}
                              appointment={item}
                              trigger={
                                <a
                                  style={{ float: 'right', cursor: 'pointer' }}>
                                  <Icon name="map signs" />
                                </a>
                              }
                            />
                          )}
                        {mainPatient.address}
                      </>
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      {formatDispatchingStatus(item, true)}
                    </Table.Cell>
                    <Table.Cell>
                      {DateTime.fromISO(item.requestedDate, {
                        zone: 'America/New_York',
                      }).toLocaleString(DateTime.DATE_MED)}
                      ,&nbsp;
                      {formatTime(item.requestedHour, item.requestedMinute)}
                      {item.clinicianUser ? (
                        <p style={{ fontSize: '12px' }}>
                          for {item.clinicianUser.name}
                        </p>
                      ) : (
                        <p></p>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {DateTime.fromJSDate(
                        new Date(item.createdAt)
                      ).toRelative()}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        )}
        {status.success && meta.total > this.props.limit && (
          <Center>
            <Pagination
              limit={this.props.limit}
              page={page}
              total={meta.total}
              onPageChange={(e, { activePage }) => {
                this.props.setPage(activePage).then(() => {
                  window.scrollTo(0, 0);
                });
              }}
            />
          </Center>
        )}
        {status.request && (
          <Segment style={{ height: '100px' }}>
            <Dimmer active inverted>
              <Loader>Loading</Loader>
            </Dimmer>
          </Segment>
        )}
        {status.error && <Message error content={status.error.message} />}
      </div>
    );
  }
}
