import React from 'react';
import { Form, Message, Modal, Button, Radio } from 'semantic-ui-react';
import AutoFocus from 'components/AutoFocus';
import SearchDropdown from '../../components/SearchDropdown';
import DateTimeField from 'components/form-fields/DateTime';
import request from 'utils/request';
import { labOrderStatusOptions } from 'utils/lab-orders';

function defaultTurnaroundHoursForProduct(product) {
  const { name } = product;
  if (name.match(/72h/i)) {
    return 72;
  }
  if (name.match(/48h/i)) {
    return 48;
  }
  if (name.match(/12h/i)) {
    return 12;
  }
  if (name.match(/6h/i)) {
    return 6;
  }
  if (name.match(/4h/i)) {
    return 4;
  }
  if (name.match(/2h/i)) {
    return 2;
  }
  return 24;
}

export default class EditLabOrder extends React.Component {
  static defaultProps = {
    initialValues: {},
    loading: false,
    error: null,
    previousPatientIds: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      formValues: {
        status: 'needsRequisition',
        ...this.props.initialValues,
        collectionDueAt: this.props.appointment.requestedAt,
      },
      previousPatientIds: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.initialValues !== prevProps.initialValues) {
      this.setState({
        touched: false,
        formValues: {
          status: 'needsRequisition',
          ...this.props.initialValues,
          collectionDueAt: this.props.appointment.requestedAt,
        },
      });
    }
  }

  handleSubmit = async (options = {}) => {
    const { initialValues, appointment } = this.props;
    const { formValues, previousPatientIds } = this.state;
    this.setState({ loading: true, error: null, touched: true });
    try {
      const body = formValues;

      if (body.product && body.product.id) {
        body.product = body.product.id;
      }

      if (body.patient && body.patient.id) {
        body.patient = body.patient.id;
      }

      if (body.appointment && body.appointment.id) {
        body.appointment = body.appointment.id;
      }

      if (initialValues.id) {
        await request({
          method: 'PATCH',
          path: `/1/lab-orders/${initialValues.id}`,
          body,
        });
      } else {
        await request({
          method: 'POST',
          path: '/1/lab-orders',
          body,
        });
      }
      if (options.continue) {
        previousPatientIds.push(body.patient);
        const nextPatientId =
          appointment.patients
            .map((p) => p.id)
            .filter((id) => !previousPatientIds.includes(id))[0] || null;
        this.setState({
          loading: false,
          touched: false,
          formValues: {
            product: body.product,
            patient: nextPatientId,
            ...this.props.initialValues,
          },
          previousPatientIds,
        });
      } else {
        this.setState({
          loading: false,
          touched: false,
          formValues: this.props.initialValues,
          open: false,
        });
        if (this.props.onSave) {
          this.props.onSave();
        }
      }
    } catch (error) {
      this.setState({ loading: false, error });
    }
  };

  setField(name, value) {
    this.setState({
      formValues: {
        ...this.state.formValues,
        [name]: value,
      },
    });
  }

  fetchPatients = (filter) => {
    const { appointment } = this.props;
    const params = {};
    if (appointment.patients.length) {
      if (appointment.patients[0].id) {
        params.ids = appointment.patients.map((p) => p.id);
      } else {
        params.ids = appointment.patients;
      }
    }
    return request({
      method: 'POST',
      path: '/1/patients/search',
      body: {
        ...filter,
        ...params,
      },
    });
  };

  handleOnPatientChange = (e, { value }) => {
    this.setState({
      formValues: {
        ...this.state.formValues,
        patient: value,
      },
    });
  };

  render() {
    const { organization, patients, initialValues, trigger } = this.props;
    const {
      touched,
      open,
      loading,
      error,
      formValues,
      previousPatientIds,
    } = this.state;

    const isUpdate = !!initialValues.id;

    const productId =
      formValues.product && formValues.product.id
        ? formValues.product.id
        : formValues.product;

    const patientId =
      formValues.patient && formValues.patient.id
        ? formValues.patient.id
        : formValues.patient;

    console.log('formValues', formValues);

    return (
      <Modal
        closeOnDimmerClick={false}
        closeIcon
        onClose={() => {
          this.setState({
            open: false,
            formValues: initialValues,
            touched: false,
          });
          if (this.props.onSave) {
            this.props.onSave();
          }
        }}
        onOpen={() => this.setState({ open: true })}
        open={open}
        trigger={trigger}>
        <Modal.Header>
          {isUpdate ? 'Edit Lab Order' : 'Create Lab Order'}
          {previousPatientIds.length
            ? ` #${previousPatientIds.length + 1}`
            : ''}
        </Modal.Header>
        <Modal.Content>
          <AutoFocus disabled={isUpdate}>
            {error && <Message error content={error.message} />}
            <Form onSubmit={() => this.handleSubmit()}>
              <Form.Field required>
                <label>Patient</label>
                <SearchDropdown
                  textField="searchId"
                  textFieldFn={(item) => `${item.firstName} ${item.lastName}`}
                  onChange={this.handleOnPatientChange}
                  value={patientId}
                  clearable
                  fetchData={this.fetchPatients}
                  fluid
                />
              </Form.Field>

              <Form.Field required>
                <label>Product</label>
                <SearchDropdown
                  onChange={(e, { value }, product) => {
                    if (!initialValues.id) {
                      const { formValues } = this.state;
                      formValues.turnaroundHours = defaultTurnaroundHoursForProduct(
                        product
                      );
                      this.setState({ formValues });
                    }
                    this.setField('product', value);
                  }}
                  value={productId}
                  clearable
                  fetchData={(filter) => {
                    return request({
                      method: 'POST',
                      path: '/1/products/search',
                      body: {
                        ...filter,
                      },
                    });
                  }}
                  fluid
                />
              </Form.Field>

              <Form.Select
                name="status"
                required
                options={labOrderStatusOptions}
                label="Status"
                onChange={(e, { value }) => this.setField('status', value)}
                value={formValues.status}
              />

              <DateTimeField
                name="testedAt"
                label="Collection Date and Time"
                includeTime={true}
                value={formValues.collectionDueAt}
                onChange={(value, name) => this.setField(name, value)}
              />

              <Form.Input
                required
                name="name"
                label="Results Turnaround Time (Hours)"
                type="number"
                value={formValues.turnaroundHours}
                onChange={(e, { name, value }) => this.setField(name, value)}
              />
            </Form>
          </AutoFocus>
        </Modal.Content>
        <Modal.Actions>
          <Button
            loading={loading}
            basic
            content="Save &amp; Continue"
            onClick={() => this.handleSubmit({ continue: true })}
          />
          <Button
            loading={loading}
            primary
            content={'Save'}
            onClick={() => this.handleSubmit()}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}
